import smbService from './sessionInterceptor';


const getCookie = (cName) => {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res
  }

export const getCart = (isGuest) => {
    
    let headers = {}
    const barangay_id = getCookie('locationGateBarangayId');
    return new Promise(async(resolve, reject) => {
        try {
            let result = await smbService.get('/cart/' + barangay_id, {
                headers
            });
            resolve(result.data)
        }catch(e){

            reject(e)
        }

    })
}

export const addToCart = (data, update = false) => {
    return new Promise(async(resolve, reject) => {
        let params = [
            '/cart',
            data,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ]

        try{
            let result = await (update ? smbService.put(...params) : smbService.post(...params))
            resolve(result.data)
        }catch(e){
            reject(e)
        }

    })
}

export const deleteCartItems = (items = []) => {

  const params = [
    '/cart',
    {
      data: {
        product_id: items
      }
    },
    {
      headers: {
          'Content-Type': 'application/json'
    }
  }];

  console.log(params);

  return smbService.delete(...params);
}

export const isCartReadyForCheckout = (query) => {
  return smbService.post('/user/checkout/verify', query)
};
