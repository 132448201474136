import axios from "axios";
import { issueGuestToken } from "./authServices";
import { get } from "lodash";
import { getToken, setToken, removeToken } from "./tokenService";
const smbService = axios.create();
const { PUBLIC_URL, API_VERSION } = process.env;
const userAuthURL = PUBLIC_URL + "/api/" + API_VERSION + "/user";

const checkIfUserIsStillLoggedIn = async () => {
  const currentToken = getToken();

  try {
    const response = await axios.get(userAuthURL, {
      headers: {
        Authorization: "Bearer " + currentToken
      }
    });

    return Promise.resolve(response);
  } catch (error) {
    const refreshedToken = get(error.response.headers, "access-token");
    const errorStatus = get(error.response, "status");

    if (error.response && errorStatus === 401 && refreshedToken) {
      console.log("Refreshing token");
      setToken(refreshedToken);
      return Promise.reject(error);
    } else {
      removeToken();
    }
  }
};

export const setInterceptor = () => {
  smbService.interceptors.request.use(config => {
    let originalRequest = config;
    let { url } = originalRequest;
    let token = getToken();

    // console.log(originalRequest);
    // console.log(token);

    if (url.indexOf("/") == 0) {
      originalRequest["url"] = PUBLIC_URL + "/api/" + API_VERSION + url;
    }

    if (token) {
      // If the axios instance request is not overriding the headers
      // with the `guest-token` header, it means that the request is
      // from a logged-in user
      // if(!originalRequest.headers['guest-token']){
      //     originalRequest.headers['Authorization'] = 'Bearer ' + token;
      // }
      originalRequest.headers["Authorization"] = "Bearer " + token;

    } else {
      console.log("fetching guest token...");
      // Set guest token
      return issueGuestToken().then(token => {
        setToken(token);

        originalRequest.headers["Authorization"] = "Bearer " + token;

        return Promise.resolve(originalRequest);
      });
    }
    return originalRequest;
  });

  smbService.interceptors.response.use(
    function(response) {
      // Do something with response data
      const newToken = get(response.headers, "access-token");
      const currentToken = getToken();

      // console.log(response);
      // console.log(newToken);
      // console.log(currentToken);

      if (!!newToken && newToken != currentToken) {
        setToken(newToken);
      }

      return response;
    },
    async function(error) {
      const { config } = error;
      const originalRequest = config;
      let retryOrigReq;

      // console.log(error);
      // console.log(originalRequest);

      // Do something with response error
      const errorStatus = get(error.response, "status");

      if (
        error.response &&
        errorStatus === 401 &&
        originalRequest.url !== userAuthURL
      ) {
        try {
          const response = await checkIfUserIsStillLoggedIn();
          console.log(response);
        } catch (error) {
          console.log("Retrying request", originalRequest);

          const refreshedToken = get(error.response.headers, "access-token");

          retryOrigReq = new Promise((resolve, reject) => {
            originalRequest.headers["Authorization"] =
              "Bearer " + refreshedToken;
            resolve(axios(originalRequest));
          });
        }

        return retryOrigReq;
      } else if (
        error.response &&
        errorStatus === 401 &&
        originalRequest.url === userAuthURL
      ) {
        const refreshedToken = get(error.response.headers, "access-token");

        setToken(refreshedToken);

        if (refreshedToken) {
          retryOrigReq = new Promise((resolve, reject) => {
            originalRequest.headers["Authorization"] =
              "Bearer " + refreshedToken;
            resolve(axios(originalRequest));
          });

          return retryOrigReq;
        } else {
          removeToken();
        }
      } else if (error.response && errorStatus === 500) {
        removeToken();
        
      } else {
        return Promise.reject(error);
      }
    }
  );
};

export default smbService;
