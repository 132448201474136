
import { actionTypes } from './actions'

export const initialState = {
    products: [],
    types:[],
    brands: [],
    loading: false,
    recommended: []
}

export default function authReducer(state = initialState, action) {

    switch (action.type) {
        case actionTypes.UPDATE_PRODUCTS :
            return {
                ...state,
                products: action.payload
            }
        case actionTypes.UPDATE_PRODUCT_TYPES :
            return {
                ...state,
                types: action.payload
            }
        case actionTypes.UPDATE_PRODUCT_BRANDS :
            return {
                ...state,
                brands: action.payload
            }
        case actionTypes.UPDATE_PRODUCT_LOADING :
            return {
                ...state,
                loading: action.payload,
            }
        case actionTypes.UPDATE_RECOMMENDED_PRODUCTS :
            return {
                ...state,
                recommended: action.payload,
            }
        default:
            return state
    }

}
