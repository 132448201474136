
import { actionTypes } from './actions'

export const initialState = {
  token: null,
  regLoading: false,
  preRegError: null,
  preRegResult: null,
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {

        case actionTypes.PRE_REGISTER_SUCCESS:
            return {
              ...state,
              regLoading:false,
              preRegResult: action.payload,
              preRegError: null
            }
        case actionTypes.PRE_REGISTER_ERROR:
            return {
                ...state,
                regLoading:false,
                preRegResult: null,
                preRegError: action.payload
            }
        case actionTypes.REGISTER_LOADING:
            return {
                ...state,
                regLoading:true
            }
        case actionTypes.SET_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        default:
            return state
    }
}
