import smbService from './sessionInterceptor';

// Stub
// export const getHolidayFromServer = () => {
//   return Promise.resolve({
//     data: {
//       "holiday": true
//     }
//   });
// };

// Actual
export const getHolidayFromServer = () => {
  return smbService.get('/philippine-holiday');
}
