import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import appActions from 'Redux/app/actions';
import authActions from 'Redux/auth/actions';
import AccountNavigationTPL from './AccountNavigation.tpl';

class AccountNavigation extends Component {

    render() {
        const {
          auth,
          toggleAccountNavigation,
          triggerLogout,
          accountNavigationIsVisible
        } = this.props;

        return (<AccountNavigationTPL
                    auth={auth}
                    triggerLogout={triggerLogout}
                    toggleAccountNavigation={toggleAccountNavigation}
                    accountNavigationIsVisible={accountNavigationIsVisible}
                    />)
    }
};


AccountNavigation.propTypes = {};

const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      accountNavigationIsVisible: state.app.accountNavigationIsVisible
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleAccountNavigation: () => {
          dispatch(appActions.toggleAccountNavigation())
        },
        triggerLogout: () => {
          dispatch(appActions.toggleAccountNavigation())
          dispatch(authActions.logOut())
        }
    }
}

AccountNavigation.propTypes = {
  auth: PropTypes.object,
  triggerLogout: PropTypes.func,
  toggleAccountNavigation: PropTypes.func,
  accountNavigationIsVisible: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps )(AccountNavigation);
