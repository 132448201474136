import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import CategoryGateModal from '../CategoryGateModal';
import PickupGateModal from 'scenes/Home/PickupGateModal';
import { removeCookies } from 'utils/commonUtils';

import { Modal } from 'antd';
import CartActions from 'Redux/cart/actions';

    const { 
        CATEGORY_GATE_ID_COOKIE_NAME,
        FULFILLMENTID_COOKIE_NAME,
        LOCATION_CITY_ID_COOKIE_NAME,
        LOCATION_BARANGAY_ID_COOKIE_NAME,
        CATEGORY_GATE_NAME_COOKIE_NAME,
        LOCATION_BARANGAY_NAME_COOKIE_NAME,
        LOCATION_CITY_NAME_COOKIE_NAME,
        PREVIOUS_CATEGORY_GATE_ID_COOKIE_NAME,
        PREVIOUS_CATEGORY_GATE_NAME_COOKIE_NAME
    } = process.env;

    const subheaderStyle = {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    }

    let displayLocation = null;

const LocationAndCategoryComponent = props => {
  return (
    <Fragment>
        <section style={subheaderStyle} className="saved-address">
            <CategoryComponent {...props}/>
            <DeliveryLocationComponent {...props}/>
        </section>
    </Fragment>
  );
};

const CategoryComponent = props => {

    const {cookies, changeLocationHandler, cart, fetchCart, deleteCart, deleteCartItem } = props;    
    const [isVisibleCategoryModal, setIsVisibleCategoryModal] = useState(false);
    const [showLocationGate, setShowLocationGate] = useState(false);
    const [showClearCartConfirmation, setShowClearCartConfirmation] = useState(false);

    let categoryNameCookie = cookies.get('categoryName');

    if (!categoryNameCookie) {
        categoryNameCookie = 'N/A';
    }
    const [categoryName, setCategoryName] = useState(categoryNameCookie);

    // useEffect( async () => {
    //     console.log('useEffect');
    //     await this.changeCategoryNameByLoad();
    // });
    // changeCategoryNameByLoad();

    // const changeCategoryNameByLoad = () => {
    //     let categoryNameCookie = cookies.get('categoryName');
    //     setCategoryName(categoryNameCookie);
    //     console.log('changeCategoryNameByLoad');
    // }

    const handleModalCloseClearCartModal = () => {
        setShowClearCartConfirmation(false);

        let categoryNameCookie = cookies.get('categoryName');
        setCategoryName(categoryNameCookie);

        // revert to original selection
        if(cookies.get('originalCategoryID') && cookies.get('originalCategoryName')) {
            cookies.set('categoryGateId', cookies.get('originalCategoryID'));
            cookies.set('categoryName', cookies.get('originalCategoryName'));
            setCategoryName(cookies.get('originalCategoryName'));
        }

        // window.location.replace("/buy-beer"); // reload to refresh the page category
        // cookies.set('categoryGateId', cookies.get('previousCategoryId'));
        // cookies.set('categoryName', cookies.get('previousCategoryName'));
    }
    
    const handleChangeCategoryType = async () => {
        try {

            let cart_items = cart.shopping_cart;
            let items_to_delete = [];

            // delete cart items
            if(cart_items && cart_items.length > 0) {
                cart_items.map(individual_item => {
                    if(individual_item && individual_item.items.length > 0) {
                        individual_item.items.map( item => {
                            // push ids to array for deletion
                            items_to_delete.push(item.id);
                        }); 
                    }
                });
            }

            // delete items from cart
            if(items_to_delete.length > 0) {
                try {
                    await deleteCartItem(items_to_delete);
                } catch (error) {
                    console.log('Failed to delete the products from cart');
                    console.error(error);
                }
            }
           
            // deleteCart();
            setShowClearCartConfirmation(false);
            setShowLocationGate(true);

        } catch (error) {
            console.log('Error in clearing your existing cart.');
            console.error(error);
        }
    }

    const categoryGateOnClickHandler = ({id, packaging}) => {

        // console.log('FIRST_PREVIOUS_CATEGORY_GATE_ID_COOKIE_NAME', cookies.get(PREVIOUS_CATEGORY_GATE_ID_COOKIE_NAME));
        // console.log('FIRST_PREVIOUS_CATEGORY_GATE_NAME_COOKIE_NAME', cookies.get(PREVIOUS_CATEGORY_GATE_NAME_COOKIE_NAME));

        setCategoryName(packaging);
        setIsVisibleCategoryModal(false);
        
        // Bug here when refresh then don't continue
        // removeCookies(cookies, [FULFILLMENTID_COOKIE_NAME,
        //   LOCATION_CITY_ID_COOKIE_NAME,
        //   LOCATION_BARANGAY_ID_COOKIE_NAME]);

        if(cookies.get('previousCategoryId') == undefined || cookies.get('previousCategoryId') == "") {
            cookies.set('previousCategoryId', id);
        } else if(cookies.get('previousCategoryId') !== id) {
            cookies.set('previousCategoryId', cookies.get('categoryGateId'));
        } else if(cookies.get('previousCategoryId') == cookies.get('categoryGateId')) {
            cookies.set('previousCategoryId', id);
        } else {
            // do nothing if same
            console.log('same previous ID');
        }

        if(cookies.get('previousCategoryName') == undefined || cookies.get('previousCategoryName') == "") {
            cookies.set('previousCategoryName', packaging);
        } else if(cookies.get('previousCategoryName') !== packaging) {
            cookies.set('previousCategoryName', cookies.get('categoryName'));
        } else if(cookies.get('previousCategoryName') == cookies.get('categoryName')) {
            cookies.set('previousCategoryName', packaging);
        } else {
            // do nothing if same
            console.log('same previous ID');
        }

        cookies.set(CATEGORY_GATE_ID_COOKIE_NAME, id);
        cookies.set(CATEGORY_GATE_NAME_COOKIE_NAME, packaging);

        // get cart from props
        const { shopping_cart } = props.cart;
        console.log('shopping_cart', shopping_cart);

        // loop thru cart list
        if(shopping_cart && shopping_cart.length > 0) {
            
            let item_type = [];

            // create an array to push all category types
            // types are: Bottles, Cans, Keg or Pack?
            shopping_cart.map((item) => {
                item_type.push(item.type);
            });

            // console.log('packaging', packaging);
            // console.log('shopping_cart', JSON.stringify(shopping_cart));
            // console.log('item_type', item_type);
            // console.log('ss', !shopping_cart.includes("Keg"));

            // Compare if packaging is in Keg and Keg is not included in the array list of item_type
            if(packaging == 'DRAFT BEER IN KEG' && shopping_cart.length > 0 && !item_type.includes("Keg")) {
                // if packaging is Keg but there is an existing item in cart and Keg is not included meaning 
                // the user will choose an item that has different category
                // if thats the case then show clear cart modal
                setShowClearCartConfirmation(true);
            } else if (packaging != 'DRAFT BEER IN KEG' && !item_type.includes(packaging)) {
                // if packaging is not in Keg and packaging is not included in the item type array then show cart
                setShowClearCartConfirmation(true);
            } else {
                // If items are the same category, hide the clear cart modal and show location gate as next modal
                // append to cart, BC and packs can be group
                setShowClearCartConfirmation(false);
                setShowLocationGate(true);
            }

        } else {
            // if there are no items in cart just continue the standard process
            console.log('No items in cart yet.');
            setShowLocationGate(true);
        }

        // setShowLocationGate(true);
    };

    const onClickCategoryHandler = () => {
        setIsVisibleCategoryModal(true);
    };

    const handleLocationGateContinue = (locName = '') => {

        const { cookies } = props;
        const barangayName = cookies.get(LOCATION_BARANGAY_NAME_COOKIE_NAME);
        const cityName = cookies.get(LOCATION_CITY_NAME_COOKIE_NAME);
        let bc = barangayName + ' ' + cityName;
        if (!barangayName) {
            bc = 'N/A';
        }

        displayLocation = bc;

        console.log('getLocation', displayLocation);

        // displayLocation = cookies.
        //this.displayLocation();
        setShowLocationGate(false);
        changeLocationHandler();

        // window.location.reload();
        
    };

    const categoryModalCloseHandler = () => {
        setIsVisibleCategoryModal(false);
    };

    const pickUpModalCloseHandler = () => {
        setShowLocationGate(false);
    };

    return (
        <Fragment>
            {isVisibleCategoryModal && <CategoryGateModal onCancel={categoryModalCloseHandler} isVisible={isVisibleCategoryModal} onClickHandler={categoryGateOnClickHandler}/>}
            <div style={{textAlign: 'center'}}>
                Product Type:{' '}
                <a onClick={onClickCategoryHandler} style={{ textDecoration: 'underline', cursor: "cursor" }}>
                    {categoryName}<span className="arrow-down" />
                </a>
            </div>

            { showClearCartConfirmation && 
                <Modal
                visible={ showClearCartConfirmation }
                onOk={ handleChangeCategoryType }
                onCancel={ handleModalCloseClearCartModal }
                title="Notice"
                closable={false}
                cancelButtonProps={{ style: { display: 'inline-block', float: 'right', marginLeft: '10px' } }}
                okText="Yes"
                cancelText="No"
                >
                    <p className="mobile-text">Your current cart will be reset. <br/>Orders for Bottles/ Cans and Draft Beer should be in a separate transaction. Continue to reset your cart?</p>
                </Modal>
            }

            {showLocationGate && <PickupGateModal onCancel={pickUpModalCloseHandler} isVisible={showLocationGate} {...props} handleLocationGateContinue={handleLocationGateContinue}/>}
        </Fragment>
    );
};

export const DeliveryLocationComponent = props => {

    const {cookies, changeLocationHandler} = props;
    const [isVisibleLocationModal, setIsVisibleLocationModal] = useState(false);
    const barangayName = cookies.get(LOCATION_BARANGAY_NAME_COOKIE_NAME);
    const cityName = cookies.get(LOCATION_CITY_NAME_COOKIE_NAME);
    let bc = barangayName + ' ' + cityName;
    console.log('barangayName', barangayName);
    if (!barangayName) {
        bc = 'N/A';
    }
    const [locationName, setLocationName] = useState(bc);

    const onClickHandler = () => {
        setIsVisibleLocationModal(true);
    };

    const handleLocationGateContinue = (locName) => {

        displayLocation = null; //set to empty

        setIsVisibleLocationModal(false);
        setLocationName(locName);
        changeLocationHandler();
    };

    const pickModalCloseHandler = () => {
        setIsVisibleLocationModal(false);
      };

    return (
        <Fragment>
            {isVisibleLocationModal && <PickupGateModal onCancel={pickModalCloseHandler} isVisible={isVisibleLocationModal} {...props} handleLocationGateContinue={handleLocationGateContinue}/>}
            <div style={{textAlign: 'center'}}>
                Pick-up/Delivery Location:{' '}
                {/* <a onClick={onClickHandler} style={{ textDecoration: 'underline', cursor: "cursor" }}>
                {locationName}<span className="arrow-down" />
                </a> */}
                <a onClick={onClickHandler} style={{ textDecoration: 'underline', cursor: "cursor" }}>
                { displayLocation ? displayLocation : locationName }<span className="arrow-down" />
                </a>
            </div>
        </Fragment>
    );
};


const mapStateToProps = state => {
    return {
        ...state.cart,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchCart: () => {
            dispatch(CartActions.fetchCart());
        },
        deleteCart: () => {
            dispatch(CartActions.deleteAllCartItemsWithoutFetch())
        },
        deleteCartItem: (items) => {
            dispatch(CartActions.deleteCartItems(items))
        }
    };
};


// export default LocationAndCategoryComponent;
export default connect(mapStateToProps, mapDispatchToProps)(LocationAndCategoryComponent);
