import { getCart, addToCart, deleteCartItems } from "Services/cartServices";
import appActions from 'Redux/app/actions';
import { getToken, setToken, removeToken } from "Services/tokenService";
import { resolve } from "path";
const { PUBLIC_URL } = process.env;

export const actionTypes = {
   ADD_TO_CART: 'ADD_TO_CART',
   UPDATE_CART: 'UPDATE_CART',
   UPDATE_CART_ERROR: 'UPDATE_CART_ERROR',
   CART_LOADING: 'CART_LOADING',
   DELETING_CART_ITEMS: 'DELETING_CART_ITEMS',
   DELETE_CART_ITEMS: 'DELETE_CART_ITEMS',
   RETURN_EMPTIES: 'RETURN_EMPTIES',
   RETURN_EMPTIES_ERROR: 'RETURN_EMPTIES_ERROR',
   RESET_ERRORS: 'RESET_ERRORS',
}


let actions = {
    updateCart: (cart) => {
      return dispatch => {
        dispatch({
            type: actionTypes.UPDATE_CART,
            payload: cart
        })
      }
    },
    returnEmpties: (returnEmptyData) => {
      return async (dispatch) => {
        const token = getToken();

        try {
          const response =  await fetch(`${PUBLIC_URL}/api/v3/return-empty`,{
            method: 'POST',
            headers: {
              'Authorization': "Bearer " + token,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(returnEmptyData)
          });
          if (!response.ok) {
            throw response;
          }
          const responseData = await response.json();
          dispatch({
            type: actionTypes.UPDATE_CART,
            payload: responseData
        });

        } catch (err) {
          if (err.text) {
            err.text().then( errorMessage => {
              const error = JSON.parse(errorMessage);
              // console.log('errorMessage', error.error);
              dispatch({
                type: actionTypes.RETURN_EMPTIES_ERROR,
                payload: {
                    message: error.error
                }
              })
            })
          } else {
            // this.props.dispatch(displayTheError('Error.')) // Hardcoded error here
          }
        }
      };
    },
    resetErrorMessage: () => {
      return dispatch =>  {
        dispatch({
          type: actionTypes.RESET_ERRORS,
        });
      };
    },

    fetchCart: () => {
      return async (dispatch, getState) => {
          let { isLoggedIn } = getState().auth

          dispatch({
              type: actionTypes.CART_LOADING,
          });
          dispatch(appActions.toggleBodyLoader(true));
          try {
            console.log('isLoggedIn', isLoggedIn)
            let result = await getCart(!isLoggedIn);

            dispatch({
                type: actionTypes.UPDATE_CART,
                payload: result
            });

          } catch(error) {
              if(error.status) {
                  if(error.status == 404){
                      dispatch({
                          type: actionTypes.UPDATE_CART_ERROR,
                          payload: {
                              message: error.data.error
                          }
                      })
                  }
              } else {
                  dispatch({
                      type: actionTypes.UPDATE_CART_ERROR,
                      payload: error,

                  })
              }

          }

          dispatch(appActions.toggleBodyLoader(false));

      }
    },
    addToCart: (item, update=false) => {
        return async (dispatch, getState) => {
            try{
              let result = await addToCart(item, update);
              dispatch(actions.fetchCart())
            }catch(e){
                console.log(e)
            }
        }
    },
    deletingCartItems: (items = []) => {
      return {
        type: actionTypes.DELETING_CART_ITEMS,
        cartItems: items
      }
    },
    deleteAllCartItemsWithoutFetch: () => {
      return {
        type: actionTypes.UPDATE_CART,
        payload: []
      }
    },
    deleteCartItems: (items=[]) => {

      console.log('deleting this item', items);

      return async (dispatch, getState) => {
        try {
          dispatch(actions.deletingCartItems(items));

          let result = await deleteCartItems(items);

          dispatch(actions.fetchCart());

          console.log(result);
          dispatch(actions.deletingCartItems([]));
        } catch(e) {
          console.log(e);
        }
      }
    }
}




export default actions;
