import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CartItemTPL from './CartItem.tpl';
import { addToCart } from 'Services/cartServices';


class CartItem extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isDeletingThisCartItem: false
      };
    }

    handleAddToCart = async (id, quantity) => {
      const { updateReduxCart } = this.props;

      await addToCart({ product_id: id, quantity }, true)
      updateReduxCart();
    }

    handleDeleteItem = (itemId) => {
      const { deleteCartItem } = this.props;

      return (e)  => {

        this.setState({
          isDeletingThisCartItem: false
        });

        deleteCartItem([itemId]);
      }
    }

    handleAskToDeleteCartItem = () => {
      this.setState({
        isDeletingThisCartItem: true
      });
    }

    handleCancelDeletingCartItem = () => {
      this.setState({
        isDeletingThisCartItem: false
      })
    }

    render() {
      const {
        isDeletingThisCartItem
      } = this.state;

      return (<CartItemTPL
                isDeletingThisCartItem={isDeletingThisCartItem}
                addToCart={this.handleAddToCart}
                handleDeleteItem={this.handleDeleteItem}
                handleAskToDeleteCartItem={this.handleAskToDeleteCartItem}
                handleCancelDeletingCartItem={this.handleCancelDeletingCartItem}
                {...this.props} />)
    }
};


CartItem.propTypes = {
  item: PropTypes.object,
  updateReduxCart: PropTypes.func,
  deleteCartItem: PropTypes.func
};

export default CartItem;
