const initialState = { 
    showCategatoryGate: false,
    showLocationGate: false
};

export const actionTypes = {
    SHOW_CATEGORY_GATE: 'show_category_gate',
    SHOW_LOCATION_GATE: 'show_location_gate',
    HIDE_GATES: 'hide_gates',
    
};

const gateReducer = (state = initialState, action) => {

    if (action.type === actionTypes.SHOW_CATEGORY_GATE) {
        return {
            showCategoryGate: true,
            showLocationGate: false,
        };
    }

    if (action.type === actionTypes.SHOW_LOCATION_GATE) {
        return {
            showCategoryGate: false,
            showLocationGate: true,
        };
    }

    if (action.type === actionTypes.HIDE_GATES) {
        return {
            showCategoryGate: false,
            showLocationGate: false,
        };
    }

    return state;
};


export let actions = {
    displayCategoryGate: () => {
        return dispatch => {
            dispatch({type: actionTypes.SHOW_CATEGORY_GATE});
        };
    },

    hideCategoryGate: () => {
        return dispatch => {
            dispatch({
                type: actionTypes.HIDE_CATEGORY_GATE
            });
        };
    },
    
};


export default gateReducer;