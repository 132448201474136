import React, { Component } from 'react';
// import { initWebAPIInterceptor } from 'services/api/webAPI';
import {
  getProvinces,
  getCities,
  getBarangays
} from 'Services/locationServices';

function withSelectOptions(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        provinces: [],
        cities: [],
        barangays: []
      };
    }

    componentDidMount() {
      // initWebAPIInterceptor();

      if (!WrappedComponent.stopFetchingOptions) {
        this.getAllProvinces();
      }
    }

    getAllProvinces = async () => {
      try {
        const response = await getProvinces();

        // console.log(response);

        this.setState({
          provinces: Object.values(response.data)
        });
      } catch (error) {
        // this.setState({
        //   provinces: [],
        //   cities: []
        // });
      }
    };

    getCities = async provinceId => {
      try {
        const response = await getCities(provinceId);

        console.log(Object.values(response.data));

        this.setState({
          cities: Object.values(response.data)
        });
      } catch (error) {
        // console.log(error);
        // this.setState({
        //   cities: []
        // });
      }
    };

    getBarangays = async cityId => {
      try {
        const response = await getBarangays(cityId);

        console.log(Object.values(response.data));

        this.setState({
          barangays: Object.values(response.data)
        });
      } catch (error) {
        // console.log(error);
        // this.setState({
        //   cities: []
        // });
      }
    };

    render() {
      const { cities, provinces, barangays } = this.state;

      console.log(cities);

      return (
        <WrappedComponent
          cities={cities}
          provinces={provinces}
          barangays={barangays}
          getBarangaysByCity={this.getBarangays}
          getCitiesByProvince={this.getCities}
          getAllProvinces={this.getAllProvinces}
          {...this.props}
        />
      );
    }
  };
}

export default withSelectOptions;
