import smbService from './sessionInterceptor';

export const getGoToCases = (goToCaseId, barangayId) => {
  return smbService.get(`/user/goto-case?barangay=${barangayId}`);
}

export const getGoToCase = (goToCaseId) => {
  return smbService.get(`/user/goto-case/${goToCaseId}`);
}

export const getCaseTypesAndSizes = () => {
  return smbService.get('/user/goto-case/options')
}

export const createGoToCase = (data) => {
  return smbService.post('/user/goto-case', data);
}

export const editGoToCase = (data) => {
  return smbService.put('/user/goto-case', data);
}

export const getGoToCaseOptions = (goToCaseId) => {
  return smbService.get(`/user/goto-case/${goToCaseId}`)
}

export const putGoToCaseItems = (caseObj, items) => {
  const request = {
    case_id: caseObj.id,
    name: caseObj.name,
    items: [
      ...items
    ]
  };

  return smbService.put('/user/goto-case', request);
}

export const deleteCase = (caseId) => {
  return smbService.delete(`/user/goto-case/${caseId}`);
}

export const deleteCaseItems = (caseItemId, caseId) => {
  return smbService.delete(`/user/goto-case/items`, {
    data: {
      "case_id": caseId,
      "items": [caseItemId]
    }
  })
}


export const addCaseToCart = (caseId, barangay_id) => {
  return smbService.post(`/user/goto-case/cart/${caseId}/barangay/${barangay_id}`)

};


export const getGoToCasesForCart = (caseItems = []) => {

  return smbService.get(`/user/goto-case`, {
    params: {
      "cart_items": [
        ...caseItems
      ]
    }
  })

}


export const createGoToCaseFromCart = (requestBody) => {
  return smbService.put('/user/goto-case', requestBody);
}
