import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import './Product.scss';

class Product extends Component {
  state = {};

  render() {
    let { image, product, addCart } = this.props;
    const productType = get(product, 'types') ? product.types : '';
    const productId = get(product, 'id') ? product.id : '';
    const productSlug = get(product, 'slug') ? product.slug : '';
    const productName = get(product, 'product_name') ? product['product_name'] : '';
    const productSKU = get(product, 'sku') ? product.sku : '';
    const productPrice = get(product, 'price') ? product.price : 0;

    // const imageContainerClass = product['product_image'] ? 'product-img-container' : 'product-img-container -no-image';

    const imageContainerClass = [
      'product-img-container',
      !product['product_image'] && '-no-image',
      product['packaging'] === 'Keg' && 'keg-wrapper',
    ]
      .filter(Boolean)
      .join(' ');

    const isOrderable = get(product, 'orderable');
    let quantity = 1;

    productType === 'Cans' ? (quantity = 24) : (quantity = 1);
    // console.log(product, quantity);

    return (
      <div className='product-cont'>
        <Link to={`/single-product/${productSlug}`}>
          <div className={imageContainerClass}>
            {product['product_image'] ? <img className='product-image' alt='sml' src={product['product_image']} /> : <div className='no-image'>No image available</div>}
          </div>
          <div className='product-details'>
            <span className='product-name'>{`${productName}`}</span>
            <span className='product-desc'>{`${productSKU}`}</span>
            <span className='product-price'>{'P ' + numeral(productPrice).format('0,0.00')}</span>
          </div>
        </Link>  
        {/* <Link  to={`/single-product/${id}`} > */}

        {
        !isOrderable ? 
          <Button className='cart-btn' disabled>
            Out Of Stock
          </Button>
        : 
          <Button onClick={addCart && addCart(productId, quantity)} className='cart-btn'>
            <Icon type='plus' />
            Add to Cart
          </Button>
        }
      
        {/* </Link> */}
      </div>
    );
  }
}

export default Product;
