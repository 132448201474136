import { actionTypes } from './actions';
import { actionTypes as authActionTypes } from './../auth/actions';
import { LOCATION_CHANGE } from 'connected-react-router';

export const initialState = {
  windowWidth: 0,
  windowHeight: 0,
  cartDrawerIsVisible: false,
  accountNavigationIsVisible: false,
  mobileNavigationVisibile: false,
  ageVerify: undefined,
  privacyContent: '',
  termsContent: '',
  bodyLoaderIsVisible: false,
  bodyLoaderMessage: '',
  cartConflict: false
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_CART_CONFLICT:
      return {
        ...state,
        cartConflict: action.cartConflict
      };
    case actionTypes.UPDATE_HEIGHT_AND_WIDTH:
      return {
        ...state,
        windowHeight: action.height,
        windowWidth: action.width
      };
    case actionTypes.TOGGLE_CART_DRAWER:
      return {
        ...state,
        cartDrawerIsVisible: action.state == undefined ? !state.cartDrawerIsVisible : action.state
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        cartDrawerIsVisible: false,
        accountNavigationIsVisible: false,
        mobileNavigationIsVisible: false,
        bodyLoaderIsVisible: false
      };
    case actionTypes.TOGGLE_ACCOUNT_NAVIGATION:
      return {
        ...state,
        accountNavigationIsVisible: !state.accountNavigationIsVisible,
        mobileNavigationIsVisible: state.mobileNavigationIsVisible ? !state.mobileNavigationIsVisible : state.mobileNavigationIsVisible
      };
    case actionTypes.TOGGLE_MOBILE_NAVIGATION:
      return {
        ...state,
        mobileNavigationIsVisible: !state.mobileNavigationIsVisible,
        accountNavigationIsVisible: state.accountNavigationIsVisible ? !state.accountNavigationIsVisible : state.accountNavigationIsVisible
      };
    case authActionTypes.SET_USER:
      const { privacyContent, termsContent, aboutUsContent } = action.payload;
      return {
        ...state,
        privacyContent: privacyContent,
        termsContent: termsContent,
        aboutUsContent: aboutUsContent,
      };
    case actionTypes.TOGGLE_BODY_LOADER:
      return {
        ...state,
        bodyLoaderIsVisible: action.isVisible,
        bodyLoaderMessage: action.message
      };
    default:
      return state;
  }
}
