import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import '../CartItems.scss';
import Counter from 'Components/Counter/Counter';
import numeral from 'numeral'
import {debounce, get} from 'lodash'

class CartItemTPL extends Component {
    constructor(props){
      super(props);
      this.debounceAddToCart = debounce(props.addToCart, 500);
      let quantity = get(props, 'item.quantity', 1);
      this.state = {
        quantity: quantity
      }
    }

    componentDidUpdate(prevProps) {
      const prevQuantity = get(prevProps, 'item.quantity');
      const nextQuantity = get(this.props, 'item.quantity');

      if(prevQuantity !== nextQuantity) {
        this.setState({
          quantity: nextQuantity
        });
      }
    }



    handleAddToCart = (id, quantity) => {
      // Update the quantity of this cart item then update the one in the server
      this.setState({
        quantity
      }, () => this.debounceAddToCart(id, quantity));
    }



    render() {
      let { item,
            handleDeleteItem,
            handleAskToDeleteCartItem,
            isDeletingThisCartItem,
            handleCancelDeletingCartItem } = this.props;
      let { product_name, product_image, price } = item
      let { quantity } = this.state

      // console.log(product_name, quantity)
      return (
        <li className="cart-group-item">
          <div className="cart-item-image-col">
            {
              product_image ?
              <img src={product_image} alt="" className="cart-item-image"/>
              :
              <div className="no-image">No Image available</div>
            }
          </div>

          <div className="cart-item-info-actions-col">
            <div className="cart-item-name-col">
              <p className="cart-item-name">{product_name}</p>
              {/* Mobile Only */}
              <p className="cart-item-total-price">
                <span className="full-price">{numeral(price).format('0,0.00')}</span>
                {/* <span className="discounted-price-from">P96.00</span> */}
              </p>
              {/* Desktop Only */}
              <p className="cart-item-unit-price">
                <span className="final-item-price">P {numeral(price).format('0,0.00')}</span>
                {/* <span className="initial-item-price">P 32.00/bottle(50% off)</span> */}
              </p>
              <button className="delete-cart-item -transparent" onClick={handleAskToDeleteCartItem}>
                <span className="icon-trash"></span>
              </button>
            </div>

            <div className="cart-qty-col">
              <Counter value={quantity} min={1} onChange={(v)=>this.handleAddToCart(item.id, v)}/>
              {/* <Debounce quantity={quantity} time="1000" handler="onChange">
                  <Counter value={quantity} min={1} onChange={(v)=>addToCart(item.id,v)}/>
              </Debounce> */}
              <button className="delete-cart-item -transparent" onClick={handleAskToDeleteCartItem}>
                <span className="icon-trash"></span>
              </button>
            </div>

            <div className="cart-item-total-price-col">
              <span className="full-price">{numeral(quantity * price).format('0,0.00')}</span>
              {/* <span className="discounted-price-from">P96.00</span> */}
            </div>
          </div>


          <Modal visible={isDeletingThisCartItem}
                  onOk={handleDeleteItem(item.id)}
                  onCancel={handleCancelDeletingCartItem}
                  title="Warning">
            <p>Are you sure you want to delete {product_name}?</p>
          </Modal>

        </li>
      )
    }

};

export default CartItemTPL;


CartItemTPL.propTypes = {
    addToCart: PropTypes.func,
    handleDeleteItem: PropTypes.func,
    isDeletingThisCartItem: PropTypes.bool,
    handleAskToDeleteCartItem: PropTypes.func,
    handleCancelDeletingCartItem: PropTypes.func
};
