import React, {Component, PureComponent} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CartCaseTPL from './CartCase.tpl';
import cartActions from 'Redux/cart/actions';

class CartCase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      goToCaseSelectorOpen: false,
      goToCaseSelectorResult: '', // SUCCESS | FAIL
      goToCaseSelectorResultMessage: '',
      goToCaseItems: null,
      isDeletingCase: false
    }
  }


  handleDeleteCase = (itemIds) => {
    const { deleteCartCase } = this.props;

    return ()  => {


      deleteCartCase(itemIds);

      this.setState({
        isDeletingCase: false
      });

    }
  }

  handleAskDeletingCase = (isDeleting) => {

    return (e) => {
      this.setState({
        isDeletingCase: isDeleting
      });

      e.stopPropagation();
    }
  };




  handleInitiateSaveGoToCase = (caseItems) => {
    return (e) => {

      this.setState({
        goToCaseSelectorOpen: true,
        goToCaseItems: [
          ...caseItems
        ]
      })
    }
  }

  handleCloseSaveGoToCase = () => {
    this.setState({
      goToCaseSelectorOpen: false,
      goToCaseItems: null
    })
  }

  setGoToCaseSelectorResult = (result, resultMessage) => {
    this.setState({
      goToCaseSelectorResult: result,
      goToCaseSelectorResultMessage: resultMessage
    });
  }

  handleSuccessAddingCartToGTC = () => {
    this.setState({
      goToCaseSelectorOpen: false,
      goToCaseSelectorResult: '', // SUCCESS | FAIL
      goToCaseSelectorResultMessage: ''
    });
  }

  handleFailAddingCartToGTC = () => {
    this.setState({
      goToCaseSelectorResult: '', // SUCCESS | FAIL
      goToCaseSelectorResultMessage: ''
    });
  }

  render() {
    const {
            goToCaseSelectorOpen,
            goToCaseItems,
            goToCaseSelectorResult,
            goToCaseSelectorResultMessage,
            isDeletingCase
          } = this.state;
    const { auth,
            forceHideSummary,
            cartCase } = this.props;

    return (<CartCaseTPL
      auth={auth}
      forceHideSummary={forceHideSummary}
      cartCase={cartCase}
      handleDeleteCase={this.handleDeleteCase}
      goToCaseSelectorOpen={goToCaseSelectorOpen}
      goToCaseItems={goToCaseItems}
      handleInitiateSaveGoToCase={this.handleInitiateSaveGoToCase}
      handleCloseSaveGoToCase={this.handleCloseSaveGoToCase}
      setGoToCaseSelectorResult={this.setGoToCaseSelectorResult}
      goToCaseSelectorResult={goToCaseSelectorResult}
      goToCaseSelectorResultMessage={goToCaseSelectorResultMessage}
      handleSuccessAddingCartToGTC={this.handleSuccessAddingCartToGTC}
      handleFailAddingCartToGTC={this.handleFailAddingCartToGTC}
      handleAskDeletingCase={this.handleAskDeletingCase}
      isDeletingCase={isDeletingCase}
    />)
  }
};


CartCase.propTypes = {
  auth: PropTypes.object,
  forceHideSummary: PropTypes.bool,
  deletCartCase: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteCartCase: (items) => {
      dispatch(cartActions.deleteCartItems(items))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartCase);
