
import { actionTypes } from './actions'

export const initialState = {
    cart: [],
    loading:false,
    addingCartLoading: false,
    errorMessage: null,
    deletingCartItems: [],
}

export default function authReducer(state = initialState, action) {

    switch (action.type) {
        case actionTypes.UPDATE_CART :
            return {
                ...state,
                cart: action.payload,
                loading:false
            }
        case actionTypes.CART_LOADING :
            return {
                ...state,
                loading:true
            }
        case actionTypes.UPDATE_CART_ERROR :
            return {
                ...state,
                loading:false,
                cart:[],
                errorMessage: action.payload.message
            }
        case actionTypes.RETURN_EMPTIES_ERROR:
            return {
                ...state,
                loading:false,
                cart: state.cart,
                errorMessage: action.payload.message
            }
        case actionTypes.RESET_ERRORS:
            return {
                ...state,
                loading:false,
                cart: state.cart,
                errorMessage: null
            }
        default:
            return state
    }

}
