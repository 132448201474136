import React from 'react';
import locationIcon from './location-icon.png'

const style = {
    width: 90,
    height: 110,
    filter: '#f8a500',
}

function LocationIcon() {
    return(
        <div>
            <img style={style} src={locationIcon} alt="location-icon" />
        </div>
    )
}

export default LocationIcon