import React from 'react';
import PropTypes from 'prop-types';
import './AppHeader.scss';
import { Layout, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import siteLogo from 'Assets/images/logo-new.png';
import CartIndicator from 'Components/Cart/components/CartIndicator/CartIndicator';
import { useEffect } from 'react';

const AppHeaderTPL = props => {

  const { Header } = Layout;
  const { auth, menuKeys, handleAccountNavigation, handleMobileNavigation, handleCartClick, handleMenuClick } = props;

  console.log('getting_auth', auth);

  return (
    <Header className="app-header">
      <div className="header-inner">
        <section className="top-header">
          <div className="centered-container">
            <nav className="header-sub-menu">
              <ul className="header-sub-menu-links">
                <li className="header-sub-menu-links-item">
                  <Link to="/how-to-order" className="header-sub-menu-link">
                    How it Works
                  </Link>
                </li>
                <li className="header-sub-menu-links-item">
                  <Link to="/faqs" className="header-sub-menu-link">
                    FAQs
                  </Link>
                </li>
                <li className="header-sub-menu-links-item">
                  <Link to="/contact-us" className="header-sub-menu-link">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </section>
        <section className="main-header">
          <div className="centered-container">
            <div className="main-header-container">
              <div className="mobile-menu-container">
                <button className="mobile-hamburger" onClick={handleMobileNavigation}>
                  <span className="patty" />
                  <span className="patty" />
                  <span className="patty" />
                </button>
              </div>
              <div className="logo-container">
                <a href="/" className="site-logo-link">
                  <img className="site-logo" src={siteLogo} alt="" />
                </a>
              </div>
              <nav className="main-menu">
                <ul className="main-menu-links">
                  <li className="main-menu-links-item">
                    <Link to="/" className="main-menu-link">
                      Home
                    </Link>
                  </li>
                  <li className="main-menu-links-item">
                    <Link to="/buy-beer" className="main-menu-link">
                      Buy Beer
                    </Link>
                  </li>
                  <li className="main-menu-links-item">
                    <Link to="/promos" className="main-menu-link">Promos</Link>
                  </li>
                  <li className="main-menu-links-item">
                    <Link to="/whats-new" className="main-menu-link">What's New</Link>
                  </li>
                </ul>
              </nav>
              <div className="user-actions-container">
                <div className="user-action-container">
                  <Tooltip title="My Account">
                    <button className="user-action-trigger -icon profile-icon" onClick={handleAccountNavigation}>
                      <span className="icon-user" />
                      { auth.user && <span className="user-firstname">{auth.user.firstname}</span> }
                    </button>
                  </Tooltip>
                </div>
                <div className="user-action-container">
                  <Tooltip title="Cart">
                    <Link to="/cart" className="user-action-trigger -svg cart-icon">
                      <CartIndicator />
                    </Link>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Header>
  );
};

export default AppHeaderTPL;

AppHeaderTPL.propTypes = {
  auth: PropTypes.object,
  menuKeys: PropTypes.object,
  handleAccountNavigation: PropTypes.func,
  handleCartClick: PropTypes.func,
  handleMenuClick: PropTypes.func
};
