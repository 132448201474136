import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { compose } from 'redux';
import { addToCart } from 'Services/cartServices';
import HomeTPL from './Home.tpl';
import CartActions from 'Redux/cart/actions';
import appActions from 'Redux/app/actions';
import { push } from 'connected-react-router';
import withHomeModal from 'Components/Modal/withHomeModal/withHomeModal';
import moment from 'moment';
import { Button, Typography } from 'antd';

class Home extends Component {
  componentDidMount() {
    const { location, onAccountSuccessDelete } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const accountDeleteSuccess = urlParams.getAll('accountSuccessDelete').toString();

    if (accountDeleteSuccess === 'yes') {
      onAccountSuccessDelete &&
        onAccountSuccessDelete(props => {
          const { onOk } = props;

          return (
            <div className='smb-modal-body --form-result --form-success'>
              <div className='modal-result-message-container'>
                <Typography.Title level={1} className='success-title'>
                  ACCOUNT DELETED
                </Typography.Title>
                <Typography.Text className='success-paragraph'>You successfully deleted your account on {moment(new Date()).format('MMMM DD, YYYY [at] LT')}</Typography.Text>
              </div>
              <div className='modal-action-container'>
                <Button type='primary' onClick={onOk} className='modal-ok-trigger'>
                  CONTINUE AS GUEST
                </Button>
              </div>
            </div>
          );
        });
    }
  }
  addToCartAction = async (product_id, quantity) => {
    try {
      await addToCart({
        product_id,
        quantity
      });

      this.setState({
        successModalVisibility: true,
        modalMessage: 'You sucessfully added this product to your shopping cart.',
        addingToCart: false
      });

      await this.props.fetchCart();
      this.props.toggleCartDrawer();
    } catch (e) {
      console.log(e);
      this.setState({
        successModalVisibility: true,
        modalMessage: 'Something went wrong adding this product to your shopping cart.',
        addingToCart: false
      });
    }
  };

  handleAddToCart = async (product_id, quantity) => {
    this.setState(
      {
        addingToCart: true
      },
      () => this.addToCartAction(product_id, quantity)
    );
  };

  // update state when user change location
  // so the products will change also
  // accordingly
  changeLocationHandler = () => {
    this.props.goTo('/buy-beer');
  };

  render() {
    let { auth, cookies } = this.props;
    return <HomeTPL addToCart={this.handleAddToCart} changeLocationHandler={this.changeLocationHandler} cookies={cookies} auth={auth} />;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCart: () => {
      dispatch(CartActions.fetchCart());
    },
    toggleCartDrawer: val => {
      dispatch(appActions.toggleCartDrawer(val));
    },
    goTo: url => {
      dispatch(push(url));
    }
  };
};

export default compose(withHomeModal, connect(mapStateToProps, mapDispatchToProps))(withCookies(Home));
