import React, { Component } from "react";
// import PropTypes from 'prop-types';
import MaintenanceTPL from "./Maintenance.tpl";

class Maintenance extends Component {
  render() {
    return <MaintenanceTPL />;
  }
}

export default Maintenance;
