import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import appActions from 'Redux/app/actions';
import MobileNavigationTPL from './MobileNavigation.tpl';

class MobileNavigation extends Component {
  render() {
    const { toggleMobileNavigation, mobileNavigationIsVisible } = this.props;

    return <MobileNavigationTPL toggleMobileNavigation={toggleMobileNavigation} mobileNavigationIsVisible={mobileNavigationIsVisible} />;
  }
}

MobileNavigation.propTypes = {};

const mapStateToProps = state => {
  return {
    mobileNavigationIsVisible: state.app.mobileNavigationIsVisible
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleMobileNavigation: () => {
      dispatch(appActions.toggleMobileNavigation());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileNavigation);
