import React from 'react';
import PropTypes from 'prop-types';
import './CartIndicator.scss';
import emptyCaseSVG from 'Assets/images/ico-case-empty.svg';
import caseSVG from 'Assets/images/ico-case-full.svg';


const CartIndicatorTPL = (props) => {
    const { cartItemsQty } = props;
    return (
    <div className="cart-indicator">
      {
        cartItemsQty && cartItemsQty > 0 ?
        <img src={caseSVG} className=""  width={33} height={39}/>
        :
        <img src={emptyCaseSVG} className="empty-icon" width={33} height={27} />
      }

      {
        cartItemsQty && cartItemsQty > 0 ?
        <span className="cart-items-counter">{cartItemsQty}</span> : null
      }
    </div>)
};

export default CartIndicatorTPL;


CartIndicatorTPL.propTypes = {
  cartItemsQty: PropTypes.number
};
