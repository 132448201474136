import { preRegister, manualRegister } from "Services/registrationServices";
import { get } from 'lodash';
import appActions from 'Redux/app/actions';
import { setToken } from "Services/tokenService";

export const actionTypes = {
    PRE_REGISTER: 'PRE_REGISTER',
    REGISTER_LOADING: 'REGISTER_LOADING',
    PRE_REGISTER_SUCCESS: 'PRE_REGISTER_SUCCESS',
    PRE_REGISTER_ERROR: 'PRE_REGISTER_ERROR',
    SET_TOKEN: 'SET_TOKEN'
}


const transformErrorFromApi = (error) => {
    let { errors } = error.data;

    return errors
}

let actions = {
    preRegister: (userDetails, setErrors) => {
        return async (dispatch) => {
            try {
                dispatch({
                  type: actionTypes.REGISTER_LOADING,
                  payload: {}
                });

                dispatch(appActions.toggleBodyLoader(true));

                let response = await preRegister(userDetails);

                dispatch({
                  type: actionTypes.PRE_REGISTER_SUCCESS,
                  payload: response.data
                })

                dispatch(appActions.toggleBodyLoader(false));

                return Promise.resolve(response);

            } catch(error) {

                dispatch({
                    type: actionTypes.PRE_REGISTER_ERROR,
                    payload: error
                });

                dispatch(appActions.toggleBodyLoader(false));

                return Promise.reject(error);
            }
        }

    },
    clearResult: () => {
      return (dispatch) => {
        dispatch({
          type: actionTypes.PRE_REGISTER_SUCCESS,
          payload: null
        });

        dispatch({
          type: actionTypes.PRE_REGISTER_ERROR,
          payload: null
        });
      }
    },
    setToken: (token) => {
        return dispatch => {
            dispatch({
                type: actionTypes.SET_TOKEN,
                payload: token
            })
        }
    },
    manualRegister: (userDetails, setErrors) => {
        return async (dispatch, getState) => {
          
          try {
            let { token } = getState().registration

            dispatch({
              type: actionTypes.REGISTER_LOADING,
              payload: {}
            })


            dispatch(appActions.toggleBodyLoader(true));

            let response = await manualRegister(token, userDetails);

            console.log('RegistrationResponse', response);

            setToken(response.headers['access-token']);

            dispatch({
              type: actionTypes.PRE_REGISTER_SUCCESS,
              payload: response.data
            });

            // dispatch(authActions.initUser());

          } catch(error) {
            const errorMessages = get(error, 'response.data.errors')  || 'Something went wrong creating your account. Please try again.';
            const errorStatus = get(error, 'response.status');
            console.log(error)

            if(errorStatus) {
              if(errorStatus === 422){
                setErrors(errorMessages)
              }
            }

            dispatch({
                type: actionTypes.PRE_REGISTER_ERROR,
                payload: errorMessages
            })
          }

          dispatch(appActions.toggleBodyLoader(false));
        }
    }
}





export default actions;
