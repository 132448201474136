export const actionTypes = {
  UPDATE_HEIGHT_AND_WIDTH: 'UPDATE_HEIGHT_AND_WIDTH',
  TOGGLE_CART_DRAWER: 'TOGGLE_CART_DRAWER',
  TOGGLE_ACCOUNT_NAVIGATION: 'TOGGLE_ACCOUNT_NAVIGATION',
  TOGGLE_MOBILE_NAVIGATION: 'TOGGLE_MOBILE_NAVIGATION',
  TOGGLE_BODY_LOADER: 'TOGGLE_BODY_LOADER',
  UPDATE_CART_CONFLICT: 'UPDATE_CART_CONFLICT'
};

let actions = {
  isCartConflict: (cartConflict) => {
    return {
      type: actionTypes.UPDATE_CART_CONFLICT,
      cartConflict
    }
  },
  updateHeightAndWidth: (height, width) => {
    return {
      type: actionTypes.UPDATE_HEIGHT_AND_WIDTH,
      height,
      width
    };
  },
  toggleCartDrawer: (state = undefined) => {
    return {
      type: actionTypes.TOGGLE_CART_DRAWER,
      state
    };
  },
  toggleAccountNavigation: () => {
    return {
      type: actionTypes.TOGGLE_ACCOUNT_NAVIGATION
    };
  },
  toggleMobileNavigation: () => {
    return {
      type: actionTypes.TOGGLE_MOBILE_NAVIGATION
    };
  },
  toggleBodyLoader: (isVisible = false, message) => {
    return {
      type: actionTypes.TOGGLE_BODY_LOADER,
      isVisible,
      message
    };
  }
};

export default actions;
