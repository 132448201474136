import React, { Component, Suspense } from 'react';
import SuspenseFallback from 'Components/SuspenseFallback';
import RouteSwitcher from './RouteSwitch';
import { connect } from 'react-redux';
import routes from 'Routes';

class Router extends Component {

  render() {
    const { cookies, ageVerify, auth } = this.props;
    return (
      <>
      <Suspense fallback={<SuspenseFallback />}>
        <RouteSwitcher
          routes={routes}
          auth={auth}
          cookies={cookies}
          ageVerify={ageVerify}
          />
      </Suspense>
      </>
    )
  }

}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(Router);
