import axios from 'axios';
import smbService from './sessionInterceptor';


export const issueGuestToken = () => {
  const { PUBLIC_URL, API_VERSION } = process.env;
  return new Promise(async (resolve, reject) => {
    try {
      console.log('Start fetching guest token...');

      let result = await axios.post(PUBLIC_URL + '/api/' + API_VERSION + '/guest-token');

      console.log('result', result);

      resolve(result.headers['access-token']);
    } catch (e) {
      reject(e);
    }
  });
};

export const getUserInfo = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await smbService.get('/user');
      resolve(result.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const getPolicy = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await smbService.get('/user');
      resolve(result.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const setPolicy = number => {
  return smbService.put(`/user/website-policy`, {
    policy_agreement_accepted: number
  });
};

export const login = credentials => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await smbService.post('/login', credentials, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const checkExistingUser = email => {
  return smbService.post(`/check-email`, {
    email
  });
};

export const socialLogin = socialAccountInfo => {
  let requestHeader = {};

  if (socialAccountInfo.socialSource === 'fb') {
    requestHeader = {
      headers: { 'fb-token': socialAccountInfo.userAccessToken }
    };
  } else if (socialAccountInfo.socialSource === 'google') {
    requestHeader = {
      headers: { 'google-token': socialAccountInfo.userAccessToken }
    };
  } else {
    throw new Error();
  }

  return smbService.post('/login', {}, requestHeader);
};

export const validateRegistrationToken = (token) => {
  return smbService.get('/check-registration-token', {
    headers: {
      "registration-token": token
    }
  })
}

export const validateResetPasswordToken = (token) => {
  return smbService.post('/reset/password/verify-link', {
    "suid": token
  });
}
