import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { isOperatingRightNow, operationDaysAndTime } from 'Utils/operationTimeUtils';


class OperatingTimeContent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isContentLoaded: false,
      isOperational: false
    }

  }

  componentDidMount() {
    this.startFetchingOperatingDays();
  }

  startFetchingOperatingDays() {
      this.setState({
        isOperational: isOperatingRightNow(operationDaysAndTime),
        isContentLoaded: true
      });
  }


  render() {
    const { isContentLoaded, isOperational } = this.state;
    const { showWhenNotOperating } = this.props;
    
    const shouldShowContent = showWhenNotOperating ? !isOperational : isOperational;
    // const shouldShowContent = false;

    return (
      isContentLoaded ?
        <>{
          shouldShowContent ?
            this.props.children
          : null
          }
        </>

      : null
    )
  }
};


OperatingTimeContent.propTypes = {};

export default OperatingTimeContent;


