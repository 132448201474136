import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { isWithinHolidaySeason, isTimeBoundHolidayToday, timeBoundHolidays, holidaySeason } from 'Utils/holidayUtils';
import { getHolidayFromServer } from 'Services/holidayServices';


class HolidayContent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isContentLoaded: false,
      isHolidayToday: false
    }

  }

   componentDidMount() {
      // console.log(this.props);

     if(this.props.useLocaltime) {
      this.startFetchingLocalHoliday();
     } else {
      this.startFetchingHoliday();
     }
  }

  startFetchingLocalHoliday() {
    const { timeBoundHoliday } = this.props;

    // console.log(isTimeBoundHolidayToday(timeBoundHolidays));

    this.setState({
      isHolidayToday: timeBoundHoliday ? isTimeBoundHolidayToday(timeBoundHolidays) : isWithinHolidaySeason(holidaySeason),
      isContentLoaded: true
    });

  }

  async startFetchingHoliday() {

    try {
      const response =  await getHolidayFromServer();

      console.log(response.data.holiday);

      this.setState({
        isHolidayToday: response.data.holiday,
        isContentLoaded: true
      });

    } catch (error) {
      this.setState({
        isContentLoaded: true,
        isHolidayToday: false
      });
    }
  }


  render() {
    const { isContentLoaded, isHolidayToday } = this.state;
    const { showWhenNotHoliday } = this.props;
    const shouldShowContent = showWhenNotHoliday ? !isHolidayToday : isHolidayToday;


    return (
      isContentLoaded ?

        <>{
          shouldShowContent ?
            this.props.children
          : null
          }
        </>

      : null
    )
  }
};


HolidayContent.propTypes = {};

export default HolidayContent;


