import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withCookies} from 'react-cookie';
import CartGoToCaseFormTPL from './CartGoToCaseForm.tpl';
import {getSelectedLocationFromCookie} from 'Utils/commonUtils';
import { getGoToCasesForCart, createGoToCaseFromCart } from 'Services/goToCaseServices';

class CartGoToCaseForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetchingCompatibleCases: true,
      compatibleCases: [],
      selectedCase: undefined,
      newCaseName: '',
    };
  }

  componentDidMount() {
    this.startFetchingCompatibleCases()
  }

  async startFetchingCompatibleCases() {
    const { goToCaseItems } = this.props;

    try {
      const response = await getGoToCasesForCart(goToCaseItems);

      console.log(goToCaseItems, response);

      this.setState({
        compatibleCases: response.data,
        isFetchingCompatibleCases: false
      });

    } catch(error) {
      console.log(error);

      this.setState({
        isFetchingCompatibleCases: false
      });

    }
  }

  submitCartItemsToGoToCase = async () => {
    const {
            cookies,
            setGoToCaseSelectorResult,
            handleCloseSaveGoToCase,
            goToCaseItems,
            cartCaseName
          } = this.props;
    const { selectedCase, newCaseName, compatibleCases } = this.state;
    const theSelectedGTC = compatibleCases.filter((compatibleCase) => {
      return parseInt(selectedCase) === compatibleCase.id;
    });

    const {barangay_id} = getSelectedLocationFromCookie(cookies);

    const isNewGoToCase = !selectedCase || (selectedCase && selectedCase == - 1);
    let requestBody =  isNewGoToCase ? {
      name: newCaseName,
      cart_items: [
        ...goToCaseItems
      ]
    } : {
      case_id: parseInt(selectedCase),
      cart_items: [
        ...goToCaseItems
      ]
    };
    requestBody.barangay = barangay_id;

    try {
      await createGoToCaseFromCart(requestBody);
      const successMessage = isNewGoToCase ?
      `You successfully created Go-to Case "${newCaseName}" from your case "${cartCaseName}"` :
      `You successfully added from your case "${cartCaseName}" to your existing Go-to Case "${theSelectedGTC[0]['case_name']}`;
      

      handleCloseSaveGoToCase();
      setGoToCaseSelectorResult('SUCCESS', successMessage);
    } catch(error) {
      const errorMessage = isNewGoToCase ?
      `Something went wrong creating your Go-to Case "${newCaseName}". Please try again.` :
      `Something went wrong adding from your case "${cartCaseName}" to your existing Go-to Case "${theSelectedGTC[0]['case_name']}. Please try again.`;
      console.log(error);

      setGoToCaseSelectorResult('FAIL', errorMessage);
    }
  };

  handleCompatibleCaseSelection = (value) => {
    this.setState({
      selectedCase: value,
      newCaseName: ''
    });
  }

  handleNewCaseName = (e) => {
    this.setState({
      newCaseName: e.target.value
    })
  }


  render() {
    const {
            isFetchingCompatibleCases,
            compatibleCases,
            selectedCase,
            newCaseName
          } = this.state;

    return (<CartGoToCaseFormTPL
              isFetchingCompatibleCases={isFetchingCompatibleCases}
              selectedCase={selectedCase}
              newCaseName={newCaseName}
              compatibleCases={compatibleCases}
              handleCompatibleCaseSelection={this.handleCompatibleCaseSelection}
              handleNewCaseName={this.handleNewCaseName}
              submitCartItemsToGoToCase={this.submitCartItemsToGoToCase}
              />)
  }
};


CartGoToCaseForm.propTypes = {
  setGoToCaseSelectorResult: PropTypes.func,
  goToCaseItems: PropTypes.array,
  handleCloseSaveGoToCase: PropTypes.func,
  cartCaseName: PropTypes.string
};

export default withCookies(CartGoToCaseForm);
