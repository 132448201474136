import React from 'react';
import { hydrate, render } from "react-dom";
import 'antd/dist/antd.css';
import './index.css';
import AppProvider from './AppProvider';
import { CookiesProvider } from 'react-cookie';
import * as serviceWorker from './serviceWorker';


const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(
  <CookiesProvider>
    <AppProvider />
  </CookiesProvider>,
  rootElement);
} else {
  render(
  <CookiesProvider>
    <AppProvider />
  </CookiesProvider>,
  rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
