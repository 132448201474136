import React from 'react';
import './Banner.scss';

const Banner = ({ title }) => {
    return (
        <div className="banner">
            <span className="banner-title">{title}</span>
        </div>
    );
};

export default Banner;
