import React, { Component, PureComponent } from 'react';
import { get, debounce } from 'lodash';
import './Counter.scss';

class Counter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: props.value ? props.value : 0
    };
  }

  increment = () => {
    const { onChange, value } = this.props;
    
    this.setState({
      count: this.state.count++
    });

    if (onChange) {
      onChange(value + 1);
    }
  };

  decrement = () => {
    const { onChange, min = 0, value } = this.props;
    if (this.state.count !== min) {
      this.setState({
        count: this.state.count--
      });

      // console.log(this.state.count);

      if (onChange) {
        onChange(value - 1);
      }
    }
  };

  handleCounterTypeChange = event => {
    const { onChange, min = 0, value } = this.props;
    const inputValue = get(event, 'target.value');
    const filterInputValue = inputValue ? parseInt(`${inputValue}`, 10) : '';

    console.log(inputValue);
    console.log(filterInputValue);

    this.setState(
      {
        count: `${filterInputValue}`
      },
      () => {
        this.onCounterTypeChange(filterInputValue);
      }
    );
  };

  handleCounterTypeChangeBlur = event => {
    const { onChange, min = 0, value } = this.props;
    const inputValue = get(event, 'target.value');
    const validatedInputValue = inputValue < min ? min : parseInt(inputValue, 10);

    console.log(inputValue);

    this.setState({
      count: validatedInputValue
    });
  };

  onCounterTypeChange = debounce(inputValue => {
    const { onChange, min = 0, value } = this.props;

    if (onChange) {
      onChange(inputValue);
    }
  }, 300);

  componentDidUpdate(prevProps) {
    // console.log(nextProps.value)

    if (this.props.value !== prevProps.value) {
      this.setState({
        count: this.props.value
      });
    }
  }

  render() {
    const { min, value } = this.props;
    const { count } = this.state;
    let isDisabled = '';
    console.log('counter',count);
    // console.log(min);
    // console.log(this.state.count);

    if (min) {
      isDisabled = this.state.count === min;
    } else {
      isDisabled = this.state.count === 0;
    }

    // console.log(value)
    return (
      <div className='counter-cont'>
        <button className='counter-btn' disabled={isDisabled}>
          <span onClick={this.decrement} className='-decrement icon-minus-box' />
        </button>
        <input type='number' value={count} onChange={this.handleCounterTypeChange} onBlur={this.handleCounterTypeChangeBlur} min={min} />
        <button className='counter-btn'>
          <span onClick={this.increment} className='counter-btn -increment icon-plus-box' />
        </button>
      </div>
    );
  }
}

export default Counter;
