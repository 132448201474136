import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { isHolidayToday, holiDates } from 'Utils/holidayUtils';
// import {  } from 'Utils/holidayUtils';


class DateBasedDisplay extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isContentLoaded: false,
      isForDisplayToday: false
    }

  }

   componentDidMount() {
    this.startFetchingLocalHoliday();
  }

  startFetchingLocalHoliday() {

    this.setState({
      isForDisplayToday: isHolidayToday(holiDates),
      isContentLoaded: true
    });

  }



  render() {
    const { isContentLoaded, isForDisplayToday } = this.state;
    const { hideInstead } = this.props;
    const shouldShowContent = hideInstead ? !isForDisplayToday : isForDisplayToday;

    // console.log(this.props);

    return (
      isContentLoaded ?

        <>{
          shouldShowContent ?
            this.props.children
          : null
          }
        </>

      : null
    )
  }
};


DateBasedDisplay.propTypes = {};

export default DateBasedDisplay;


