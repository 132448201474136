import React from 'react';
import PropTypes from 'prop-types';
import './CartItems.scss';
import Counter from 'Components/Counter/Counter';
import CartItem from './CartItem/CartItem';

const CartItemsTPL = (props) => {
    const {
      items=[],
      updateReduxCart,
      deleteCartItem
    } = props;

    return (
      <ul className="cart-group-items">
        {items.map((item, index) =>
        <CartItem item={item}
                  updateReduxCart={updateReduxCart}
                  deleteCartItem={deleteCartItem}
                  key={index} />)}
      </ul>
    )
};

export default CartItemsTPL;


CartItemsTPL.propTypes = {
  items: PropTypes.array,
  updateReduxCart: PropTypes.func,
  deleteCartItem: PropTypes.func
};
