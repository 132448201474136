import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import appActions from 'Redux/app/actions';
import AppHeaderTPL from './AppHeader.tpl';

const menuKeys = {
  MY_ACCOUNT: 'MY_ACCOUNT',
  CART: 'CART'
};

class AppHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleCartClick = () => {
    this.props.toggleCartDrawer();
  };

  handleMenuClick = ({ item, key }) => {
    if (key == menuKeys.CART) {
      this.props.toggleCartDrawer();
    } else {
      this.props.toggleCartDrawer(false);
    }
  };

  handleAccountNavigation = () => {
    const { toggleAccountNavigation } = this.props;

    toggleAccountNavigation();
  };

  handleMobileNavigation = () => {
    const { toggleMobileNavigation } = this.props;

    toggleMobileNavigation();
  };
  
  render() {
    return (
      <AppHeaderTPL auth={this.props.auth} menuKeys={menuKeys} handleCartClick={this.handleCartClick} handleMenuClick={this.handleMenuClick} handleAccountNavigation={this.handleAccountNavigation} handleMobileNavigation={this.handleMobileNavigation}/>
    );
  }
}

function mapStateToProps(state) {
  console.log('mapStateToProps', state);
  return {
    // cartDrawerIsVisible: state.app.cartDrawerIsVisible
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleMobileNavigation: () => {
      dispatch(appActions.toggleMobileNavigation());
    },
    toggleCartDrawer: val => {
      dispatch(appActions.toggleCartDrawer(val));
    },
    toggleAccountNavigation: () => {
      dispatch(appActions.toggleAccountNavigation());
    }
  };
}

AppHeader.propTypes = {
  toggleAccountNavigation: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppHeader);
