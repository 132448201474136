import React, { Component } from "react";
import PropTypes from 'prop-types';
import { debounce, get } from 'lodash';
import AppFooterTPL from "./AppFooter.tpl";
import './AppFooter.scss';
import { EMAIL } from "Utils/regexUtils";
import { postEmailToNewsletterList } from "Services/appServices";

class AppFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittingNewsletter: false,
      newsletterEmail: '',
      newsletterSubmitResult: '', // SUCCESS | FAIL
      newsletterResultMessage: ''
    }
  }

  handleEmailAddressInput = (event) => {
    const value  = get(event, 'target.value');

    this.storeNewsletterEmail(value);
  };

  startClearingResultMessage = () => {
    const timeoutRef = window.setTimeout(() => {
      this.setState({
        newsletterSubmitResult: '',
        newsletterResultMessage: ''
      });
      window.clearTimeout(timeoutRef);
    }, 5000)
  }

  registerEmailNewsletter = async (event) => {
    const { newsletterEmail } = this.state;
    event.preventDefault();

    console.log(newsletterEmail);

    try {
      const response = await postEmailToNewsletterList(newsletterEmail);
      const successMessage = get(response, 'data.success') || 'Your email has been added to our mailing list and will receive our latest news.';

      console.log(successMessage);

      this.setState({
        newsletterEmail: '',
        newsletterSubmitResult: 'SUCCESS',
        newsletterResultMessage: successMessage
      });


    } catch(error) {
      const errorMessage = get(error, 'response.data.errors.email[0]') || 'Something went wrong adding your email address to the newsletter. Please try again.';

      this.setState({
        newsletterEmail: '',
        newsletterSubmitResult: 'FAIL',
        newsletterResultMessage: errorMessage
      });


      console.log(errorMessage);
    }

    this.startClearingResultMessage();
  };

  storeNewsletterEmail = (value) => {
    console.log(value);

    this.setState({
      newsletterEmail: value
    });
  };

  render() {
    const {
            isSubmittingNewsletter,
            newsletterEmail,
            newsletterResultMessage,
            newsletterSubmitResult
          } = this.state;

    return (<AppFooterTPL
              handleEmailAddressInput={this.handleEmailAddressInput}
              registerEmailNewsletter={this.registerEmailNewsletter}
              isSubmittingNewsletter={isSubmittingNewsletter}
              newsletterEmail={newsletterEmail}
              newsletterSubmitResult={newsletterSubmitResult}
              newsletterResultMessage={newsletterResultMessage}
              />)
  }
}

export default AppFooter;

AppFooter.propTypes = {};
