import { getToken, setToken, removeToken } from "Services/tokenService";
import axios from "axios";
import { useState } from "react";
import { useCallback } from 'react';

const useHttp = () => {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const sendRequest = useCallback(async (requestConfig, callback) => {
        
        const token = getToken();
        
        try {
            const response = await fetch(
                requestConfig.url,
                {
                    method: requestConfig.method ? requestConfig.method : "GET",
                    headers: requestConfig.headers ? requestConfig.headers : {Authorization: "Bearer " + token},
                    body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
                }
            );

            if (!response.ok) {
                throw response;
            }
            
            const data = await response.json();
            callback(data);

        } catch (err) {
            if (err.text) {
                err.text().then( errorMessage => {
                  const error = JSON.parse(errorMessage);
                  setError(error.error);
                });
            } else {
                setError('Request failed');
            }
        }
        setLoading(false);
    }, []);

    return {
        sendRequest: sendRequest,
        loading: loading,
        error: error,
        setError: setError
    };
};

export default useHttp;