import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './MobileNavigation.scss';

const MobileNavigationTPL = props => {
  const { mobileNavigationIsVisible, toggleMobileNavigation } = props;
  return (
    <>
      <div className={`mobile-navigation ${mobileNavigationIsVisible ? '-active' : ''}`}>
        <div className="menu-toggle-container">
          <button className="menu-close-toggle" onClick={toggleMobileNavigation}>
            <span className="icon-plus" />
          </button>
        </div>
        <nav>
          <ul className="mobile-nav-menu">
            <li>
              <Link to="/" className="mobile-nav-menu-link" onClick={toggleMobileNavigation}>
                Home
              </Link>
            </li>
            <li className="mobile-nav-menu-item">
              <Link to="/buy-beer" className="mobile-nav-menu-link" onClick={toggleMobileNavigation}>
                Buy Beers
              </Link>
            </li>
            <li className="mobile-nav-menu-item">
              <Link to="/how-to-order" className="mobile-nav-menu-link">
                How It Works
              </Link>
            </li>
            <li className="mobile-nav-menu-item">
              <Link to="/whats-new" className="mobile-nav-menu-link">
                What's New
              </Link>
            </li>
            <li className="mobile-nav-menu-item">
              <Link to="/faqs" className="mobile-nav-menu-link">
                FAQs
              </Link>
            </li>
            <li className="mobile-nav-menu-item">
              <Link to="/contact-us" className="mobile-nav-menu-link">
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className={`overlay ${mobileNavigationIsVisible ? '-active' : ''}`} onClick={toggleMobileNavigation} />
    </>
  );
};

export default MobileNavigationTPL;

MobileNavigationTPL.propTypes = {};
