import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { connect } from 'react-redux';
import CartIndicatorTPL from './CartIndicator.tpl';
import { getCartTotalItems } from 'Utils/cartUtils';

class CartIndicator extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { cart } = this.props;
    const shoppingCart = !isNil(cart) && 'shopping_cart' in cart ? cart['shopping_cart'] : [];

    const cartQty = getCartTotalItems(shoppingCart);

    return (<CartIndicatorTPL  cartItemsQty={cartQty} />)
  }
};


CartIndicator.propTypes = {};

const mapStateToProps = (state) => {
  return {
    ...state.cart
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartIndicator);
