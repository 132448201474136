import React, {Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CartSummaryTPL from './CartSummary.tpl';
import { isCartReadyForCheckout } from 'Services/cartServices';

class CartSummary extends Component {

  constructor(props) {
    super(props);

    // this.state = {
    //   didAgreeToTC: false
    // };
  }

  // handleToggleTC = () => {
    
  //   this.setState({
  //     didAgreeToTC: !this.state.didAgreeToTC
  //   });
  // }

  render() {
    const {
            cart,
            cartSummary,
            handleSwitchToCart,
            handleSubmitOrder,
            isInCheckout,
            canSubmitOrder
          } = this.props;
    return (<CartSummaryTPL
              handleSwitchToCart={handleSwitchToCart}
              handleSubmitOrder={handleSubmitOrder}
              cartSummary={cartSummary}
              isInCheckout={isInCheckout}
              canSubmitOrder={canSubmitOrder}
              cart={cart}
              />)
  }
};


CartSummary.propTypes = {
  cartSummary: PropTypes.object,
  handleSwitchToCart: PropTypes.func,
  handleSubmitOrder: PropTypes.func,
  isInCheckout: PropTypes.bool
};



export default CartSummary;
