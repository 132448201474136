import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Select } from "antd";

import "./DeliveryGateModal.scss";

const { Option } = Select;

const DeliveryGateModal = React.memo(children => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  return (
    <Fragment>
      <Modal visible={visible} onCancel={handleClose} footer={null}>
        <div>Please select if you would like delivery or pickup for this order</div>
        <div className="service-option">
          <Button>Delivery</Button>
          <Button>Pick-up</Button>
        </div>

        <section className="service-option">
          <div>
            <div>Select your current city:</div>
            <Select
              defaultValue="Select a city"
              style={{ width: 200 }}
              onChange={handleChange}
            >
              <Option value="city-1">City 1</Option>
              <Option value="city-2">City 2</Option>
              <Option value="city-3">City 3</Option>
              <Option value="city-4">City 4</Option>
            </Select>
          </div>
        </section>

        <div className="continue-button">
          <Button>Continue</Button>
        </div>
      </Modal>
    </Fragment>
  );
});

export default DeliveryGateModal;
