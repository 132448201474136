import { Icon } from 'antd';
import { get } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import appActions from 'Redux/app/actions';
import { withCookies } from 'react-cookie';
import CartActions from 'Redux/cart/actions';
import { push } from 'connected-react-router';
import { addToCart } from 'Services/cartServices';
import React, { Component, Fragment } from 'react';
import SingleProductTPL from './SingleProduct.tpl';
import { getSingleProduct } from 'Services/productServices';
import { getSelectedLocationFromCookie } from 'Utils/commonUtils';
import withLocationNotice from 'Components/Modal/withLocationNotice/withLocationNotice';

import './SingleProduct.scss';

class SingleProduct extends Component {
  state = {
    error: '',
    product: null,
    successModalVisibility: false,
    addingToCart: false,
    recoProducts: [],
    addToCartResult: '',
    addToCartResultMessage: '',
    disabledProducts: []
  };

  componentDidMount = async () => {
    let { slug } = this.props.match.params;

    this.fetchThisProduct(slug);

    window.scrollTo(0, 0);
  };

  componentDidUpdate(prevProps) {
    const prevSlug = get(prevProps, 'match.params.slug');
    const newSlug = get(this.props, 'match.params.slug');

    if (prevSlug !== newSlug) {
      this.fetchThisProduct(newSlug);
    }
  }

  async fetchThisProduct(productSlug) {
    const { cookies } = this.props;
    const {barangay_id: barangay} = getSelectedLocationFromCookie(cookies);
    
    try {

      let product = await getSingleProduct({productSlug, barangay});

      this.setState({
        product: product.product_single,
        recoProducts: product.you_might_also_like
      });
    } catch (e) {
      this.props.returnToBrowse();
    }
  }

  handleOK = () => {
    this.setState({
      successModalVisibility: false,
      modalMessage: ''
    });
  };

  handleAddToCart = quantity => {
    this.setState(
      {
        addingToCart: true
      },
      () => this.addToCartAction(quantity)
    );
  };

  addToCartAction = async quantity => {
    const { product } = this.state;
    
    const {cookies} = this.props;
    const {barangay_id, street_id} = getSelectedLocationFromCookie(cookies);
    let query = {
      barangay: barangay_id
    };
    if (street_id) {
      query.street_id = street_id;
    }
    const data = {
      ...query,
      product_id: product.id,
      quantity: quantity,
    };

    try {
      await addToCart(data);

      this.setState({
        addingToCart: false
      });

      await this.props.fetchCart();
      this.props.toggleCartDrawer();
    } catch (error) {
      const errorCode = get(error, 'response.status');
      let errorItems = [];
      let errorMessage = '';

      if (errorCode === 422) {
        errorMessage = get(error, 'response.data.title');
        errorItems = get(error, 'response.data.products');
      } else {
        errorMessage = 'Something went wrong adding this product to the cart.';
      }

      this.setState({
        addToCartResult: 'FAIL',
        addToCartResultMessage: errorMessage,
        disabledProducts: Array.isArray(errorItems) ? errorItems : [],
        addingToCart: false
      });
    }
  };

  handleModalClose = () => {
    this.setState({
      addToCartResult: '',
      addToCartResultMessage: '',
      disabledProducts: []
    });
  };

  render() {
    const { error, product, addingToCart, recoProducts, addToCartResult, addToCartResultMessage, disabledProducts } = this.state;
    const { auth, cookies, showModal } = this.props;

    if (error) {
      return <div className='product-loader'> {error} </div>;
    }

    if (!product) {
      return (
        <div className='product-loader'>
          {' '}
          <Icon type={'loading'} />
        </div>
      );
    }
    return (
      <SingleProductTPL
        addingToCart={addingToCart}
        product={this.state.product}
        addToCart={this.handleAddToCart}
        recoProducts={recoProducts}
        auth={auth}
        addToCartResult={addToCartResult}
        addToCartResultMessage={addToCartResultMessage}
        disabledProducts={disabledProducts}
        handleModalClose={this.handleModalClose}
        cookies={cookies}
        showModal={showModal}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    returnToBrowse: () => {
      dispatch(push('/buy-beer'));
    },
    fetchCart: () => {
      dispatch(CartActions.fetchCart());
    },
    toggleCartDrawer: val => {
      dispatch(appActions.toggleCartDrawer(val));
    }
  };
};

export default compose(withLocationNotice, withCookies, connect(mapStateToProps, mapDispatchToProps))(SingleProduct);

/**
Cans

- Solo (Disabled) - OK
- Box of 24 (Selected by default) - OK

Bottle (320ml/330ml)

- Bottle (Selected by default)
- Case of 24

If Bottle (1000ml)

- Bottle (Selected by default)
- Case of 6


Keg (30l/50l)

- No option

 */
