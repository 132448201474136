import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Debounce } from 'react-throttle';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import WindowResizeListener from 'react-window-size-listener';
import { Layout, Drawer, Icon } from 'antd';
import AppRoute from './AppRoute';
import actions from 'Redux/app/actions';
import GradientBtn from 'Components/GradientBtn/GradientBtn';
import AppHeader from 'Components/AppHeader/AppHeader';
import './App.scss';
import AppFooter from 'Components/AppFooter/AppFooter';
import AccountNavigation from 'Components/AccountNavigation/AccountNavigation';
import MobileNavigation from 'Components/MobileNavigation/MobileNavigation';
import AuthenticationActions from 'Redux/auth/actions';
import Cart from 'Components/Cart/Cart';
import PrivacyPopup from 'Components/PrivacyPopup/PrivacyPopup';
import CategoryGateModal from 'scenes/Home/CategoryGateModal';
import PickupGateModal from 'scenes/Home/PickupGateModal';
import { setPolicy, getPolicy } from './../services/authServices';
import { getUserDetails } from 'Services/accountServices';
import Loading from 'Components/Loading/Loading';
import Router from 'Components/Router/Router';
import { removeCookies, isCookieAlreadyAccepted } from 'Utils/commonUtils';
import {actionTypes} from 'Redux/gates/reducers';


const { 
  CATEGORY_GATE_ID_COOKIE_NAME,
  FULFILLMENTID_COOKIE_NAME,
  LOCATION_CITY_ID_COOKIE_NAME,
  LOCATION_BARANGAY_ID_COOKIE_NAME,
  CATEGORY_GATE_NAME_COOKIE_NAME
 } = process.env;


class App extends Component {
  state = {
    policyVisible: false,
    policyStatus: false,
    showCategoryGate: false,
    showLocationGate: false,

  };

  getContainer(id) {
    // debugger;
    return () => {
      if (!id || typeof document === 'undefined') return null;
      return document.getElementById(id);
    };
  }

  componentDidMount() {
    const { pathname, cookies } = this.props;
    if (pathname !== '/privacy-policy' && pathname !== '/terms-and-conditions') {
      this.fetchPolicyInformation(true);
    }

  }

  componentDidUpdate(nextProps) {
    const { pathname } = this.props;
    const { policyStatus } = this.state;

    if (policyStatus === false) {
      if (nextProps.pathname !== pathname && pathname !== '/privacy-policy' && pathname !== '/terms-and-conditions') {
        this.showPolicy();
      }
    }
  }

  async fetchPolicyInformation(showPolicy) {
    try {
      const response = await getPolicy();

      console.log(response, 'POLICY');

      this.setState(
        {
          policyStatus: response.policy_agreement_accepted
        },
        () => {
          showPolicy && this.showPolicy();
        }
      );
    } catch (error) {
      showPolicy && this.showPolicy();
    }
  }

  showPolicy = () => {
    const { policyStatus } = this.state;
    const { cookies, isLoggedIn } = this.props;
    let privacyVerification = cookies.get('privacyVerification');

    if (isLoggedIn) {
      console.log('logged in');
      if (policyStatus === true || privacyVerification === '1') {
        this.setState({
          policyVisible: false
        });
      } else {
        this.setState({
          policyVisible: true
        });
      }
    } else if (!isLoggedIn) {
      console.log('not logged in');

      if (privacyVerification === '1') {
        this.setState({
          policyVisible: false
        });
      } else {
        this.setState({
          policyVisible: true
        });
      }
    }
  };

  goToCart = () => {
    this.props.toggleCartDrawer();
    this.props.goTo('/cart');
  };

  handleOk = e => {
    const { cookies } = this.props;
    setPolicy(1);
    cookies.set('privacyVerification', '1', { path: '/' });

    this.setState({
      policyVisible: false,
      policyStatus: true,
      showCategoryGate: true,
    });

    console.log('here');

  };

  handleCancel = e => {
    this.setState({
      policyVisible: false
    });
    this.props.goTo('/terms-and-conditions');
  };

  handleLink = e => {
    console.log('boom');
    this.setState({
      policyVisible: false
    });
  };


  categoryGateOnClickHandler = ({id, packaging}) => {
    // this.setState({
    //   showLocationGate: true,
    //   showCategoryGate: false,
    // });

    console.log('setting_category', id, packaging);

    this.props.showLocationGate();
    const { cookies } = this.props;
    removeCookies(cookies, [FULFILLMENTID_COOKIE_NAME,
      LOCATION_CITY_ID_COOKIE_NAME,
      LOCATION_BARANGAY_ID_COOKIE_NAME]);
    cookies.set(CATEGORY_GATE_ID_COOKIE_NAME, id);
    cookies.set(CATEGORY_GATE_NAME_COOKIE_NAME, packaging);
    
  };

  handleLocationGateContinue = (locationName = '') => {
    // const {cookies} = this.props;
    // this.setState({
    //   showLocationGate: false,
    //   showCategoryGate: false,
    // });
    
    this.props.hideGates();
    this.props.goTo('buy-beer');
  };

  render() {
    
    const { Content } = Layout;
    const { cookies, isLoggedIn, pathname, gate } = this.props;
    

    const { cookieAccepted } = this.state;
    let ageVerify = cookies.get('ageVerification');
    let privacyVerification = cookies.get('privacyVerification');
    
    const { cartDrawerIsVisible, toggleCartDrawer, bodyLoaderIsVisible, bodyLoaderMessage } = this.props;
    let categoryGateId = cookies.get(CATEGORY_GATE_ID_COOKIE_NAME);
    
    let showCategoryGate = gate.showCategoryGate;
    let showLocationGate = gate.showLocationGate;
    
    if (cartDrawerIsVisible) {
      document.body.classList.add('-hidden');
    } else {
      document.body.classList.remove('-hidden');
    }

    let classes = 'app-root-container';
    if (pathname === '/maintenance') classes += ' -simple';

    return (
      <div className={classes}>
        <Debounce time="1000" handler="onResize">
          <WindowResizeListener onResize={windowSize => this.props.updateHeightAndWidth(windowSize.windowHeight, windowSize.windowWidth)} />
        </Debounce>

        {/* {ageVerify && <PrivacyPopup cookies={this.props.cookies} visible={this.state.policyVisible} handleCancel={this.handleCancel} handleOk={this.handleOk} handleLink={this.handleLink} />} */}
        {showCategoryGate && <CategoryGateModal isLoggedIn={isLoggedIn} isVisible={showCategoryGate} onClickHandler={this.categoryGateOnClickHandler}/>}
        {showLocationGate && <PickupGateModal isLoggedIn={isLoggedIn} isVisible={showLocationGate} handleLocationGateContinue={this.handleLocationGateContinue} cookies={this.props.cookies}/>}
        {ageVerify && <AppHeader />}
        
        <Layout className="drawer-layout-container">
          <Drawer
            title="Cart"
            placement="right"
            closable={true}
            onClose={toggleCartDrawer}
            className={'drawer-container'}
            visible={cartDrawerIsVisible}
            getContainer=".drawer-layout-container"
            width={430}>
            <Cart forceHideSummary={true} showTotal={true} onBar={cartDrawerIsVisible} isAttachedInSidebar />
            <GradientBtn className="review-trigger" onClick={this.goToCart} block>
              View Shopping Cart
            </GradientBtn>

            <div className="tax-info-container">
              {/* <p className="tax-info">*Tax &amp; deposits will be calculated at checkout</p> */}
              <p className="tax-info">*Deposits will be calculated at checkout</p>
            </div>
          </Drawer>
          <Content id={'main-content'}>
            <Router cookies={cookies} ageVerify={ageVerify} />

            {bodyLoaderIsVisible && (
              <div className="loading-overlay">
                <Loading message={bodyLoaderMessage} />
              </div>
            )}
          </Content>
          <AccountNavigation />
          <MobileNavigation />
        </Layout>
        {ageVerify && <AppFooter />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.cart,
    gate: state.gate,
    user: state.auth.user,
    bodyLoaderIsVisible: state.app.bodyLoaderIsVisible,
    bodyLoaderMessage: state.app.bodyLoaderMessage,
    cartDrawerIsVisible: state.app.cartDrawerIsVisible,
    isLoggedIn: state.auth.isLoggedIn,
    pathname: state.router.location.pathname
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleCartDrawer: () => {
      dispatch(actions.toggleCartDrawer());
    },
    updateHeightAndWidth: (height, width) => {
      dispatch(actions.updateHeightAndWidth(height, width));
    },
    goTo: url => {
      dispatch(push(url));
    },
    iniUser: () => {
      console.log('Triggerd Init');
      dispatch(AuthenticationActions.initUser());
    },

    showCategoryGate: () => {
      dispatch({type: actionTypes.SHOW_CATEGORY_GATE})
    },

    showLocationGate: () => {
      dispatch({type: actionTypes.SHOW_LOCATION_GATE})
    },

    hideGates: () => {
      dispatch({type: actionTypes.HIDE_GATES})
    },

  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(App));
