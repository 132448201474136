import moment from 'moment';


const { 
    CATEGORY_GATE_ID_COOKIE_NAME,
    FULFILLMENTID_COOKIE_NAME,
    LOCATION_CITY_ID_COOKIE_NAME,
    LOCATION_BARANGAY_ID_COOKIE_NAME,
    LOCATION_STREET_ID_COOKIE_NAME,
    CATEGORY_GATE_NAME_COOKIE_NAME,
    LOCATION_BARANGAY_NAME_COOKIE_NAME,
    LOCATION_CITY_NAME_COOKIE_NAME,
    COOKIE_CONSENT_NAME
   } = process.env;

export const assertNowIsBetween = (startDate, endDate) => {
    const compareDate = moment();
    const st = moment(startDate, "DD/MM/YYYY");  
    const ed = moment(endDate, "DD/MM/YYYY");  
    const isBetween = compareDate.isBetween(st, ed);
    return isBetween;
};
/**
 * Remove cookies based on provided keys
 * @param {*} cookies 
 * @param array keys 
 */
export const removeCookies = (cookies, keys) => {
    keys.map(key => {
        cookies.remove(key);
        return [];
    });
};


/**
 * User already done in location gate
 * 
 * @return bool
 */
export const isDoneInLocationGate = (cookies) => {
    let fulfillmentId = cookies.get(FULFILLMENTID_COOKIE_NAME);
    let locationCityId = cookies.get(LOCATION_CITY_ID_COOKIE_NAME);
    let locationBarangayId = cookies.get(LOCATION_BARANGAY_ID_COOKIE_NAME);

    return typeof fulfillmentId !== 'undefined' && typeof locationCityId !== 'undefined' && typeof locationBarangayId !== 'undefined';
};

export const getSelectedLocationFromCookie = (cookies) => {
    
    const city_id = cookies.get(LOCATION_CITY_ID_COOKIE_NAME);
    const barangay_id = cookies.get(LOCATION_BARANGAY_ID_COOKIE_NAME);
    const street_id = cookies.get(LOCATION_STREET_ID_COOKIE_NAME);
    const barangay_name = cookies.get(LOCATION_BARANGAY_NAME_COOKIE_NAME);
    const city_name = cookies.get(LOCATION_CITY_NAME_COOKIE_NAME);
    const fulfillmentId = cookies.get(FULFILLMENTID_COOKIE_NAME);
    return {
        city_id,
        barangay_id,
        street_id,
        barangay_name,
        city_name,
        fulfillmentId
    };
};

export const isCookieAlreadyAccepted = cookies => {
    const isCookieAccepted = cookies.get(COOKIE_CONSENT_NAME) || false;
    return {
        isCookieAccepted
    };
};

export const getSelectedCategory = cookies => {
    const selectedCategoryGate = cookies.get(CATEGORY_GATE_NAME_COOKIE_NAME);
    return {
        selectedCategoryGateName: selectedCategoryGate
    };
};



export const toArrayLowerCase = val => {
    return val.split(',').map(t => t.toLowerCase()) || [];
};