import React, { Component } from "react";
import { Radio } from "antd";
import "./ProductToggle.scss";

const ProductToggle = ({ options, value, onChange }) => {
  console.log(options.map(o => console.log(o.name)));
  return (
    <Radio.Group className="product-toggle" onChange={onChange} value={value} buttonStyle="solid">
      {" "}
      {options.map(option => (
        <Radio.Button disabled={option.disabled} key={option.name} value={option.name}>
          {option.name}{" "}
        </Radio.Button>
      ))}{" "}
    </Radio.Group>
  );
};

export default ProductToggle;
