import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

const ErrorMessageTPL = (props) => {
  const { error } = props;
  let errorMessage = error.message;
  const fallbackErrorMessage = error.fallbackMessage;
  let errorMessageItems = [];

  try {

    if(error.errorItems &&
          Array.isArray(error.errorItems) &&
          error.errorItems.length > 0) {
      errorMessageItems = error.errorItems.map((errorItem) => {
        return Array.isArray(errorItem)
                && errorItem.length > 0 ?
                  errorItem[0] : errorItem;
      });
    }

  } catch(error) {
    errorMessage = fallbackErrorMessage;
    errorMessageItems = [];
  }


  return (<>
    {
      errorMessage &&
      <p>{`${errorMessage}`}</p>
    }
    {
      (Array.isArray(errorMessageItems) && errorMessageItems.length > 0) &&
        (
          <ul>
            {errorMessageItems.map((errorMessageItem, index) => {
              return (
                <li key={index}>{`${errorMessageItem}`}</li>
              )
            })}
          </ul>
        )
    }
  </>)
};

export default ErrorMessageTPL;


ErrorMessageTPL.propTypes = {
  error: PropTypes.object
};
