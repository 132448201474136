import React, { Fragment } from "react";
import bottledBeer from "Assets/images/bottled-beer.png";
import draftBeer from "Assets/images/draft-beer.png";
import {Button} from "antd";
import './CategoryModalItem.scss'
import GradientBtn from "Components/GradientBtn/GradientBtn";

const CategoryModalItem = (props) => {

    const { product } = props;
    console.log('product', product);
    const onClickHandler = (evt) => {
        evt.preventDefault();
        console.log('product',props.product);
        props.onClickHandler({
          id: props.product.id,
          packaging: props.product.packaging
        });
    };

    const containerWrapper = product.packaging === "DRAFT BEER IN KEG" || product.packaging === "Keg" ? "keg-wrapper" : "";

    // const imageContainerClass = props.product['product_image'] ? 'product-img-container' : 'product-img-container -no-image';

    return (
        <Fragment>
          <div className="container">
            <div className={`product-img-container ${ containerWrapper }`}>
              {/* <img className="element" src={bottledBeer} alt="beer-package" /> */}
              <img className='product-image element' alt='sml' src={product.image} />
            </div>
            <p className="element mobile-text">{product.custom_description}</p>
            <GradientBtn className="element product-type" type="button" onClick={onClickHandler}><span  style={{padding: 7, width: '200px'}}>{product.packaging}</span></GradientBtn>
          </div>
        </Fragment>
      );
};

export default CategoryModalItem;