import React from 'react';
import PropTypes from 'prop-types';
import './HeroNotice.scss';

const HeroNotice = (props) => {
    return (<div className="hero-notice">
    {props.children}
  </div>)
};

export default HeroNotice;


HeroNotice.propTypes = {};
