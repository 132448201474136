import smbService from './sessionInterceptor';

export const getProvinces = () => {
  return smbService.get('/provinces');
};

export const getCities = provinceId => {
  return smbService.get('/cities', {
    params: {
      filterByProvince: provinceId
    }
  });
};

export const getBarangays = cityId => {
  return smbService.get('/barangay', {
    params: {
      filterByCity: cityId
    }
  });
};
