import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension'

export const history = createBrowserHistory()

export default function configureStore(preloadedState) {
    // const middlewares = [thunk, routerMiddleware(history)]
    const middlewareEnhancers =  applyMiddleware(thunk, routerMiddleware(history))
    const enhancers = [middlewareEnhancers]
    const composedEnhancers = composeWithDevTools(...enhancers)

    const store = createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
        composedEnhancers
    )

    return store
}

export const store = configureStore()