import React, { Component } from 'react';
import './PrivacyPopup.scss';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Modal } from 'antd';
import GradientBtn from 'Components/GradientBtn/GradientBtn';
import { Link } from 'react-router-dom';

class PrivacyPopup extends Component {
  render() {
    return (
      <Modal className="privacy-modal" visible={this.props.visible} footer={null} closable={false}>
        <div className="privacy-title">PRIVACY / COOKIE POLICY</div>
        <p>
          You must be at least 18 years old to use SMBdelivers.com and also agree to our{' '}
          <Link to="/terms-and-conditions" className="privacy-links" onClick={this.props.handleLink}>
            Terms and Conditions
          </Link>
          ,
          <Link to="/privacy-policy"  className="privacy-links" onClick={this.props.handleLink}>
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link to="/privacy-policy"  className="privacy-links" onClick={this.props.handleLink}>
            Cookie use
          </Link>
          . Our use of cookies and other similar technologies provide site functionality, analyze traffic and usage, and deliver content tailored to your interests. Please click
          “Agree and Proceed” to accept all policies and continue to use this website.
        </p>
        <GradientBtn className="agree" onClick={this.props.handleOk} block>
          AGREE AND PROCEED
        </GradientBtn>
        <GradientBtn className="learn-more" onClick={this.props.handleCancel} block>
          Learn More
        </GradientBtn>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    goTo: url => {
      dispatch(push(url));
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(PrivacyPopup);
