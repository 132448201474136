import React from "react";
import "./Maintenance.scss";
import { Col } from "antd";
// import MaintenanceMessage from 'Assets/images/hero-message.png';
import heroHandle from "Assets/images/hero-handle.png";

const MaintenanceTPL = () => {
  return (
    <section className="maintenance">
      <img src={heroHandle} alt="" className="hero-handle" />
      <div className="maintenance-bg-container">
        <div className="maintenance-bg-image-container">
          <Col className="maintenance-content-container" md={8} xs={20}>
            <div className="maintenance-title">
              <div className="maintenance-title-row">
                <h2 className="h2 tradesmith">We're</h2>
                <h2 className="h2 tradesmith">Brewing</h2>
              </div>
              <div className="maintenance-title-row">
                <h2 className="h2 tradesmith">Something</h2>
              </div>
              <div className="maintenance-title-row">
                <h2 className="h2 tradesmith">For</h2>
                <h2 className="h2 tradesmith">You</h2>
              </div>
            </div>

            <h4 className="h4 maintenance-sub-title tradesmith">
              <span className="block">We'll be back soon</span>
              <span className="block"> with a brand new website.</span>
            </h4>
          </Col>
        </div>
      </div>
    </section>
  );
};

export default MaintenanceTPL;
