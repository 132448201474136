import { format } from 'date-fns';
import moment from 'moment';

export const holiDates = ['2019-12-25', '2020-01-01', '2020-04-09', '2020-04-10'];

export const holidaySeason = [
  {
    min: '2019-12-01 00:00',
    max: '2020-01-01 23:59',
    format: 'YYYY-MM-DD HH:mm',
  },
  {
    min: '2020-03-17 00:00',
    max: '2020-03-18 23:59',
    format: 'YYYY-MM-DD HH:mm',
  },
  {
    min: '2020-04-09 00:00',
    max: '2020-04-10 23:59',
    format: 'YYYY-MM-DD HH:mm',
  },
  {
    min: '2020-04-09 00:00',
    max: '2020-04-10 23:59',
    format: 'YYYY-MM-DD HH:mm',
  },
  {
    min: '2021-03-29 00:00',
    max: '2021-04-04 23:59',
    format: 'YYYY-MM-DD HH:mm',
  },
];

export const muziklabanFestival = [
  {
    min: '2020-11-15 00:00',
    max: '2020-12-07 23:59',
    format: 'YYYY-MM-DD HH:mm',
  },
];

export const timeBoundHolidays = [
  {
    min: '2020-04-04 8:00',
    max: '2020-04-08 16:00',
    format: 'YYYY-MM-DD HH:mm',
  },
];

export const isHolidayToday = (dates = []) => {
  const matchingHolidays = dates.filter((date) => {
    const currentDateTime = moment().format('YYYY-MM-DD');

    return moment(currentDateTime, 'YYYY-MM-DD').isSame(date);
  });

  return matchingHolidays.length > 0;
};

export const isWithinHolidaySeason = (dates = []) => {
  const matchingHolidays = dates.filter((dateRange) => {
    const currentDateTime = moment().format('YYYY-MM-DD HH:mm');

    // console.log(moment().format('YYYY-MM-DD HH:mm'))
    // console.log(moment(currentDateTime, 'YYYY-MM-DD HH:mm').isSameOrAfter(dateRange.min));
    // console.log(moment(currentDateTime, 'YYYY-MM-DD HH:mm').isSameOrBefore(dateRange.max));

    return moment(currentDateTime, 'YYYY-MM-DD HH:mm').isSameOrAfter(dateRange.min, 'day') && moment(currentDateTime).isSameOrBefore(dateRange.max, 'day');
  });

  return matchingHolidays.length > 0;
};

export const isTimeBoundHolidayToday = (timeBoundDates = []) => {
  const matchingHolidays = timeBoundDates.filter((dateRange) => {
    const currentDateTime = moment().format('YYYY-MM-DD HH:mm');

    // console.log(moment().format('YYYY-MM-DD HH:mm'))
    console.log(moment(currentDateTime, 'YYYY-MM-DD HH:mm').isSameOrAfter(dateRange.min, 'minute'));
    console.log(moment(currentDateTime).isSameOrBefore(dateRange.max, 'minute'));

    // return moment(currentDateTime, 'YYYY-MM-DD HH:mm').isSameOrAfter(dateRange.min, 'minute') && moment(currentDateTime).isSameOrBefore(dateRange.max, 'minute');
    return moment(currentDateTime, 'YYYY-MM-DD HH:mm').isBetween(dateRange.min, dateRange.max, null, '[)');
  });

  return matchingHolidays.length > 0;
};
