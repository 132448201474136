import React, { Component } from 'react';
import { compose } from 'redux';
import './withLocationNotice.scss';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { Modal, Select } from 'antd';
import GradientBtn from 'Components/GradientBtn/GradientBtn';
import { Link } from 'react-router-dom';
import withSelectOptions from 'Components/HOC/withSelectOptions';
import { get, isNil } from 'lodash';
const { Option } = Select;

const withLocationNotice = WrappedComponent => {
  const EnhancedComponent = class extends Component {
    static propTypes = {
      cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
      super(props);

      const { cookies } = props;
      this.state = {
        visible: true,
        province: undefined,
        city: undefined,
        barangay: undefined,
        cityName: ''
      };
    }

    getExistingLocation = () => {
      const { cookies } = this.props;
      const locationCookie = cookies.get('location');

      return locationCookie;
    };

    showModal = () => {
      const { getCitiesByProvince, getBarangaysByCity } = this.props;
      const location = this.getExistingLocation();
      const province = !isNil(get(location, 'province')) ? get(location, 'province') : 51;
      const city = get(location, 'city');
      const barangay = get(location, 'barangay');
      getCitiesByProvince(province);
      getBarangaysByCity(city);

      this.setState({
        province: province,
        city: city,
        barangay: barangay,
        visible: true
      });
    };

    handleOk = e => {
      const { cookies, cities, barangays } = this.props;
      const { province, city, barangay} = this.state;
      cookies.set('locationNotice', '1', { path: '/', maxAge: '2629746 ' });
      const cityName = cities.reduce((prev, next) => {
        // console.log(city);
        // console.log(next.id);

        return next.id == city ? next['city_name'] : prev;
      }, '');
      const barangayName = barangays.reduce((prev, next) => {
        return next.id == barangay ? next['barangay_name'] : prev;
      }, '');
      let location = {
        province: province,
        city: city,
        barangay: barangay
      };

      // console.log(this.state);
      // console.log(cities);
      // console.log(barangays);
      // console.log(cityName, barangayName);

      let locationString = JSON.stringify(location);

      cookies.set('location', locationString, {
        path: '/',
        // maxAge: '2629746 '
      });

      cookies.set('cityName', cityName, {
        path: '/',
        // maxAge: '2629746 '
      });

      cookies.set('barangayName', barangayName, {
        path: '/',
        // maxAge: '2629746 '
      });

      this.setState({
        cityName,
        barangayName,
        visible: false
      });

      // window.location.reload();
    };

    handleCancel = e => {
      // console.log(e);
      this.setState({
        visible: false
      });
    };

    handleCancelWithoutForm = e => {
      // console.log(e);
      const { cookies } = this.props;

      cookies.set('locationNotice', '1', { path: '/', maxAge: '2629746 ' });
      this.setState({
        visible: false
      });

    };

    componentDidMount() {
      const { cookies } = this.props;
      let location = cookies.get('locationNotice');
      const { getCitiesByProvince, getBarangaysByCity } = this.props;
      const locationName = this.getExistingLocation();
      const province = !isNil(get(locationName, 'province')) ? get(location, 'province') : 51;
      const city = !isNil(get(locationName, 'city')) ? get(location, 'province') : '';

      // console.log('log: componentDidMount -> province', province);
      console.log(locationName);
      if(province) {
        getCitiesByProvince(province);
      }

      if(city) {
        getBarangaysByCity(city);
      }

      this.setState({
        province: province
      });

      if (location === '1') {
        this.setState({
          visible: false
        });
      }
    }

    render() {
      const { provinces, cities, barangays, getCitiesByProvince, getBarangaysByCity } = this.props;
      const { province, city, barangay } = this.state;
      // console.log('log: city', city);
      // console.log('log: barangay', barangay);

      // console.log(!city, !barangay);
      // console.log(!city && !barangay);

      return (
        <>
          <Modal className='location-modal' visible={false} footer={null} closable={false}>
            <span className='icon-location' />
            <div className='location-title'>YOUR LOCATION</div>
            <p className='location-paragraph text'>
              Please note that we are only able to deliver certain products in <Link className="location-link" to='/delivery-areas'>certain areas</Link>
            </p>
            {/*
            <p className='location-paragraph'>
              Please note that we currently cater to the areas only available in our <Link to='/delivery-areas'>list</Link>
            </p>
            <div className='form-container'>
              <Select
                showSearch
                placeholder='Select a province'
                optionFilterProp='province'
                className='location-select'
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                name='province'
                defaultValue='51'
                value={province}
                disabled={Array.isArray(provinces) && provinces.length === 0}
                onChange={value => {
                  getCitiesByProvince(value);
                  this.setState({ province: value, city: undefined, barangay: undefined });
                }}
              >
                {provinces &&
                  provinces.map((province, index) => {
                    return (
                      <Option value={province.id} key={`${province.id}${index}`}>
                        {province.province_name}
                      </Option>
                    );
                  })}
              </Select>
              <Select
                showSearch
                placeholder='Select a City'
                optionFilterProp='city'
                className='location-select'
                name='city'
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                disabled={!province}
                value={city}
                onChange={(value, e) => {
                  getBarangaysByCity(value);
                  this.setState({
                    city: value,
                    // cityName: e.props.children,
                    barangay: undefined
                  });
                }}
              >
                {cities &&
                  cities.map((city, index) => {
                    return (
                      <Option value={city.id} key={`${city.id}${index}`}>
                        {city['city_name']}
                      </Option>
                    );
                  })}
              </Select>
              <Select
                showSearch
                placeholder='Select a Barangay'
                optionFilterProp='barangay'
                className='location-select'
                name='barangay'
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                disabled={!city}
                value={barangay}
                onChange={(value, e) => {
                  this.setState({
                    barangay: value,
                    // barangayName: e.props.children
                  });
                }}
              >
                {barangays &&
                  barangays.map((barangay, index) => {
                    return (
                      <Option value={barangay.id} key={`${barangay.id}${index}`}>
                        {barangay['barangay_name']}
                      </Option>
                    );
                  })}
              </Select>
            </div>
            <GradientBtn
              className='agree-btn'
              onClick={this.handleOk}
              block
              disabled={!province || !city || !barangay}>
              CONFIRM LOCATION
            </GradientBtn>

            <GradientBtn className='cancel-btn' onClick={this.handleCancel} block>
              BROWSE ANYWAY
            </GradientBtn> */}


            <GradientBtn className='cancel-btn' onClick={this.handleCancelWithoutForm} block>
              I UNDERSTAND
            </GradientBtn>
          </Modal>

          <WrappedComponent {...this.props} showModal={this.showModal} />
        </>
      );
    }
  };

  return compose(withCookies, withSelectOptions)(EnhancedComponent);
};

export default withLocationNotice;
