import { login, getUserInfo } from "Services/authServices";
import { get } from "lodash";
import cartActions from "Redux/cart/actions";
import appActions from "Redux/app/actions";
import { push } from "connected-react-router";
import { removeToken, setToken } from "Services/tokenService";

export const actionTypes = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  CLEAR_FAIL: "CLEAR_FAIL",
  SET_USER: "SET_USER"
};

let actions = {
  login: (credentials, isFromCheckout) => {
    return async dispatch => {
      dispatch(appActions.toggleBodyLoader(true));

      try {
        // dispatch({
        //   type: actionTypes.LOGIN_REQUEST
        // });

        // Login request
        let result = await login(credentials);

        setToken(result.headers["access-token"]);

        console.log(result.headers["access-token"], "test");
        // console.log('login_result', result.data);

        if(result.status && result.status == 200) {
          // pass cart conflict value to determin if there is a notification for conflict cart 
          dispatch(appActions.isCartConflict(result.data.cart_conflict));
          // store in localstorage because after redirecting the state will reset
          window.localStorage.setItem('CART_CONFLICT', result.data.cart_conflict); 
        }

        if (!isFromCheckout) {
          window.location.replace("/buy-beer");
        } else {
          console.log('login_result', result);
          window.location.replace("/checkout");
        }

        // await dispatch(actions.initUser());

        // dispatch({
        //   type: actionTypes.LOGIN_SUCCESS
        // });
      } catch (e) {
        console.log(e.response);

        if (get(e, "response.status")) {
          if (get(e, "response.data.error")) {
            dispatch({
              type: actionTypes.LOGIN_FAIL,
              payload: e.response.data
            });
          } else if (get(e, "response.data.success")) {
            dispatch({
              type: actionTypes.LOGIN_FAIL,
              payload: {
                error: e.response.data.success
              }
            });
          } else {
            dispatch({
              type: actionTypes.LOGIN_FAIL,
              payload: e.response.data
            });
          }
        } else {
          dispatch({
            type: actionTypes.LOGIN_FAIL,
            payload: e
          });
        }
      }

      dispatch(appActions.toggleBodyLoader(false));
    };
  },
  initUser: () => {
    return async dispatch => {
      try {
        await fetchUser(dispatch);
        console.log("Logged In");
      } catch (e) {
        if (!e.status) {
          console.log("Guest");
        } else {
          console.log("ERROR USER : ", e);
        }
      } finally {
        // NOTE: To back-end, if the cart is empty do not response error.
        dispatch(cartActions.fetchCart());
      }
    };
  },
  logOut: () => {
    return async dispatch => {
      dispatch(appActions.toggleBodyLoader(true));

      dispatch({
        type: actionTypes.LOGOUT_REQUEST
      });

      removeToken();

      try {
        await fetchUser(dispatch);
      } catch (e) {
        dispatch({
          type: actionTypes.LOGOUT_SUCCESS
        });

        dispatch(cartActions.updateCart({}));

        localStorage.setItem('CART_CONFLICT', false); // remove conflict state after login
        document.cookie = "originalCategoryID=";
        document.cookie = "originalCategoryName=";
        // cookies.set('originalCategoryID', "");
        // cookies.set('originalCategoryName', "");

        dispatch(push("/login"));
      }

      dispatch(appActions.toggleBodyLoader(false));
    };
  },
  clearFail: () => {
    return dispatch => {
      dispatch({
        type: actionTypes.CLEAR_FAIL
      });
    };
  }
};

const fetchUser = async dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      let user = await getUserInfo();

      console.log(user, "userLogin");
      const policy_agreement_text = get(user, "policy_agreement_text");
      const term_and_condition_text = get(user, "term_and_condition_text");
      const about_us_text = get(user, "about_us_text");
      dispatch({
        type: actionTypes.SET_USER,
        payload: {
          isLoggedIn: true,
          user,
          privacyContent: policy_agreement_text,
          termsContent: term_and_condition_text,
          aboutUsContent: about_us_text,
        }
      });

      resolve(user);
    } catch (error) {
      const policy_agreement_text = get(
        error,
        "response.data.policy_agreement_text"
      );
      const term_and_condition_text = get(
        error,
        "response.data.term_and_condition_text"
      );
      const about_us_text = get(
        error,
        "response.data.about_us_text"
      );
      dispatch({
        type: actionTypes.SET_USER,
        payload: {
          isLoggedIn: false,
          user: null,
          privacyContent: policy_agreement_text,
          termsContent: term_and_condition_text,
          aboutUsContent: about_us_text,
        }
      });
      reject(error);
    }
  });
};

export default actions;
