import smbService from "./sessionInterceptor";

export const getUserDetails = () => {
  return smbService.get(`/user`);
};

export const editUserDetails = values => {
  return smbService.put("/user", values);
};

export const requestPasswordLink = email => {
  return smbService.post("/reset/password/get-link", email);
};

export const resetPassword = values => {
  return smbService.post("/reset/password", values);
};

export const deleteUserAccount = () => {
  return smbService.delete(`/user`);
};
