import React, {Fragment} from 'react';

const CustomButton = ({onClickHandler, buttonText}) => {

    return (
        <Fragment>
            <button
                className="-edit-trigger red-filled-btn"
                onClick={onClickHandler}
              >
                {buttonText}
              </button>

        </Fragment>
    );
};

export default CustomButton;