import React, { Component } from 'react'
import PropTypes from 'prop-types';

import { Form } from 'antd';
import Text from 'antd/lib/typography/Text';


class FormItemWrapper extends  Component {
    static propTypes = {
      name: PropTypes.string,
      label: PropTypes.string,
      error: PropTypes.string
    }

    render(){
      const { name, label, error, children, required } = this.props;

      return (
          <Form.Item label={label && <span>{label} {required ?
                                  (<span className="required-asterisk">*</span>) : ''}</span>}>
            {children}
            {error && <Text type="danger" strong>{error}</Text>}
          </Form.Item>
      )
    }
}



export default FormItemWrapper
