
import smbService from './sessionInterceptor';
/**
 * @param {*} userDetails
 * @param {string} userDetails.first_name
 * @param {string} userDetails.last_name
 * @param {string} userDetails.birthday
 * @param {string} userDetails.email
 * @param {string} userDetails.i_agree
 */
export const preRegister = (userDetails) => {
    console.log('preRegister', userDetails)

    return new Promise(async(resolve, reject) => {
        try {
            let result = await smbService.post('/pre-register', userDetails)
            resolve(result)
        }catch(e){
            reject(e.response)
        }

    })

}

/**
 * @param {string} token
 * @param {*} userDetails
 * @param {string} userDetails.password
 * @param {string} userDetails.password_confirm
 */
export const manualRegister = (token, userDetails) => {
    console.log('manualRegister', userDetails)

    return new Promise(async(resolve, reject) => {
        try {
            let result = await smbService.post('/registration', userDetails,
            {
                headers: { "registration-token": token }
            })
            resolve(result)
        }catch(error){
            reject(error)
        }

    })

}

export const socialRegister = (socialAccountInfo) => {
  const requestBody = {
    ...socialAccountInfo
  };
  let headerBody = {};

  if(socialAccountInfo.socialSource === 'fb') {
    headerBody = {
      headers: { "fb-token": socialAccountInfo.userAccessToken }
    }
  } else if (socialAccountInfo.socialSource === 'google') {
    headerBody = {
      headers: { "google-token": socialAccountInfo.userAccessToken }
    }
  } else {
    throw new Error();
  }

  return smbService.post('/registration', requestBody, headerBody);
}
