import React, {
  Component
} from 'react';
import PropTypes from 'prop-types';
import DeleteAccountTPL from './DeleteAccount.tpl';

class DeleteAccount extends Component {

  render() {
    return ( < DeleteAccountTPL / > )
  }
};


DeleteAccount.propTypes = {};

export default DeleteAccount;
