import { setInterceptor } from 'Services/sessionInterceptor';
import AuthenticationActions from 'Redux/auth/actions' 
import  { store } from 'Redux/store';
import cartActions from 'Redux/cart/actions'

const { dispatch } = store

const boot = () => {
    
    setInterceptor()

    dispatch(AuthenticationActions.initUser()) 
    
}

export default boot;
