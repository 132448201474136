import { chunk } from 'lodash';
import numeral from 'numeral';

export const getCaseGroupConfiguration = (cartCase) => {
  const { name, items, unit_per_case, minimum_order_qty } = cartCase;
  
  let totalCaseItems = 0;
  let totalCases = 0;
  let missingItems = 0;
  let totalCasePrice = 0;
  let caseItemsIds = [];
  let caseType = '';
  let packCounter = '';

  try {
    totalCaseItems = items.reduce((previousValue, currentValue) => {

      // console.log('currentValue', currentValue);

      if(currentValue.quantity_per_pack > 1) {
        packCounter = currentValue.quantity;
        return previousValue + currentValue.quantity_per_pack
      } else {
        return previousValue + currentValue.quantity
      }
      // return previousValue + currentValue.quantity
      
    }, 0);

    totalCasePrice = items.reduce((previousValue, currentValue) => {
      return previousValue + parseInt(currentValue['total_price'])
    }, 0);

    caseType = items.reduce((previousValue, nextValue) => {
      return nextValue.packaging;
    }, {});

    // if(cartCase.items[0].packaging == "Pack") {
    //   let fillableArray = [];
    //   for(let x = 1; x <= packCounter; x++) {
    //     fillableArray.push(x);
    //   }
    //   totalCases = chunk(fillableArray, unit_per_case);
    // } else {
    //   totalCases = chunk([...new Array(totalCaseItems)], unit_per_case);
    // }

    totalCases = chunk([...new Array(totalCaseItems)], unit_per_case);

    let totalCaseLength = totalCases.length * minimum_order_qty;
    missingItems = totalCaseItems > minimum_order_qty ? 0 : totalCaseLength - totalCaseItems
    // missingItems = (totalCases.length * minimum_order_qty) - totalCaseItems;
    
    caseItemsIds = items.map(item => {
      return item.id
    });


  } catch(e) {
    return {
      totalCaseItems: 0,
      totalCasePrice: 0,
      totalCases: 0,
      missingItems: 0,
      caseItemsIds: [],
      caseType: ''
    };
  }


  return {
    totalCaseItems,
    totalCasePrice,
    totalCases: totalCases.length,
    missingItems,
    caseItemsIds,
    caseType
  };
};

export const summarizeCaseContents = (cartCase) => {
  const { name, items, unit_per_case } = cartCase;

  try {
    return items.reduce((previousValue, currentValue, index) => {
      return `${previousValue}${currentValue.quantity} ${currentValue['product_name']}${index + 1 < items.length ? ', ' : ''}`;
    }, '');
  } catch(e) {
    return '';
  }
}

export const getCaseType = (cartCase) => {
  console.log('CartCase', cartCase);

  switch(cartCase.toLowerCase()) {
    case 'keg':
      return {
        name: 'keg',
        singularTypeName: 'Keg',
        pluralTypeName: 'Keg',
        icon: 'icon-case'
      }
    // case 'full goods': //Bottles
    //   return {
    //     name: 'case',
    //     singularTypeName: 'Bottle',
    //     pluralTypeName: 'Bottles',
    //     icon: 'icon-case'
    //   }
    case 'bottles' || 'bottle': //Bottles
      return {
        name: 'case',
        singularTypeName: 'Bottle',
        pluralTypeName: 'Bottles',
        icon: 'icon-case'
      }
    case 'cans' || 'can':
      return {
        name: 'box',
        singularTypeName: 'Can',
        pluralTypeName: 'Cans',
        icon: 'icon-box'
      }
    default:
      return {
        name: 'case',
        singularTypeName: 'Bottle',
        pluralTypeName: 'Bottles',
        icon: 'icon-case'
      }
  }
};


export const printStandardDecimals = (value = 0) => {
  try {
    return numeral(value).format('0,0.00')
  } catch(e) {
    return 0;
  }
}


export const getCartTotalItems = (cart) => {

  try {
    return cart.reduce((previousValue, currentValue) => {

      const cartCaseItemsQty = currentValue.items.reduce((previousValue, nextValue) => {
        return previousValue + nextValue.quantity;
      }, 0);

      return previousValue + cartCaseItemsQty;
    }, 0)
  } catch(error) {
    return 0;
  }

}


export const getNumberOfCases = (goToCaseItems, itemsPerCase) => {
  let cases = 0;
  const finalItemsPerCase = itemsPerCase !=0 ? itemsPerCase : 1;

  if(goToCaseItems > finalItemsPerCase) {

    cases = Math.floor(goToCaseItems / finalItemsPerCase) + 1;

    return {
      cases,
      unitItemsTotal: cases * finalItemsPerCase
    };
  }

  return {
    cases: 1,
    unitItemsTotal: finalItemsPerCase != 0 ? finalItemsPerCase : 1
  };
}
