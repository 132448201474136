import { getProducts, getPromoProducts } from "Services/productServices";

export const actionTypes = {
   UPDATE_PRODUCTS: 'UPDATE_PRODUCTS',
   UPDATE_PRODUCT_TYPES: 'UPDATE_PRODUCT_TYPES',
   UPDATE_PRODUCT_BRANDS: 'UPDATE_PRODUCT_BRANDS',
   UPDATE_PRODUCT_LOADING: 'UPDATE_PRODUCT_LOADING',
   UPDATE_RECOMMENDED_PRODUCTS: 'UPDATE_RECOMMENDED_PRODUCTS'
}


export const fetchProducts = (filters) => {

    return async (dispatch, getState) => {
        try {
            const { types, brands } = getState().products

            dispatch({
                type: actionTypes.UPDATE_PRODUCT_LOADING,
                payload: true
            })

            const results = await getProducts(filters);


            dispatch({
                type: actionTypes.UPDATE_PRODUCTS,
                payload: results.products
            })

            dispatch({
                type: actionTypes.UPDATE_RECOMMENDED_PRODUCTS,
                payload: results.recommended_products
            })

            if(types.length == 0) {
              dispatch({
                  type: actionTypes.UPDATE_PRODUCT_TYPES,
                  payload: results.filter_types
              })
            }

          if(brands.length == 0) {
            dispatch({
                type: actionTypes.UPDATE_PRODUCT_BRANDS,
                payload: results.brands
            })
          }

        } catch(e) {
            console.error(e);
            throw e;

            return Promise.reject(false);
        } finally {
          dispatch({
              type: actionTypes.UPDATE_PRODUCT_LOADING,
              payload: false
          })
        }

        return Promise.resolve(true);
    }
}


export const fetchPromoProducts = (filters) => {

    return async (dispatch, getState) => {
        try {
            const { types, brands } = getState().products

            dispatch({
                type: actionTypes.UPDATE_PRODUCT_LOADING,
                payload: true
            })

            const results = await getPromoProducts(filters);

            dispatch({
                type: actionTypes.UPDATE_PRODUCTS,
                payload: results.products
            })

            dispatch({
                type: actionTypes.UPDATE_RECOMMENDED_PRODUCTS,
                payload: results.recommended_products
            })

            if(types.length == 0) {
              dispatch({
                  type: actionTypes.UPDATE_PRODUCT_TYPES,
                  payload: results.filter_types
              })
            }

          if(brands.length == 0) {
            dispatch({
                type: actionTypes.UPDATE_PRODUCT_BRANDS,
                payload: results.brands
            })
          }

        } catch(e) {
            console.error(e);
            throw e;

            return Promise.reject(false);
        } finally {
          dispatch({
              type: actionTypes.UPDATE_PRODUCT_LOADING,
              payload: false
          })
        }

        return Promise.resolve(true);
    }
}
