import React from 'react';
import './ProductDesc.scss';
import { isNil } from 'lodash';
import { createMarkup } from 'Utils/stringUtils';

const ProductDesc = ({ name, type, types, bulkPrice, price, desc, selectedType, computedPrice }) => {
  console.log(types);

  return (
    <div className="product-desc">
      <div className="product-desc" dangerouslySetInnerHTML={createMarkup(desc)}/>
    </div>
  );
};

export default ProductDesc;
