import React, { lazy } from "react";
import { Switch, Route, } from 'react-router';
import { Redirect } from 'react-router-dom';
import Loading from 'Components/Loading/Loading';

const Splash = lazy(() => import('Scenes/Splash/Splash'));

/**
 * A component to return the Route if it passes the
 * visibility condition
 *
 * @param {object} props
 * @param {reactelement} props.component
 * @param {boolean} props.condition
 * @param {string} props.redirectTo
 */
function RestrictedRoute ({
  component: Component,
  condition,
  redirectTo,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={props => {
        return condition ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectTo,
              state: {
                from: props.location
              }
            }}
          />
        );
      }}
    />
  )
};


/**
 * This is a component that filters routes that are exclusive to
 * logged in users
 * @param {object} props React props
 * @param {array} props.routes Array of route objects
 * @param {boolean} props.isLoggedIn Checks if user is logged in
 */
function authOnlyRoutes ({routes, isLoggedIn}) {
  return routes
  .filter(route => route.auth)
  .map(route => {
    return <RestrictedRoute
      key={route.path}
      path={route.path}
      component={route.component}
      condition={isLoggedIn}
      redirectTo="/login"
      />;
  })
}

/**
 * This is a component that filters routes that are exclusive to
 * guest/logged out users
 * @param {object} props React props
 * @param {array} props.routes Array of route objects
 * @param {boolean} props.isLoggedIn Checks if user is logged in
 */
function guestOnlyRoutes ({routes, isLoggedIn}) {
  return (
    routes
      .filter(route => route.guestOnly)
      .map(route => {
        return <RestrictedRoute
                  key={route.path}
                  path={route.path}
                  component={route.component}
                  condition={isLoggedIn === false}
                  redirectTo="/"
                />;
      })
  )
}

/**
 * This is a component that filters routes that are available to everyone
 * @param {object} props React props
 * @param {array} props.routes Array of route objects
 */
function globalRoutes ({routes}) {
  return (
    routes
      .filter(route => !route.auth)
      .map(route => {
        return <Route
                  path={route.path}
                  key={route.path}
                  exact={route.exact}
                  component={route.component}
                />;
      })
  )
}

/**
 * The component that  react router switching
 * according to the type of user that is trying
 * to access a certain resource.
 *
 * @param {object} props
 * @param {object} props.cookies
 * @param {boolean} props.ageVerify
 * @param {object} props.auth
 * @param {boolean} props.auth.isLoggedIn
 * @param {boolean} props.auth.loginLoading
 * @param {array} props.routes
 *
 */
function RouteSwitcher(props) {
  const { cookies, ageVerify, routes } = props;
  let { isLoggedIn, loginLoading } = props.auth;

  return loginLoading ? (
    <div><Loading /></div>
  ) : !ageVerify && !isLoggedIn ? (
    <Splash cookies={cookies} />
  ) : (
    <Switch>
      {/* React router switch only works on direct decendants which are <Route /> components */}
      {authOnlyRoutes({routes, isLoggedIn})}
      {guestOnlyRoutes({routes, isLoggedIn})}
      {globalRoutes({routes})}
    </Switch>
  );
}


export default RouteSwitcher
