import React, {Component} from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import RecoProductsTPL from './RecoProducts.tpl';
import { addToCart } from 'Services/cartServices';
import CartActions from 'Redux/cart/actions';
import appActions from 'Redux/app/actions';

class RecoProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      successModalVisibility: true,
      modalMessage: 'Success!',
      addingToCart: false,
      addToCartResult: '',
      addToCartResultMessage: '',
      disabledProducts: []
    };
  }

  addToCartAction = async (product_id, quantity) => {
    try {
      await addToCart({
        product_id,
        quantity
      });

      // this.setState({
      //   successModalVisibility: true,
      //   modalMessage: 'You sucessfully added this product to your shopping cart.',
      //   addingToCart: false
      // });

      await this.props.fetchCart();
      this.props.toggleCartDrawer();
    } catch (error) {
      const errorCode = get(error, 'response.status');
      let errorItems = [];
      let errorMessage = '';

      if(errorCode === 422) {
        errorMessage = get(error, 'response.data.title');
        errorItems = get(error, 'response.data.products');
      } else {
        errorMessage = 'Something went wrong adding this product to the cart.';
      }

      this.setState({
        addToCartResult: 'FAIL',
        addToCartResultMessage: errorMessage,
        disabledProducts: Array.isArray(errorItems) ? errorItems : [],
        addingToCart: false
      });
    }
  };

  handleAddToCart = (product_id, quantity) => {
    return () => {
      this.setState(
        {
          addingToCart: true
        },
        () => this.addToCartAction(product_id, quantity)
      );
    }
  };

  handleModalClose = () => {
    this.setState({
      addToCartResult: '',
      addToCartResultMessage: '',
      disabledProducts: []
    });
  }

  render() {
    const {
      addToCartResult,
      addToCartResultMessage,
      disabledProducts
    } = this.state;
    const { products,
            sectionTitle,
            shouldLinkToProducts } = this.props;

    return (<RecoProductsTPL
              products={products}
              sectionTitle={sectionTitle}
              handleAddToCart={this.handleAddToCart}
              shouldLinkToProducts={shouldLinkToProducts}
              addToCartResult={addToCartResult}
              addToCartResultMessage={addToCartResultMessage}
              disabledProducts={disabledProducts}
              handleModalClose={this.handleModalClose}
              />)
  }
};


RecoProducts.propTypes = {
  products: PropTypes.array.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  shouldLinkToProducts: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCart: () => {
      dispatch(CartActions.fetchCart());
    },
    toggleCartDrawer: val => {
      dispatch(appActions.toggleCartDrawer(val));
    },
  }
}

export default connect(null, mapDispatchToProps)(RecoProducts);
