import React, {Fragment, useState, useEffect} from 'react';
import {Button, Modal} from 'antd';
import GradientBtn from 'Components/GradientBtn/GradientBtn';
import './ExistingAddressesModal.scss'

const ExistingAddressesModal = ({existingAddressModalOnOkHandler, customerExistingAddresses, onCancel, visible}) => {

    
    const [selectedAddress, setSelectedAddress] = useState({});
    // const [visible, setVisible] = useState(true);
    
    const hasAddresses = customerExistingAddresses && Object.keys(customerExistingAddresses).length > 0;

    return (
      <Fragment>
        <Modal visible={visible} keyboard={false} footer={null} onCancel={onCancel}>
        {hasAddresses &&
          <div className="saved-address-modal">
            <h2 className="saved-address-header">SAVED ADDRESS</h2>
            <div className="address-items-list">
              {customerExistingAddresses.map(adr => <AddressItem key={adr.id} address={adr} setSelectedAddress={setSelectedAddress}/>)}
            </div>
            <GradientBtn onClick={existingAddressModalOnOkHandler.bind(null, selectedAddress)} className="use-address-button">USE THIS ADDRESS</GradientBtn>
          </div>}
        </Modal>  
      </Fragment>
    );
  };


const AddressItem = ({address, setSelectedAddress}) => {
    const onClickHandler = () => {
        setSelectedAddress(address);
    };
    return (
      <Fragment>
        <div className="address-item" onClick={onClickHandler}>
          <span className="icon-home-add icon" />
            {address.address}
          <span className="icon-next" />
        </div>
      </Fragment>
    );
};

export default ExistingAddressesModal;