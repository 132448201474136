import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Collapse, Button, Icon, Modal} from 'antd';
import { Link } from 'react-router-dom';
import { round } from 'lodash';
import pluralize from 'pluralize';
import './../../Cart.scss';
import CartItems from 'Components/Cart/components/CartItems/CartItems';
import { getCaseGroupConfiguration, summarizeCaseContents, getCaseType } from 'Utils/cartUtils';
import CartGoToCaseForm from '../CartGoToCaseForm/CartGoToCaseForm';

const CartCaseTPL = (props) => {
  const {
    auth = { isLoggedIn: false },
    cartCase,
    forceHideSummary = false,
    handleDeleteCase,
    goToCaseSelectorOpen,
    goToCaseItems,
    handleInitiateSaveGoToCase,
    handleCloseSaveGoToCase,
    setGoToCaseSelectorResult,
    goToCaseSelectorResult,
    goToCaseSelectorResultMessage,
    handleSuccessAddingCartToGTC,
    handleFailAddingCartToGTC,
    handleAskDeletingCase,
    isDeletingCase
  } = props;
  const Panel = Collapse.Panel;
  const { name, items, unit_per_case, minimum_order_qty } = cartCase;

  console.log('cartCase', cartCase);

  const caseGroupConfiguration = getCaseGroupConfiguration(cartCase);
  const summarizedCaseContents = summarizeCaseContents(cartCase);
  const {
          totalCaseItems,
          totalCases,
          missingItems,
          totalCasePrice,
          caseItemsIds,
          caseType,
        } = caseGroupConfiguration;
  const actualCaseType = getCaseType(caseType);
  const caseTypeName = pluralize(actualCaseType.name, totalCases);
    return (
        <Fragment>
          <div className="cart-group-items-container">
            <Collapse defaultActiveKey={['1']}  bordered={false}>
                <Panel
                    showArrow={false}
                    key="1"
                    header={
                    <div className="cart-panel-heading">
                        <div className="cart-info-col">
                        <div className="name-qty-row">
                            <div className="case-type-icon-col">
                            <span className={actualCaseType.icon}></span>
                            </div>
                            <div className="case-type-col">
                              <p className="case-type">{name} ({`${totalCases} ${caseTypeName}`})</p>
                              <p className="case-summary">({`${summarizedCaseContents}`})</p>
                            </div>
                            <div className="panel-state-icon-col">
                            <span className="icon-arrow-down"></span>
                            </div>
                        </div>
                        <div className="missing-items-row">
                          {
                            missingItems ?
                            <p className="insufficient-message"><span className="icon-warning"></span> Need {`${missingItems}`} More</p> : null
                          }
                        </div>
                        <div className="summary-items-row">
                          <div className="case-items-col">
                          <p className="heading">Quantity</p>
                          <div className="case-items-box-action">
                            <div className={missingItems > 0 ? "case-items-box -incomplete" : "case-items-box"}>
                              <span className="case-size">{totalCaseItems}</span>
                              /
                              
                              <span className="case-size-max">{`${unit_per_case * totalCases}`}</span>
                              {/* <span className="case-size-max">{`${minimum_order_qty * totalCases}`}</span> */}
                            </div>
                            <button
                            className="delete-cart-item -transparent"
                              onClick={handleAskDeletingCase(true)}
                            >
                            <span className="icon-trash"></span>
                            </button>
                          </div>
                          </div>
                          <div className="case-summary-col"><p className="case-summary">({`${summarizedCaseContents}`})</p></div>
                        </div>

                        <div className="case-price-row">
                            <p className="heading">Price</p>
                            <span className="case-price">P {`${round(totalCasePrice, 2).toFixed(2)}`}</span>
                        </div>

                        </div>
                        <div className="panel-state-icon-col">
                        <span className="icon-arrow-down"></span>
                        </div>
                    </div>
                    }
                >
                    <CartItems items={items} />

                      <div className="cart-group-items-footer">
                        <p className="cart-group-items-total">P{`${round(totalCasePrice, 2).toFixed(2)}`}</p>
                        {
                          !forceHideSummary &&
                          <div className="cart-group-items-action">
                            <Link to={`/buy-beer?filterByType=${actualCaseType.singularTypeName.toLowerCase()}#browse-cont`} className="ant-btn add-trigger -filled">
                              <Icon type="plus" />
                              <span>Add More</span> <span className="hidden-desktop"> Bottles</span>
                            </Link>
                            {/* Mobile Only */}
                            <p className="case-size">{name}</p>
                            {/* <p className="case-source">From your <span className="gotocase-name">Freaky Fridays</span> Go-to-case</p> */}

                            <div className="save-trigger-container">
                              <Button className="save-trigger" disabled={!auth.isLoggedIn} onClick={handleInitiateSaveGoToCase(caseItemsIds)} block>
                              Save New Go-to Case
                              </Button>
                              {
                                !auth.isLoggedIn &&
                                <p className="login-info">*Please login or create an account to save a go-to case.</p>
                              }
                            </div>

                            <Button
                              onClick={handleDeleteCase(caseItemsIds)}
                              className="remove-trigger -transparent" block>Remove Case</Button>
                          </div>
                        }
                      </div>

                </Panel>
            </Collapse>
          </div>

          <Modal
            className="cart-modal -go-to-case-add -info"
            visible={goToCaseSelectorOpen}
            onCancel={handleCloseSaveGoToCase}
            title={`Add Items to your Go-to Cases (${name} - ${totalCases} ${caseTypeName})`}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            >
            <CartGoToCaseForm
              goToCaseItems={goToCaseItems}
              setGoToCaseSelectorResult={setGoToCaseSelectorResult}
              handleCloseSaveGoToCase={handleCloseSaveGoToCase}
              cartCaseName={`${name} - ${totalCases} ${caseTypeName}`}
              />
          </Modal>


        <Modal
         className="cart-modal -go-to-case-add -success"
         visible={goToCaseSelectorResult === 'SUCCESS'} onOk={handleSuccessAddingCartToGTC} title="Success" cancelButtonProps={{ style: { display: 'none' } }}>
          <p>{goToCaseSelectorResultMessage}</p>
        </Modal>

        <Modal
        className="cart-modal -go-to-case-add -error"
        visible={goToCaseSelectorResult === 'FAIL'} onOk={handleFailAddingCartToGTC} title="Error" cancelButtonProps={{ style: { display: 'none' } }}>
          <p>{goToCaseSelectorResultMessage}</p>
        </Modal>


        <Modal  className="cart-modal -delete-case"
                visible={isDeletingCase}
                onOk={handleDeleteCase(caseItemsIds)}
                onCancel={handleAskDeletingCase(false)}
                title="Warning"
        >
          <p>Are you sure you want to delete {name}?</p>
        </Modal>

        </Fragment>
    )
};

CartCaseTPL.defaultProps = {
  cart: []
}

CartCaseTPL.propTypes = {
  auth: PropTypes.object,
  forceHideSummary: PropTypes.bool,
  handleDeleteCase: PropTypes.func,
  goToCaseSelectorOpen: PropTypes.bool,
  goToCaseItems: PropTypes.array,
  handleInitiateSaveGoToCase: PropTypes.func,
  handleCloseSaveGoToCase: PropTypes.func,
  setGoToCaseSelectorResult: PropTypes.func,
  goToCaseSelectorResult: PropTypes.string,
  goToCaseSelectorResultMessage: PropTypes.string,
  handleSuccessAddingCartToGTC: PropTypes.func,
  handleFailAddingCartToGTC: PropTypes.func,
  handleAskDeletingCase: PropTypes.func,
  isDeletingCase: PropTypes.bool
};

export default CartCaseTPL;


