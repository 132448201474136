import React from 'react';
import { Icon } from 'antd';

const styles = {
    padding:'10px'
}

const SuspenseFallback = () => {
    return <div style={styles}>
       <Icon type="loading" />
    </div>
}


export default SuspenseFallback;