
import { actionTypes } from './actions'

export const initialState = {
    isLoggedIn: null,
    loginLoading: true,
    errorMessage:'',
    user: null
}

export default function authReducer(state = initialState, action) {

    switch (action.type) {
        case actionTypes.LOGIN_REQUEST :
            return {
              ...state,
              loginLoading:true
            }
        case actionTypes.LOGIN_SUCCESS :
            return {
              ...state,
              isLoggedIn: true,
              loginLoading:false
            }
        case actionTypes.LOGIN_FAIL :
            return {
              ...state,
              errorMessage: action.payload,
              loginLoading:false
          }
        case actionTypes.CLEAR_FAIL :
            return {
              ...state,
              errorMessage: ''
            }
        case actionTypes.SET_USER :
            const { isLoggedIn, user } = action.payload
            return {
                ...state,
                isLoggedIn,
                loginLoading:false,
                user

            }
        default:
            return state
    }

}
