import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import CartTPL from './Cart.tpl';
import { isCartReadyForCheckout } from 'Services/cartServices';
import { addToCart } from 'Services/cartServices';
import CartActions from 'Redux/cart/actions';
import { withCookies } from 'react-cookie';
import {getSelectedLocationFromCookie} from 'Utils/commonUtils';

class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileView: false,
      showTotal: false,
      isViewingSummaryInMobile: false,
      isErrorModalVisible: false,
      errorMessage: '',
      errorItems: []
    };
  }

  addToCartAction = async (product_id, quantity) => {
    const {cookies} = this.props;
    const {barangay_id, street_id} = getSelectedLocationFromCookie(cookies);
    let query = {
      barangay: barangay_id
    };
    if (street_id) {
      query.street_id = street_id;
    }
    const data = {
      ...query,
      product_id: product_id,
      quantity: quantity,
    };
    try {
      await addToCart(data);

      this.setState({
        successModalVisibility: true,
        modalMessage: 'You successfully added this product to the cart.',
        addingToCart: false
      });

      await this.props.fetchCart();
      this.props.toggleCartDrawer();
    } catch (e) {
      console.log(e);
      this.setState({
        successModalVisibility: true,
        modalMessage: `Something went wrong adding this product to cart.`,
        addingToCart: false
      });
    }
  };

  handleAddToCart = async (product_id, quantity) => {
    this.setState(
      {
        addingToCart: true
      },
      () => this.addToCartAction(product_id, quantity)
    );
  };

  handleSwitchToCart = async () => {
    const { history, auth, deleteCart, cookies } = this.props;

    if (auth.isLoggedIn) {
      try {
        const {barangay_id, street_id} = getSelectedLocationFromCookie(cookies);
        let query = {
          barangay: barangay_id
        };
        if (street_id) {
          query.street_id = street_id;
        }
        
        const response = await isCartReadyForCheckout(query);

        console.log(response);


        // deleteCart();
        history.push('/checkout');
      } catch (error) {
        const statusCode = get(error, 'response.status');
        // const errorMessage = get(error, 'response.data.title') || '';
        const errorMessage = get(error, 'response.data.error') || '';
        const errorItems = get(error, 'response.data.error') || get(error, 'response.data.products') || [];


        if(statusCode && statusCode === 410) {
          window.location.replace('/checkout');
        } else {

          this.setState({
            errorMessage,
            errorItems
          });

          if(Array.isArray(errorItems) && errorItems.length > 0) {
            await this.props.fetchCart();
          }

          console.log(errorMessage, errorItems);

          this.toggleErrorModal(error);
        }

      }
    } else {
      // deleteCart();
      history.push('/checkout');
    }
  };

  toggleSummaryInMobile = e => {
    const { isViewingSummaryInMobile } = this.state;

    this.setState({
      isViewingSummaryInMobile: !isViewingSummaryInMobile
    });
  };

  toggleErrorModal = () => {
    const { isErrorModalVisible } = this.state;

    this.setState({
      isErrorModalVisible: !isErrorModalVisible
    });
  };

  render() {
    const { auth, forceHideSummary, cart, showTotal, onBar, isAttachedInSidebar } = this.props;
    const { isMobileView,
            isViewingSummaryInMobile,
            isErrorModalVisible,
            errorItems,
            errorMessage
          } = this.state;
    const errorModal = {
      onConfirm: this.toggleErrorModal,
      isVisible: isErrorModalVisible
    };

    return (
      <CartTPL
        showTotal={showTotal}
        isMobileView={isMobileView}
        isViewingSummaryInMobile={isViewingSummaryInMobile}
        isAttachedInSidebar={isAttachedInSidebar}
        cart={cart}
        forceHideSummary={forceHideSummary}
        toggleSummaryInMobile={this.toggleSummaryInMobile}
        handleSwitchToCart={this.handleSwitchToCart}
        errorModal={errorModal}
        errorItems={errorItems}
        errorMessage={errorMessage}
        onBar={onBar}
        addToCart={this.handleAddToCart}
        auth={auth}
      />
    );
  }
}

Cart.propTypes = {
  forceHideSummary: PropTypes.bool,
  isAttachedInSidebar: PropTypes.bool,
  showTotal: PropTypes.bool,
  history: PropTypes.object,
  auth: PropTypes.object
};

function mapStateToProps(state) {
  return {
    ...state.cart,
    auth: state.auth
  };
}

const mapDispatchToProps = dispatch => {
  return {
    deleteCart: () => {
      dispatch(CartActions.deleteAllCartItemsWithoutFetch())
    },
    fetchCart: () => {
      dispatch(CartActions.fetchCart());
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withCookies(Cart))
);
