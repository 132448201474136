import React, { Component } from "react";
import "./withModal.scss";
import { Modal } from "antd";

const withModal = (WrappedComponent, conf) => {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        modalProps: {},
        ModalContents: null
      };
    }

    toggleModal = (modalProps, ModalContents = null) => {
      this.setState({
        modalProps,
        ModalContents
      });
    };

    updateModalProps = updatedModalProps => {
      this.setState({
        modalProps: {
          ...this.state.modalProps,
          ...updatedModalProps
        }
      });
    };

    render() {
      const { modalProps, ModalContents } = this.state;

      return (
        <>
          <Modal {...modalProps}>
            {ModalContents && <ModalContents {...modalProps} />}
          </Modal>
          <WrappedComponent
            toggleModal={this.toggleModal}
            updateModalProps={this.updateModalProps}
            {...this.props}
          />
        </>
      );
    }
  };
};

withModal.propTypes = {};

export default withModal;
