import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import './DynamicBanner.scss';
import useWindowDimensions from '../../hooks/useWindowDimensions'
import useHttp from 'hooks/use-http';
import OperatingTimeContent from 'Components/OperatingTimeContent/OperatingTimeContent';
import GradientBtn from 'Components/GradientBtn/GradientBtn';

const {
    PUBLIC_URL
  } = process.env;

function DynamicBanner(props) {

    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
        arrows: true,
        className: 'image-container'
    };

    const { user, showButtonLink, showUserName } = props
    const { height, width } = useWindowDimensions()
    const { sendRequest } = useHttp()
    const [ data, setData ] = useState([])
    const [ isLoaded, setIsLoaded ] =  useState(false)

    useEffect(() => {
        const callback = responseData => {
            setIsLoaded(true)
            setData(responseData)
        }
        sendRequest({
            url: `${PUBLIC_URL}/api/v1/banners`
            // url: 'http://localhost:5000/banners'
        }, callback)
    }, [])

    const slides = data.map((item, i) => (
        <div key={i}>
            <img src={item.desktop} alt={item.desktop} />
        </div>
    ))
      
    return (
        <section id="dynamic-banner-wrapper">
            <div className="banner-main-container">
                { width >= 1200 ? 
                    isLoaded && data.length > 0 && 
                        <Slider {...settings}>
                            { data.map( d => 
                                <div key={ d.desktop }>
                                    <img src={ d.desktop } alt={ d.desktop} />
                                </div>    
                            )}
                        </Slider>
                :
                    isLoaded && data.length > 0 && 
                        <Slider {...settings}>
                            { data.map( d => 
                                <div key={ d.mobile }>
                                    <img src={ d.mobile } alt={ d.mobile} />
                                </div>    
                            )}
                        </Slider>
                }
            
                {/* { isLoaded && 
                    showUserName && 
                        user && 
                            <div className='welcome-notice'>
                            <div className='top'>
                                <div className='note -welcome'>
                                    <span>Welcome</span>
                                </div>
                                <div className='note -back'>
                                    <span>Back,</span>
                                </div>
                            </div>
                            <div className='bottom'>
                                <div className='note -name'>
                                    <span>{ user.firstname }</span>
                                </div>
                            </div>
                        </div>
                } */}

                { isLoaded && 
                    showButtonLink &&
                        data.length > 0 &&
                            <OperatingTimeContent className="main-cta-button-wrapper">
                                <GradientBtn className='call-to-action -large' href='/buy-beer'>
                                Buy Now!
                                </GradientBtn>
                            </OperatingTimeContent>
                }

            </div>
        </section>
    )
}

export default DynamicBanner
