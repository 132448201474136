import React, { Component } from 'react';
import './DeliveryBanner.scss';
import { Icon } from 'antd';
import { isNil } from 'lodash';

class DeliveryBanner extends Component {
  state = {};
  render() {
    const { cityName, barangayName, handleClick } = this.props;

    const location = !isNil(cityName) ? barangayName + ', ' + cityName : 'All LOCATIONS';

    return (
      <div className='delivery-banner'>
        <p>
          Delivering in{' '}
          <button onClick={handleClick}>
            {
              location ?
              <>{location} <Icon type='caret-down' /></>
              : <>ALL LOCATIONS <Icon type='caret-down' /></>
            }
          </button>
        </p>
      </div>
    );
  }
}

export default DeliveryBanner;
