import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import './AppFooter.scss';
import { Link } from 'react-router-dom';
import { Row, Col, Layout } from 'antd';
import drinkResponsibly from 'Assets/images/drink-responsibly.png';
import SMBLogo from 'Assets/images/smb-logo.png';
import footerRefAccent from 'Assets/images/footer-ref-accent.png';
import { Icon } from 'antd';
import GradientBtn from 'Components/GradientBtn/GradientBtn';

const AppFooterTPL = props => {
  const { handleEmailAddressInput, registerEmailNewsletter, isSubmittingNewsletter, newsletterEmail, newsletterSubmitResult, newsletterResultMessage } = props;
  const { Footer } = Layout;
  let currentYear = new Date().getFullYear();
  return (
    <Footer className="app-footer">
      <div className="pre-footer">
        <img className="footer-ref-accent" src={footerRefAccent} alt="Image" />
      </div>
      <section className="main-footer">
        <div className="centered-container">
          <Row>
            <Col xl={8} md={12} xs={24}>
              <div className="footer-column-content">
                <div className="footer-column-heading-container">
                  <h3 className="footer-column-title san-miguel-brewery">San Miguel Brewery</h3>
                </div>
                <div className="footer-column-body">
                  <Row>
                    <Col md={8} xs={12} className="footer-company-logo-container">
                      <img src={SMBLogo} alt="San Miguel Brewery Inc." />
                    </Col>
                    <Col md={16} xs={12} className="footer-company-contact-container">
                      <div className="contact-details">
                        <Icon type="phone" theme="filled" />
                        <a href="tel:0286322337">(02) 8632 2337</a>
                      </div>
                      <div className="contact-details">
                        <Icon type="mail" theme="filled" />
                        <a href="mailto:632beer@smb.sanmiguel.com.ph">632beer@smb.sanmiguel.com.ph</a>
                      </div>
                      {/* <div className="find-a-store">
                        <a>FIND A STORE</a>
                      </div> */}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col xl={4} md={12} xs={24}>
              <div className="footer-column-content">
                <div className="footer-column-heading-container">
                  <h3 className="footer-column-title">Navigate</h3>
                </div>
                <div className="footer-column-body">
                  <ul className="footer-list">
                    <li className="footer-list-item">
                      <Link to="/" className="footer-list-link">
                        Home
                      </Link>
                    </li>
                    <li className="footer-list-item">
                      <Link to="/about-us" className="footer-list-link">
                        About San Miguel
                      </Link>
                    </li>
                    <li className="footer-list-item">
                      <Link to="/how-to-order" className="footer-list-link">
                        How to Order
                      </Link>
                    </li>
                    <li className="footer-list-item">
                      <Link to="/delivery-areas" className="footer-list-link">
                        Delivery Areas
                      </Link>
                    </li>
                    <li className="footer-list-item">
                      <Link to="/contact-us" className="footer-list-link">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col xl={4} md={12} xs={24}>
              <div className="footer-column-content">
                <div className="footer-column-heading-container">
                  <h3 className="footer-column-title">Legal</h3>
                </div>
                <div className="footer-column-body">
                  <ul className="footer-list">
                    <li className="footer-list-item">
                      <Link to="/terms-and-conditions" className="footer-list-link">
                        Terms & Conditions
                      </Link>
                    </li>
                    <li className="footer-list-item">
                      <Link to="/privacy-policy" className="footer-list-link">
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                  <img className="drink-responsibly-logo" src={drinkResponsibly} alt="Drink Responsibly" />
                </div>
              </div>
            </Col>
            <Col xl={8} md={12} xs={24}>
              <div className="footer-column-content">
                <div className="footer-column-heading-container">
                  <h3 className="footer-column-title">Subscribe</h3>
                </div>
                <div className="footer-column-body">
                  <p className="mailing-list-promo">Join our mailing list to get the latest news and promos!</p>
                  <form onSubmit={registerEmailNewsletter} className="mailing-list-form">
                    <div className="input-caret">
                      <input className="subscribe-input" type="text" placeholder="Email Address" value={newsletterEmail} onChange={handleEmailAddressInput}/>
                      <span className="icon-next caret" />
                    </div>
                    {newsletterResultMessage && <p className={`form-submission-result ${newsletterSubmitResult === 'FAIL' && '-error'}`}>{`${newsletterResultMessage}`}</p>}
                    {/* <GradientBtn disabled={newsletterEmail.length === 0} onClick={registerEmailNewsletter} className="subscribe-btn">
                      Subscribe
                    </GradientBtn> */}
                  </form>
                  <img className="footer-column-content__logo" src={drinkResponsibly} alt="Drink Responsibly" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="sub-footer">
        <div className="centered-container">
          <div className="sub-footer-content">
            <div className="copyright">
              <p className="copyright-container">ALL RIGHTS RESERVED {currentYear}. SAN MIGUEL BEER DELIVERS WEBSITE.</p>
              {/* <p className="copyright-container">Best compatible with IE Edge, Chrome, Safari, and Firefox.</p> */}
            </div>

            {/* <ul className="social-media-links -footer">
              <li className="social-media-list-item">
                <a href="#" className="social-media-link -footer">
                  <span className="icon-facebook" />
                </a>
              </li>
              <li className="social-media-list-item">
                <a href="#" className="social-media-link -footer">
                  <span className="icon-instagram" />
                </a>
              </li>
              <li className="social-media-list-item">
                <a href="#" className="social-media-link -footer">
                  <span className="icon-twitter" />
                </a>
              </li>
            </ul> */}
          </div>
        </div>
      </section>
    </Footer>
  );
};

AppFooterTPL.propTypes = {
  handleEmailAddressInput: PropTypes.func,
  registerEmailNewsletter: PropTypes.func,
  isSubmittingNewsletter: PropTypes.bool,
  newsletterEmail: PropTypes.string,
  newsletterSubmitResult: PropTypes.string,
  newsletterResultMessage: PropTypes.string
};

export default AppFooterTPL;
