import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router';
import app from './app/reducer';
import registration from './registration/reducer';
import gate from './gates/reducers';
import auth from './auth/reducer';
import cart from './cart/reducer'
import products from './products/reducers'
import packages from './packages/reducers'

export default (history) => combineReducers({
  
  app,
  gate,
  registration,
  auth,
  cart,
  products,
  packages,
  router: connectRouter(history),

})