import moment from 'moment';

export const operationDaysAndTime = [
  {
    // Dec 1-23: 8AM TO 8PM
    minDate: '2022-12-01',
    maxDate: '2022-12-23',
    minTime: '08:00',
    maxTime: '20:00',
    format: 'YYYY-MM-DD HH:mm'
  },
  {
    // Dec 24: 8AM TO 3PM
    minDate: '2022-12-24',
    maxDate: '2022-12-24',
    minTime: '08:00',
    maxTime: '15:00',
    format: 'YYYY-MM-DD HH:mm'
  },
  {
    // Dec 26-30: 8AM TO 3PM
    minDate: '2022-12-26',
    maxDate: '2022-12-30',
    minTime: '08:00',
    maxTime: '20:00',
    format: 'YYYY-MM-DD HH:mm'
  },
  {
    // Dec 30: 8AM TO 3PM
    minDate: '2022-12-31',
    maxDate: '2022-12-31',
    minTime: '08:00',
    maxTime: '15:00',
    format: 'YYYY-MM-DD HH:mm'
  },
  {
    // Jan 2 onwards: 8AM TO 3PM
    minDate: '2023-01-02',
    maxDate: '2023-12-31', //Until the end of year
    minTime: '08:00',
    maxTime: '20:00',
    format: 'YYYY-MM-DD HH:mm'
  },

  {
    // April holy week
    minDate: '2022-04-11',
    maxDate: '2022-04-16', //Until the end of year
    minTime: '17:00',
    maxTime: '09:00',
    format: 'YYYY-MM-DD HH:mm'
  }
];

export const isOperatingRightNow = dateRange => {
  let format = 'hh:mm:ss';
  let time = moment();
  
  //gives you current time. no format required.
  // let time = moment('09:34:00',format),

  // Disable checkout from May 7 10PM to May 11 9AM
  if (time.isBetween("2022-05-07", "2022-05-11")) {
    let currentTime = moment(moment().format('YYYY-MM-DD HH:mm'));
    let isBetween = currentTime.isBetween(moment("2022-05-07 22:00", "YYYY-MM-DD HH:mm"), moment("2022-05-11 09:00", "YYYY-MM-DD HH:mm"));
    return !isBetween;

  }
  let beforeTime = moment('08:00:00', format);
  let afterTime = moment('22:00:00', format);

  console.log('beforeTime', beforeTime);
  console.log('afterTime', afterTime);
  
  return time.isBetween(beforeTime, afterTime);

  // const matchingOperations = dateRange.filter((dateRange) => {
  //   const currentDate = moment().format('YYYY-MM-DD');
  //   const currentDateTime = moment().format('YYYY-MM-DD HH:mm');
  //   const isCurrentOperationalDate = moment(currentDateTime, 'YYYY-MM-DD').isBetween(dateRange.minDate, dateRange.maxDate, null, '[]');
  //   const isCurrentOperationalTime = moment(currentDateTime, 'YYYY-MM-DD HH:mm').isBetween(`${currentDate} ${dateRange.minTime}`, `${currentDate} ${dateRange.maxTime}`, null, '[)');

  //   return isCurrentOperationalDate && isCurrentOperationalTime;
  // });

  // return matchingOperations.length > 0;
};
