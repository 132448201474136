import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import './SingleProduct.scss';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Modal, Icon } from 'antd';
import ScrollMemory from 'react-router-scroll-memory';
import { get, isNil } from 'lodash';
import ProductDesc from 'Components/ProductDesc/ProductDesc';
import ProductName from 'Components/ProductDesc/ProductName';
import ProductToggle from 'Components/ProductToggle/ProductToggle';
import Counter from 'Components/Counter/Counter';
import numeral from 'numeral';
import RecoProducts from 'Components/RecoProducts/RecoProducts';
import DeliveryBanner from 'Components/DeliveryBanner/DeliveryBanner';

class SingleProductTPL extends Component {
  state = {
    quantity: 1,
    options: [{ name: 'Case of 12' }, { name: 'Bottle' }],
    selectedType: '',
    multiplier: 1,
    computedPrice: this.props.product.type === 'Cans' ? this.props.product.price_per_case : this.props.product.price,
    isOrderable: this.props.product.orderable
  };

  changeQuantity = count => {
    this.setState({
      quantity: count
    });
  };

  handleAddToCart = () => {
    let { addToCart } = this.props;
    let { quantity, multiplier } = this.state;

    const qty = quantity * multiplier;

    console.log('Adding to cart from solo product...');

    addToCart(qty);
  };

  componentDidMount() {
    this.setUpDisplay();
  }

  componentDidUpdate(prevProps) {
    const prevProductId = get(prevProps, 'product.id');
    const newProductId = get(this.props, 'product.id');

    if (prevProductId && newProductId && prevProductId !== newProductId) {
      this.setUpDisplay();
    }
  }

  setUpDisplay() {
    let { types, unit_per_case } = this.props.product;
    const isCan = types === 'Cans';
    const caseName = types === 'Cans' ? 'Box' : 'Case';
    const soloName = types === 'Cans' ? 'Solo' : 'Bottle';

    let opt = [
      {
        name: `${caseName} of ${unit_per_case}`,
        multiplier: unit_per_case
      },
      {
        name: soloName,
        disabled: !!(types === 'Cans'),
        multiplier: 1
      }
    ];

    let defaultValue = soloName;
    if (types === 'Cans') {
      defaultValue = get(opt.filter(o => !o.disabled)[0], 'name', '');

      // Hide solo option
      opt = opt.slice(0, 1);
    }

    this.setState(
      {
        options: opt,
        selectedType: defaultValue
      },
      this.handleMultiplier
    );
  }

  handleMultiplier = () => {
    let { types, price_per_case, price } = this.props.product;
    let computedPrice = 0;
    const { options, selectedType } = this.state;
    const val = get(options.filter(o => o.name === selectedType)[0], 'multiplier', 1);

    if (selectedType === 'Case of 24' && types === 'Full Goods') {
      computedPrice = price_per_case;
      console.log(computedPrice, selectedType, 'case');
    } else if (selectedType === 'Box of 24' && types === 'Cans') {
      computedPrice = price_per_case;
      console.log(computedPrice, selectedType, 'box');
    } else {
      computedPrice = price;
      console.log(computedPrice, selectedType, 'else');
    }

    this.setState({
      multiplier: val,
      computedPrice: computedPrice
    });
  };

  handleOnTypeChange = e => {
    this.setState(
      {
        selectedType: e.target.value
      },
      this.handleMultiplier
    );
  };

  render() {
    let { product_name, price, sku, description, price_per_case, product_image, types } = this.props.product;
    const { quantity, options, selectedType, computedPrice, isOrderable } = this.state;
    console.log('products', this.props.product);
    const { addingToCart, recoProducts, auth, handleModalClose, addToCartResult, addToCartResultMessage, disabledProducts, cookies, showModal } = this.props;

    const isLoggedIn = !isNil(auth) && 'isLoggedIn' in auth ? auth.isLoggedIn : false;

    console.log(recoProducts, 'test');
    console.log('RENDER', options[0].name);
    console.log('selectedType', selectedType);
    
    return (
      <>
        <Helmet>
          {product_name ? <title>{product_name} | SMB Delivers</title> : <title>SMB Delivers</title>}
          <meta name='description' content={`SMBDelivers.com offers delivery services of your favourite San Miguel Beer brands...`} />
        </Helmet>

        <ScrollMemory />
        <section className='app-hero -product'>
          {/* <DeliveryBanner cityName={cityName} barangayName={barangayName} handleClick={showModal}></DeliveryBanner> */}
          <div className='hero-notice'>
            <h5>NOTICE</h5>
            <p>
              Certain products are only available in certain areas <Link to='/delivery-areas'>Check Delivery Areas</Link>
            </p>
          </div>
          <div className='hero-bg-container normal'>
            <div
              className='hero-bg-image-container'
              // style={{
              //   backgroundImage: `url("${heroBGDesktop}")`
              // }}
            />
          </div>
        </section>
        <section className='single-product-cont'>
          <div className='bg' />
          <div className='centered-container'>
            <Row>
              <Col md={12} xs={24}>
                <div className='product-image-container -single-product'>
                  {product_image ? <img src={product_image} className='product-img' alt='SML' /> : <div className='image-not-available'>Product image not available</div>}
                </div>
                <ProductDesc
                  name={product_name}
                  type={`(${sku} ${types})`}
                  bulkPrice={numeral(price_per_case).format('0,0.00')}
                  price={numeral(price).format('0,0.00')}
                  desc={description}
                  types={types}
                  selectedType={selectedType}
                  computedPrice={numeral(computedPrice).format('0,0.00')}
                />
              </Col>
              <Col md={12} xs={24} className='product-desc-cont'>
              <ProductName
                  name={product_name}
                  type={`(${sku} ${types})`}
                  bulkPrice={numeral(price_per_case).format('0,0.00')}
                  price={numeral(price).format('0,0.00')}
                  desc={description}
                  types={types}
                  selectedType={selectedType}
                  computedPrice={numeral(computedPrice).format('0,0.00')}
                />
                <div className='product-options'>
                  {types !== 'Keg' && (
                    <div className='opt'>
                      <span className='label'>TYPE:</span>
                      <ProductToggle onChange={this.handleOnTypeChange} options={this.state.options} value={selectedType} />
                    </div>
                  )}
                  <div className='opt'>
                    <span className='label'>SIZE:</span>
                    <span className='size'>{sku}</span>
                  </div>
                  <div className='opt'>
                    <span className='label'>QTY:</span>
                    <Counter value={quantity} min={1} onChange={this.changeQuantity} />
                  </div>
                </div>
                {
                  !isOrderable ?
                  <Button className='cart-btn' disabled>
                    Out Of Stock
                  </Button>
                  :
                    <Button loading={addingToCart} onClick={this.handleAddToCart} className='cart-btn-single'>
                      <Icon type='plus' />
                      Add to Cart
                    </Button>  
                }
               
              </Col>
            </Row>
          </div>
        </section>

        {recoProducts.length > 0 ? <RecoProducts products={recoProducts} sectionTitle='YOU MIGHT ALSO LIKE' /> : null}

        <Modal
          visible={addToCartResult === 'FAIL'}
          onCancel={handleModalClose}
          title={addToCartResult === 'FAIL' ? 'Error' : ''}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <p>{`${addToCartResultMessage}`}</p>
          {Array.isArray(disabledProducts) && disabledProducts.length > 0 && (
            <ul>
              {disabledProducts.map((disabledProduct, index) => {
                return <li key={index}>{disabledProduct}</li>;
              })}
            </ul>
          )}
        </Modal>
      </>
    );
  }
}

export default SingleProductTPL;
