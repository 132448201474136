import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './AccountNavigation.scss';

const AccountNavigationTPL = props => {
  const { auth, accountNavigationIsVisible, toggleAccountNavigation, triggerLogout } = props;

  const { user } = auth;

  // console.log(user);
  console.log(auth);

  return (
    <>
      <div className={`account-navigation ${accountNavigationIsVisible ? '-active' : ''}`}>
        <div className="current-user-card">

          {auth.isLoggedIn && 'user' in auth && auth.user ? (
            <React.Fragment>
              {/* <h3 className="current-user-name">{`${user.firstname} ${user.lastname}`}</h3> -- HIDE IT */}
              {/* <p className="current-user-email">johndoe@gmail.com</p> */}
            </React.Fragment>
          ) : null}

          <div className="menu-toggle-container">
            <button className="menu-close-toggle" onClick={toggleAccountNavigation}>
              <span className="icon-plus" />
            </button>
          </div>
        </div>
        <nav>
          <ul className="account-nav-menu">
            {!auth.isLoggedIn ? (
              <React.Fragment>
                <li className="account-nav-menu-item">
                  <Link to="/login" className="account-nav-menu-link" onClick={toggleAccountNavigation}>
                    Log In
                  </Link>
                </li>
                <li className="account-nav-menu-item">
                  <Link to="/registration" className="account-nav-menu-link" onClick={toggleAccountNavigation}>
                    Create Account
                  </Link>
                </li>
              </React.Fragment>
            ) : null}
            {auth.isLoggedIn ? (
              <React.Fragment>
                <li className="account-nav-menu-item">
                  <Link to="/my-account" className="account-nav-menu-link" onClick={toggleAccountNavigation}>
                    My Account
                  </Link>
                </li>
                <li className="account-nav-menu-item">
                  <Link to="/my-account/orders" className="account-nav-menu-link" onClick={toggleAccountNavigation}>
                    My Orders
                  </Link>
                </li>
                <li className="account-nav-menu-item">
                  <Link to="/my-account/go-to-cases" className="account-nav-menu-link" onClick={toggleAccountNavigation}>
                    My Go-to Cases
                  </Link>
                </li>
                <li className="account-nav-menu-item">
                  <Link to="/my-account/addresses" className="account-nav-menu-link" onClick={toggleAccountNavigation}>
                    My Addresses
                  </Link>
                </li>
              </React.Fragment>
            ) : null}
            <li className="account-nav-menu-item">
              <Link to="/terms-and-conditions" className="account-nav-menu-link" onClick={toggleAccountNavigation}>
                Terms &amp; Conditions
              </Link>
            </li>
            <li className="account-nav-menu-item">
              <Link to="/privacy-policy" className="account-nav-menu-link" onClick={toggleAccountNavigation}>
                Privacy Policy
              </Link>
            </li>
          </ul>
        </nav>
        {auth.isLoggedIn ? (
          <div className="logout-trigger-container">
            <button className="logout-trigger" onClick={triggerLogout}>
              Log Out
            </button>
          </div>
        ) : null}
      </div>
      <div className={`overlay ${accountNavigationIsVisible ? '-active' : ''}`} onClick={toggleAccountNavigation} />
    </>
  );
};

export default AccountNavigationTPL;

AccountNavigationTPL.propTypes = {
  auth: PropTypes.object,
  triggerLogout: PropTypes.func,
  accountNavigationIsVisible: PropTypes.bool,
  toggleAccountNavigation: PropTypes.func
};
