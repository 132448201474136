import React from 'react';
import { Select, Input, Button } from 'antd';
import PropTypes from 'prop-types';
import './CartGoToCaseForm.scss';

const CartGoToCaseFormTPL = (props) => {
  const {
    isFetchingCompatibleCases,
    selectedCase,
    compatibleCases = [],
    handleCompatibleCaseSelection,
    handleNewCaseName,
    newCaseName,
    submitCartItemsToGoToCase
  } = props;
  const Option = Select.Option;

  console.log(compatibleCases);
  console.log(selectedCase);
  console.log(newCaseName);

  return (
    <div className="cart-go-to-case-form-container ">
      {
        !isFetchingCompatibleCases && compatibleCases.length > 0 ?
          <>
            <div className="existing-go-to-case-container">
              <Select
                className="go-to-case-selector"
                placeholder="Select compatible Go-to Case..."
                onChange={handleCompatibleCaseSelection}
                name="type"
                value={selectedCase}
              >
                {
                  compatibleCases.map((compatibleCase) => {
                    return (
                      <Option key={`${compatibleCase.id}`}>{`${compatibleCase['case_name']}`}</Option>
                    )
                  })
                }
                <Option key={`-1`}>Create A New Case</Option>
              </Select>
            </div>
          </>
        : null
      }

      {
        !isFetchingCompatibleCases &&
        (compatibleCases.length === 0 || selectedCase === '-1') ?
        <div className="cart-new-go-to-case">
          <Input
            onChange={handleNewCaseName}
            value={newCaseName}
            className=""
            placeholder={"New Go-to Case name..."}
          />
        </div>
        : null
      }

      <div className="submit-actions -cart-go-to-case">
        <Button
          onClick={submitCartItemsToGoToCase}
          disabled={(!selectedCase && newCaseName.length === 0 )||
                      (selectedCase === '-1' && newCaseName.length === 0) ||(compatibleCases.length === 0 && newCaseName.length === 0)}>Submit</Button>
      </div>
    </div>
  )
};

export default CartGoToCaseFormTPL;


CartGoToCaseFormTPL.propTypes = {
  selectedCase: PropTypes.string,
  isFetchingCompatibleCases: PropTypes.bool,
  handleCompatibleCaseSelection: PropTypes.func,
  handleNewCaseName: PropTypes.func,
  newCaseName: PropTypes.string,
  compatibleCases: PropTypes.array,
  submitCartItemsToGoToCase: PropTypes.func
};
