import React, { Fragment, useState, useEffect } from 'react';
import useHttp from 'hooks/use-http';
import { Modal, Button, Select } from 'antd';
import GradientBtn from 'Components/GradientBtn/GradientBtn';
import LocationIcon from 'Assets/fonts/icon/location-icon';
import ExistingAddressesModal from '../MyAccount/components/MyAddresses/ExistingAddressesModal.tpl';
import { getSelectedCategory } from 'Utils/commonUtils';
import CustomButton from '../Buttons/CustomButton';
import Loading from 'Components/Loading/Loading';

import './PickupGateModal.scss';

const {
  PUBLIC_URL,
  CATEGORY_GATE_ID_COOKIE_NAME,
  FULFILLMENTID_COOKIE_NAME,
  LOCATION_CITY_ID_COOKIE_NAME,
  LOCATION_BARANGAY_ID_COOKIE_NAME,
  LOCATION_STREET_ID_COOKIE_NAME,
  LOCATION_BARANGAY_NAME_COOKIE_NAME,
  LOCATION_CITY_NAME_COOKIE_NAME
} = process.env;

const { Option } = Select;
let findCity, findBarangay;

const PickupGateModal = React.memo(props => {
  const { cookies, isLoggedIn } = props;
  const { selectedCategoryGateName } = getSelectedCategory(cookies);
  const [cityId, setCityId] = useState(null);
  const [fulfillments, setFulfillments] = useState([]);
  const [fulfillmentId, setFulfillmentId] = useState(null);
  const [barangayId, setBarangayId] = useState(null);
  const [barangays, setBarangays] = useState([]);
  const [locationName, setLocationName] = useState('');
  const [streetId, setStreetId] = useState(null);
  const [hasStreets, setHasStreets] = useState(false);
  const { loading, error, sendRequest } = useHttp();
  
  const isKeg = selectedCategoryGateName === 'Keg';
  
  const [isKegCategory, setIsKegCategory] = useState(isKeg);
  useEffect(() => {
    const callback = data => {
      const fulfillmentCount = data.results.length;
      if (fulfillmentCount === 1) {
        let fid = data.results[0].id;
        setFulfillmentId(fid);
        cookies.set(FULFILLMENTID_COOKIE_NAME, fid);
      }
      setFulfillments(data.results);
    };

    // http://v3smb.nuworks.ph/api/v3/fulfillment-types
    sendRequest(
      {
        url: `${PUBLIC_URL}/api/v3/fulfillment-types`
      },
      callback
    );
  }, []);

  const fulfillmentHandler = fulfillmentId => {
    setFulfillmentId(fulfillmentId);
  };
  const hasFulfillments = fulfillments.length > 0;

  return (
    <Fragment>
      <Modal
        className="location-modal"
        onCancel={props.onCancel}
        visible={props.isVisible}
        footer={null}
        closable={true}
      >
        <BottlesAndCansComponent
          fulfillments={fulfillments}
          hasFulfillments={hasFulfillments}
          cookies={cookies}
          barangays={barangays}
          fulfillmentHandler={fulfillmentHandler}
          fulfillmentId={fulfillmentId}
          setBarangays={setBarangays}
          cityId={cityId}
          setCityId={setCityId}
          barangayId={barangayId}
          setStreetId={setStreetId}
          streetId={streetId}
          setHasStreets={setHasStreets}
          hasStreets={hasStreets}
          props={props}
          setBarangayId={setBarangayId}
          setLocationName={setLocationName}
          locationName={locationName}
          isKegCategory={isKegCategory}
          isLoggedIn={isLoggedIn}
        />
      </Modal>
    </Fragment>
  );
});

const BottlesAndCansComponent = ({
  fulfillments,
  hasFulfillments,
  cookies,
  barangays,
  fulfillmentHandler,
  fulfillmentId,
  setBarangays,
  cityId,
  setCityId,
  barangayId,
  setStreetId,
  streetId,
  setHasStreets,
  hasStreets,
  props,
  setBarangayId,
  setLocationName,
  locationName,
  isKegCategory,
  isLoggedIn
}) => {
  
  const [cities, setCities] = useState([]);

  return (
    <Fragment>
      <span className="icon-location" />
      <div className="location-gate-description">
        {/* <div>Please select if you would like </div>
        <div>delivery or pickup for this order</div> */}
        <div>Please select from option below</div>
      </div>
      {/* Note: we disabled the service option selection because this will be implemented in the next phase.
        Uncomment this code to make but don't remove this here because this is already functional
    */}
      {hasFulfillments && fulfillments.length > 1 && (
        <ServiceOption
          fulfillments={fulfillments}
          fulfillmentHandler={fulfillmentHandler}
        />
      )}

      <section className="container">
        <section className="container__items">
          <ProvinceListComponent fulfillmentId={fulfillmentId} setCities={setCities}/>

          <CityListComponent
            cookies={cookies}
            fulfillmentId={fulfillmentId}
            setBarangays={setBarangays}
            cityId={cityId}
            setCityId={setCityId}
            isKegCategory={isKegCategory}
            isLoggedIn={isLoggedIn}
            cities={cities}
            setCities={setCities}
          />
          <BarangayListComponent
            cookies={cookies}
            barangays={barangays}
            barangayId={barangayId}
            setBarangayId={setBarangayId}
            setLocationName={setLocationName}
            setStreetId={setStreetId}
            setHasStreets={setHasStreets}
          />
        </section>
        <ContinueAction
          cookies={cookies}
          props={props}
          fulfillmentId={fulfillmentId}
          cityId={cityId}
          streetId={streetId}
          hasStreets={hasStreets}
          barangayId={barangayId}
          locationName={locationName}
        />
      </section>
    </Fragment>
  );
};

const ServiceOption = ({ fulfillments, fulfillmentHandler }) => {
  return (
    <Fragment>
      <div id="service-option">
        {fulfillments.map(fulfillment => (
          <GradientBtn
            key={fulfillment.id}
            onClick={fulfillmentHandler.bind(null, fulfillment.id)}
            className="fulfillment-name"
          >
            <span style={{ padding: 7, width: '108px' }}>
              {fulfillment.name}
            </span>
          </GradientBtn>
        ))}
      </div>
    </Fragment>
  );
};

const ProvinceListComponent = ({
  fulfillmentId,
  setCities
}) => {
  const [provinces, setProvinces] = useState([]);

  const { sendRequest } = useHttp();

  useEffect(() => {
    const callback = data => {
      console.log('provinces', data);
      setProvinces(data);
    };

    sendRequest(
      {
        url: `${PUBLIC_URL}/api/v1/provinces`
      },
      callback
    );
  }, []);


  const onChangeProvinceHandler = province_id => {
    console.log('ProvinceID: ', province_id);
    const callback = data => {
      setCities(data.results.locations);
    };
    sendRequest({
      url: `${PUBLIC_URL}/api/v3/locations/provinces/${province_id}/fulfillments/${fulfillmentId}/cities`
    }, callback);
  };
  
  const hasProvinces = provinces && provinces.length > 0;

  return (
    <Fragment>
      
      
        <div>
          <span>Select your province:</span>
        </div>
        {!hasProvinces && (
          <Select defaultValue="Select a province" className="select-city">
            <Option value="">No Province available</Option>
          </Select>
        )}
        {hasProvinces && (
          <Select
            defaultValue="Select a province"
            onChange={onChangeProvinceHandler}
            className="select-city"
          >
            {hasProvinces &&
              provinces.map(province => (
                <Option key={province.id} value={province.id}>
                  {province.province_name}
                </Option>
              ))}
          </Select>
        )}
        <div className="delivery__note">
          <span className="delivery__note--text">
            Note: Delivery hours and coldness of the beer
          </span>
          <span className="delivery__note--text">may vary per location.</span>
        </div>
        
    </Fragment>
  );
};

const CityListComponent = ({
  cookies,
  fulfillmentId,
  setBarangays,
  cityId,
  setCityId,
  isKegCategory,
  isLoggedIn,
  cities,
  setCities
}) => {
  // const [cities, setCities] = useState([]);
  const { sendRequest } = useHttp();
  const [showAddressListModal, setShowAddressListModal] = useState(false);
  const [customerExistingAddresses, setCustomerExistingAddresses] = useState(
    {}
  );

  useEffect(() => {
    if (fulfillmentId !== null && !isKegCategory) {
      // setCities([]);
      // setCityId(null);
      // const callback = data => {
      //   cookies.set(FULFILLMENTID_COOKIE_NAME, fulfillmentId);

      //   setCities(data.results.locations);
      //   setBarangays([]);
      // };

      // sendRequest(
      //   {
      //     url: `${PUBLIC_URL}/api/v3/fulfillment-types/${fulfillmentId}/cities`
      //   },
      //   callback
      // );
    } else if (isKegCategory) {
      // if category is keg, we pull all locations(not basing it on fulfillment type)
      const callback = data => {
        setCities(data.results);
        setBarangays([]);
      };
      sendRequest(
        {
          url: `${PUBLIC_URL}/api/v3/locations/cities`,
          method: 'POST'
        },
        callback
      );
    }
  }, [fulfillmentId, isKegCategory]);

  useEffect(() => {
    if (cityId !== null) {
      const callback = data => {
        setBarangays(data.results);
      };

      sendRequest(
        {
          url: `${PUBLIC_URL}/api/v3/locations/city/${cityId}/barangays`
        },
        callback
      );
    }
  }, [cityId]);

  // Get addresses of logged in user
  useEffect(() => {
    if (isLoggedIn) {
      const callback = data => {
        setCustomerExistingAddresses(data.results);
      };

      sendRequest(
        {
          url: `${PUBLIC_URL}/api/v1/user/addresses/list`
        },
        callback
      );
    }
  }, [isLoggedIn]);

  const onChangeCityHandler = cityId => {
    findCity = cities.find(c => c.city_id === cityId);
    const cityProvinceName = findCity.city_province;
    addCityIdAndNameToCookie({ cityId, cityProvinceName });
  };

  const existingAddressModalOnOkHandler = selectedAddress => {
    // push existing address to cookie
    const cityProvinceName = `${selectedAddress.city_name} ${
      selectedAddress.province_name
    }`;
    const cityId = selectedAddress.city_id;
    addCityIdAndNameToCookie({ cityId, cityProvinceName });
  };

  const addCityIdAndNameToCookie = ({ cityId, cityProvinceName }) => {
    cookies.set(LOCATION_CITY_ID_COOKIE_NAME, cityId);
    cookies.set(LOCATION_CITY_NAME_COOKIE_NAME, cityProvinceName);
    setCityId(cityId);
  };

  const useExistingAddressHandler = () => {
    setShowAddressListModal(!showAddressListModal);
  };

  const onClickHandlerUseSaveAddress = () => {
    setShowAddressListModal(true);
  };

  const hasCities = cities && cities.length > 0;

  return (
    <Fragment>
      <ExistingAddressesModal
        visible={showAddressListModal}
        customerExistingAddresses={customerExistingAddresses}
        existingAddressModalOnOkHandler={existingAddressModalOnOkHandler}
        onCancel={useExistingAddressHandler}
      />
      <div>
        <div>
          <span>Select your city:</span>
          {/* {isLoggedIn && (
            <CustomButton
              onClickHandler={onClickHandlerUseSaveAddress}
              buttonText="Use saved address"
            />
          )} */}
        </div>
        {!hasCities && (
          <Select defaultValue="Select a city" className="select-city">
            <Option value="">No Cities available</Option>
          </Select>
        )}
        {hasCities && (
          <Select
            defaultValue="Select a city"
            onChange={onChangeCityHandler}
            className="select-city"
          >
            {hasCities &&
              cities.map(city => (
                <Option key={city.city_id} value={city.city_id}>
                  {city.city_province}
                </Option>
              ))}
          </Select>
        )}
      </div>
    </Fragment>
  );
};

const BarangayListComponent = ({
  cookies,
  barangays,
  barangayId,
  setBarangayId,
  setLocationName,
  setStreetId,
  setHasStreets
}) => {

  const { sendRequest } = useHttp();
  const [streets, setStreets] = useState([]);
  const onChangeBarangayHandler = barangayId => {
    cookies.set(LOCATION_BARANGAY_ID_COOKIE_NAME, barangayId);
    findBarangay = barangays.find(b => b.id == barangayId);
    cookies.set(LOCATION_BARANGAY_NAME_COOKIE_NAME, findBarangay.barangay_name);
    const locName = findBarangay.barangay_name + ' ' + findCity.city_province;
    setLocationName(locName);
    setBarangayId(barangayId);
  };

  
  const onChangeStreetHandler = streetId => {
    setStreetId(streetId);
    cookies.set(LOCATION_STREET_ID_COOKIE_NAME, streetId);
  };

  useEffect(() => {

    if (barangayId) {
      const callback = data => {
        // setCustomerExistingAddresses(data.results);
        console.log('Streets Data', data);
        setStreets(data.results);
      };
  
      sendRequest(
        {
          url: `${PUBLIC_URL}/api/v3/locations/barangay/${barangayId}/streets`
        },
        callback
      );
    }
  }, [barangayId]);

  const hasBarangay = barangays && barangays.length > 0;
  const hasStreets = streets && streets.length > 0;
  setHasStreets(hasStreets);
  
  return (
    <Fragment>
      <div>
        <div>
          <span>Select your barangay:</span>
        </div>
        {barangays && barangays.length <= 0 && (
          <Select
            defaultValue="Select a barangay"
            className="select-barangay"
            onChange={onChangeBarangayHandler}
          >
            <Option value="">No barangay available</Option>
          </Select>
        )}
        {hasBarangay && (
          <Select
            defaultValue="Select a barangay"
            className="select-barangay"
            onChange={onChangeBarangayHandler}
          >
            {hasBarangay &&
              barangays.map(barangay => (
                <Option key={barangay.id} value={barangay.id}>
                  {barangay.barangay_name}
                </Option>
              ))}
          </Select>
        )}
        {/* <div className="delivery-note">
          Note: Delivery hours and coldness of the beer may vary per location.
        </div> */}
      </div>
      {hasStreets && <div>
        <div>
          <span>Select your street:</span>
        </div>
        <Select
            defaultValue="Select a street"
            className="select-barangay"
            onChange={onChangeStreetHandler}
          >
            {streets.map(street => (
              <Option key={street.id} value={street.id}>
                {street.street_name}
              </Option>
            ))}
            
          </Select>
        
      </div>}
      <div className="delivery__note">
          <span className="delivery__note--text">
            Please note that we are only able to deliver certain.
          </span>
          <span className="delivery__note--text">
            products in certain areas.
          </span>
        </div>
    </Fragment>
  );
};
const ContinueAction = ({
  props,
  fulfillmentId,
  cityId,
  streetId,
  hasStreets,
  barangayId,
  locationName = ''
}) => {
  const handleLocationGateContinue = () => {
    if (cityId && barangayId) {
      if (hasStreets && streetId) {
        props.handleLocationGateContinue(locationName);
      } else if (!hasStreets) {
        props.handleLocationGateContinue(locationName);
      }
    }
  };

  return (
    <Fragment>
      <div id="continue-button">
        <GradientBtn onClick={handleLocationGateContinue} className="continue">
          <span className="continue-button" style={{ margin: '20px 0' }}>
            Continue
          </span>
        </GradientBtn>
      </div>
    </Fragment>
  );
};
export default PickupGateModal;
