import smbService from './sessionInterceptor';
import { useCookies } from 'react-cookie';
import { isNil, get } from 'lodash';

import DummyProductData from '../data/products.json';

const { 
  PUBLIC_URL
 } = process.env;


export const getSingleProduct = ({productSlug, barangay}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const v2APIRoot = `${PUBLIC_URL}/api/v2`;
      const query = `${v2APIRoot}/products/${productSlug}/barangay/${barangay}`;
      
      let result = await smbService.get(query);

      resolve(result.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const getProducts = filters => {
  
  return new Promise(async (resolve, reject) => {
    try {
      const v2APIRoot = `${PUBLIC_URL}/api/v1`;
      const query =
        `${filters}`.indexOf('?') === 0
          ? `${v2APIRoot}/products${filters}`
          : `${v2APIRoot}/products`;
      // const query = `/products?${filters.replace('?', '')}`;
      // console.log('query', query);
      // console.log('filters', filters);
      let result = await smbService.get(query);

      // result.data = DummyProductData;
      // console.log('RESULTS DATA', DummyProductData); -- uncomment this to use dummy product data (data synced: 2022-09-19)

      resolve(result.data);
    } catch (e) {
      reject(e);
    }
  });
};


export const getPromoProducts = filters => {
  
  return new Promise(async (resolve, reject) => {
    try {
      
      const v2APIRoot = `${PUBLIC_URL}/api/v1/products/promos`;
      const query =
        `${filters}`.indexOf('?') === 0
          ? `${v2APIRoot}/${filters}`
          : `${v2APIRoot}/`;
      // const query = `/products?${filters.replace('?', '')}`;
      console.log('promo product query', query);
      let result = await smbService.get(query);

      resolve(result.data);
    } catch (e) {
      reject(e);
    }
  });
};
