import React, { useState } from "react";
import { Modal, Button } from "antd";
import GradientBtn from "Components/GradientBtn/GradientBtn";

import './CookiePrompt.scss'

const CookiePrompt = React.memo((props) => {
    const [visible, setVisible] = useState(null);

  return (
    <div className="cookie-prompt">
      <p>Our use of cookies and other similar technologies provide site
        functionality, analyze traffic and usage, and deliver content tailored
        to your interests. Please click "I Agree" to accept the
        <a href="/privacy-policy"> cookie policy </a>
        and continue the use of this website.
      </p>
      <div id="accept-cookie" className="cookie-button">
        <GradientBtn type="primary"><span className="agree">I AGREE</span></GradientBtn>
      </div>
    </div>
  );
});

export default CookiePrompt;