import React, { Component, Fragment, lazy, Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import Home from 'Scenes/Home/Home';
import SingleProduct from 'Scenes/SingleProduct/SingleProduct';
import DeleteAccount from 'Scenes/DeleteAccount/DeleteAccount';
import Maintenance from 'Scenes/Maintenance/Maintenance';
const Login = lazy(() => import('Scenes/Login/Login'));
const Browse = lazy(() => import('Scenes/Browse/Browse'));
const ActiveOrder = lazy(() => import('Scenes/ActiveOrder/ActiveOrder'));
const MyAccount = lazy(() => import('Scenes/MyAccount/MyAccount'));
const AtomicDesign = lazy(() => import('Scenes/AtomicDesign/AtomicDesign'));
const Registration = lazy(() => import('Scenes/Registration/Registration'));
const Cart = lazy(() => import('Scenes/Cart/Cart'));
const Checkout = lazy(() => import('Scenes/Checkout/Checkout'));
const Delivery = lazy(() => import('Scenes/Delivery/Delivery'));
const ErrorPage = lazy(() => import('Scenes/ErrorPage/ErrorPage'));
const Splash = lazy(() => import('Scenes/Splash/Splash'));
const ResetPassword = lazy(() => import('Scenes/ResetPassword/ResetPassword'));
const SetAddress = lazy(() => import('Scenes/Registration/Steps/SetAddress/SetAddress'));
const ChangePassword = lazy(() => import('Scenes/ChangePassword/ChangePassword'));
const ContactUs = lazy(() => import('Scenes/ContactUs/ContactUs'));
const TermsConditions = lazy(() => import('Scenes/TermsConditions/TermsConditions'));
const AboutUs = lazy(() => import('Scenes/AboutUs/AboutUs'));
const PrivacyPolicy = lazy(() => import('Scenes/PrivacyPolicy/PrivacyPolicy'));
const Faqs = lazy(() => import('Scenes/Faqs/Faqs'));
const HowToOrder = lazy(() => import('Scenes/HowToOrder/HowToOrder'));
const Promos = lazy(() => import('Scenes/Promos/Promos'));
const WhatsNew = lazy(() => import('Scenes/WhatsNew/WhatsNew'));


const routes = [
  {
    path: '/maintenance',
    component: Maintenance,
    exact: false
  },
  {
    path: '/how-to-order',
    component: HowToOrder,
    exact: false
  },
  {
    path: '/faqs',
    component: Faqs,
    exact: false
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    exact: false
  },
  {
    path: '/terms-and-conditions',
    component: TermsConditions,
    exact: false
  },

  {
    path: '/about-us',
    component: AboutUs,
    exact: false
  },

  {
    path: '/contact-us',
    component: ContactUs,
    exact: false
  },
  {
    path: '/active-order/:id',
    component: ActiveOrder,
    exact: false
  },
  {
    path: '/reset-password',
    component: ChangePassword,
    exact: false
  },
  {
    path: '/change-password',
    component: ResetPassword,
    exact: false
  },
  {
    path: '/splash',
    exact: false,
    component: Splash
  },
  {
    path: '/delivery-areas',
    exact: false,
    component: Delivery
  },
  {
    path: '/cart',
    exact: false,
    component: Cart
  },
  {
    path: '/checkout',
    exact: false,
    component: Checkout
  },
  {
    path: '/login',
    exact: false,
    component: Login,
    guestOnly: true
  },
  {
    path: '/buy-beer',
    exact: false,
    component: Browse
  },
  {
    path: '/promos',
    exact: false,
    component: Promos
  },
  {
    path: '/whats-new',
    exact: false,
    component: WhatsNew
  },
  {
    path: '/single-product/:slug',
    exact: false,
    component: SingleProduct
  },
  {
    path: '/single-product',
    exact: false,
    component: () => (
      <Redirect
        to={{
          pathname: '/'
        }}
      />
    )
  },
  {
    path: '/my-account/delete-account',
    exact: false,
    component: DeleteAccount
  },
  {
    path: '/my-account',
    exact: false,
    component: MyAccount,
    auth: true
  },
  {
    path: '/atomic-design',
    exact: true,
    component: AtomicDesign
  },
  {
    path: '/registration',
    exact: false,
    component: Registration,
    guestOnly: true
  },
  {
    path: '/setup-address',
    exact: false,
    component: SetAddress,
    auth: true
  },
  {
    path: '/',
    exact: true,
    component: Home
  },
  {
    path: '*',
    exact: false,
    component: ErrorPage
  }
];

export default routes;
