import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import './GradientBtn.scss';

class GradientBtn extends PureComponent {

    render() {
        let { href, children, className, block, onClick, type, name, value, disabled } = this.props;
        const classes = className ? className : '';
        const display = block ? ' -block' : '';
        return (
          <React.Fragment>
          {
            href ?
            <Link to={href} onClick={onClick} className={'gradient-btn ' + classes + display}>
              <span className="white-bg">{children}</span>
            </Link>
            :
            <button
              disabled={disabled}
              onClick={onClick}
              type={type}
              name={name}
              value={value}
              className={'gradient-btn ' + classes + display}>
              <span className="white-bg">{children}</span>
            </button>
          }
          </React.Fragment>
        );
    }
}

GradientBtn.defaultProps = {
    block: false
};

export default GradientBtn;
