import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Modal, Col, Row } from 'antd';
import './Cart.scss';
import GradientBtn from 'Components/GradientBtn/GradientBtn';
import { Link } from 'react-router-dom';
import CartCase from './components/CartCase/CartCase';
import CartSummary from 'Components/CartSummary/CartSummary';
import { printStandardDecimals } from 'Utils/cartUtils';
import RecoProducts from 'Components/RecoProducts/RecoProducts';
import ErrorMessageTPL from 'Components/ErrorMessage/ErrorMessage.tpl';
import { isHolidayToday, holidays } from 'Utils/holidayUtils';
import HolidayContent from 'Components/HolidayContent/HolidayContent';
import OperatingTimeContent from "Components/OperatingTimeContent/OperatingTimeContent";

import DummyCart from '../../data/cart.json';

class CartTPL extends Component {
  handleAddToCart = (productId, quantity) => {
    return () => {
      let { addToCart } = this.props;
      addToCart(productId, quantity);
    };
  };

  render() {
    const {
      errorModal,
      errorMessage,
      errorItems,
      forceHideSummary = false,
      isViewingSummaryInMobile,
      isAttachedInSidebar,
      cart = {},
      toggleSummaryInMobile,
      handleSwitchToCart,
      showTotal = false,
      onBar = false,
      auth
    } = this.props;

    // console.log('----->', DummyCart);
    const cartItems = cart['shopping_cart']; // for live data
    // const cartItems = DummyCart.shopping_cart; // for testing

    const cartSummary = cart['cart_order_summary']; // cart['cart_order_summary'] | DummyCart.cart_order_summary;
    //console.log('cartSummary', cartSummary);

    const cartContainerClass = isViewingSummaryInMobile ? 'products-list-cont -summary' : 'products-list-cont';
    const cartReco = cart['recommended_products'] ; // cart['recommended_products'] | DummyCart.recommended_products;
    const isLoggedIn = auth.isLoggedIn || false;
    let classes = 'cart-content';

    isAttachedInSidebar ? (classes += ' side-bar-open') : (classes = 'cart-content');
    
    return (
      <section className={'cart-content ' + classes}>
        {Array.isArray(cartItems) && cartItems.length > 0 ? (
          <React.Fragment>
            {/* This is mobile only */}
            <section className="cart-sub-heading">
              <div className="centered-container">
                {!forceHideSummary && (
                  <div className="cart-mobile-sub-heading">
                    <div className="left-info-col -cart-mobile-sub-heading">
                      <div className="total-info">
                        <span className="label">Total:</span>
                        <span className="cart-total">P {printStandardDecimals(cartSummary.subtotal)}</span>
                      </div>
                    </div>
                    <div className="cart-nav-col">
                      {isViewingSummaryInMobile ? (
                        <button onClick={toggleSummaryInMobile} className="cart-sub-nav-action -back">
                          <span className="button-label">Back to Cart</span> <span className="icon-next" />
                        </button>
                      ) : (
                        <button onClick={toggleSummaryInMobile} className="cart-sub-nav-action -order-summary">
                          <span className="button-label">Order Summary</span>
                          <span className="icon-next" />
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </section>

            <div className="cart-main-content">
              <div className="centered-container">
                <Row className={cartContainerClass} type="flex" justify="space-between">
                  <Col lg={forceHideSummary ? 24 : 17} sm={24} xs={24}>
                    <div className="cart-items-col">
                      <section className="cart-groups">
                        {// Case groups
                        cartItems.map((cartCase, index) => {
                          return <CartCase forceHideSummary={forceHideSummary} key={index} cartCase={cartCase} />;
                        })}
                      </section>

                      {!forceHideSummary && (
                        <section className="cart-subtotal-container">
                          <div className="subtotal-row">
                            <span className="label-text">Subtotal</span>
                            <span className="subtotal-figure">P {printStandardDecimals(cartSummary.subtotal)}</span>
                          </div>
                          <div className="review-trigger-container">

                            {/* <HolidayContent
                              timeBoundHoliday
                              useLocaltime>
                              <GradientBtn
                                className="review-trigger"
                                onClick={handleSwitchToCart}
                                disabled
                                block>
                                Proceed To Checkout
                              </GradientBtn>
                            </HolidayContent>

                            <HolidayContent
                              timeBoundHoliday
                              useLocaltime
                              showWhenNotHoliday>
                              <GradientBtn
                                className="review-trigger"
                                onClick={handleSwitchToCart}
                                block>
                                Proceed To Checkout
                              </GradientBtn>
                            </HolidayContent> */}

                            {/* COVID SHIFT */}

                            <OperatingTimeContent>
                              <GradientBtn  className="review-trigger" onClick={handleSwitchToCart} block >Proceed To Checkout</GradientBtn>
                            </OperatingTimeContent>

                            <OperatingTimeContent showWhenNotOperating>
                              <GradientBtn  className="review-trigger" onClick={handleSwitchToCart} block disabled>Proceed To Checkout</GradientBtn>
                            </OperatingTimeContent>

                              {/* <GradientBtn
                                className="review-trigger"
                                onClick={handleSwitchToCart}
                                disabled
                                block>
                                Proceed To Checkout
                              </GradientBtn> */}


                            <div className="tax-info-container">
                              {/* <p className="tax-info">*Tax &amp; deposits will be calculated at checkout</p> */}
                              <p className="tax-info tax-info__mobile">*Deposits will be calculated at checkout</p>
                            </div>
                            <Link to="/buy-beer" className="ant-btn continue-trigger -transparent -block">
                              Continue Shopping
                            </Link>
                          </div>
                        </section>
                      )}

                      {showTotal && !isAttachedInSidebar && (
                        <section className="cart-subtotal-container -sidebar">
                          <div className="subtotal-row">
                            <span className="label-text">Subtotal</span>
                            <span className="subtotal-figure">P {printStandardDecimals(cartSummary.subtotal)}</span>
                          </div>
                        </section>
                      )}
                    </div>
                  </Col>
                  {!forceHideSummary ? (
                    <Col lg={7} sm={24} xs={24}>
                      <CartSummary handleSwitchToCart={handleSwitchToCart} cart={cart} cartSummary={cartSummary} />
                    </Col>
                  ) : null}
                </Row>
              </div>
            </div>

            {isLoggedIn && cartReco.length > 0 && onBar === false && !forceHideSummary ? (
              <RecoProducts products={cartReco} sectionTitle="Recommended For You" shouldLinkToProducts />
            ) : null}
          </React.Fragment>
        ) : (
          <div className="empty-cart-message-container">
            <p className="text">There are no items in your cart. <Link className="empty-cart-link" to="/buy-beer">Buy beer?</Link></p>
          </div>
        )}
        <Modal title="Error"
              visible={errorModal.isVisible}
              onOk={errorModal.onConfirm}
              onCancel={errorModal.onConfirm}
              cancelButtonProps={{ style: { display: 'none' } }}>
          <ErrorMessageTPL error={{
            message: errorMessage,
            errorItems: errorItems,
            fallbackMessage: 'For you to proceed to checkout, you need to complete filling up your cases.'
          }} />
        </Modal>
      </section>
    );
  }
}

CartTPL.defaultProps = {
  cart: []
};

CartTPL.propTypes = {
  errorModal: PropTypes.shape({
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    isVisible: PropTypes.bool
  }),
  errorMessage: PropTypes.string,
  errorItems: PropTypes.array,
  auth: PropTypes.object,
  isMobileView: PropTypes.bool,
  isViewingSummaryInMobile: PropTypes.bool,
  isAttachedInSidebar: PropTypes.bool,
  forceHideSummary: PropTypes.bool,
  toggleSummaryInMobile: PropTypes.func,
  handleSwitchToCart: PropTypes.func,
  showTotal: PropTypes.bool
};

export default CartTPL;
