import React, { useState, useEffect } from "react";
import axios from "axios";
import Input from "antd";

const TypeAhead = React.memo(() => {
  const [cities, setCities] = useState([]);
  const [cityMatch, setCityMatch] = useState([]);

  useEffect(() => {
    const loadCities = async () => {
      const response = await axios.get("");
      setCities(response.data);
    };

    loadCities();
  }, []);

  console.log(cities);

  const searchCity = text => {
    let matches = cities.filter(city => {
      const regex = new RegExp(`${text}`);
      return city.name.match(regex);
    });
    setCityMatch(matches);
  };

  return (
    <div className="type-ahead-container">
      <form>
        <label>Select your current city:</label>
        <input
          placeholder="Select a city"
          onChange={e => searchCity(e.target.value)}
        />
        {cityMatch &&
          cityMatch.map((item, index) => <div key={index}>{item.name}</div>)}
      </form>
    </div>
  );
});

export default TypeAhead;
