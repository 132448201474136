import { getPackages } from "Services/packageServices.js";

export const actionTypes = {
  UPDATE_PACKAGES: "UPDATE_PACKAGES",
  UPDATE_PACKAGE_TYPES: "UPDATE_PACKAGE_TYPES",
  UPDATE_PACKAGE_BRANDS: "UPDATE_PACKAGE_BRANDS",
  UPDATE_PACKAGE_LOADING: "UPDATE_PACKAGE_LOADING",
  UPDATE_RECOMMENDED_PACKAGES: "UPDATE_RECOMMENDED_PACKAGES"
};

export const fetchPackages = filters => {
  return async (dispatch, getState) => {
    try {
      const { types, brands } = getState().packages;

      dispatch({
        type: actionTypes.UPDATE_PACKAGE_LOADING,
        payload: true
      });

      const results = await getPackages(filters);

      dispatch({
        type: actionTypes.UPDATE_PACKAGES,
        payload: results.packages
      });

      dispatch({
        type: actionTypes.UPDATE_RECOMMENDED_PACKAGES,
        payload: results.recommended_packages
      });

      if (types.length == 0) {
        dispatch({
          type: actionTypes.UPDATE_PACKAGE_TYPES,
          payload: results.filter_types
        });
      }

      if (brands.length == 0) {
        dispatch({
          type: actionTypes.UPDATE_PACKAGE_BRANDS,
          payload: results.brands
        });
      }
    } catch (e) {
      console.error(e);
      throw e;

      return Promise.reject(false);
    } finally {
      dispatch({
        type: actionTypes.UPDATE_PACKAGE_LOADING,
        payload: false
      });
    }

    return Promise.resolve(true);
  };
};
