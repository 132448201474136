import Cookies from 'js-cookie';
const { TOKEN_EXPIRATION_DURATION } = process.env;

export const getToken = () => {

  try {
    // const token =  JSON.parse(Cookies.get('smbd-token'));
    const token =  JSON.parse(localStorage.getItem('token'));

    // console.log(token);

    return token ? token.token : false;
  } catch(error) {
    return false;
  }
}

export const setToken = (token) => {
    const data = {token, date: (new Date().toString())}
    // Cookies.set('smbd-token', JSON.stringify(data), { secure: true });
    localStorage.setItem('token', JSON.stringify(data));

}

export const removeToken = () => {
    localStorage.removeItem('token')
    // Cookies.remove('smbd-token');
}

export const isTokentExpired = () => {
  const {date} = JSON.parse(Cookies.get('smbd-token'));
  let tokenDate = new Date(date);
  let now = new Date();
  const tokenDurationInMilliseconds = TOKEN_EXPIRATION_DURATION * 60000

  if((now - tokenDate) >= tokenDurationInMilliseconds){
      return true
  }

  return false
}

export const updateTokenExpiration = () => {
  const {token} = JSON.parse(Cookies.get('smbd-token'));
  const data = {token, date: (new Date().toString())}
  localStorage.setItem('token', JSON.stringify(data))
}
