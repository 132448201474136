import React from 'react';
import './Loading.scss';

const Loading = props => {
  const { visible, message } = props;
  const classes = visible ? '-active' : '';

  return (
    <div className={'loading-cont ' + classes}>
      <div className="loader-box">
        <div className="loader">
          <div />
          <div />
          <div />
          <div />
        </div>
        {
          message &&
          <p className="message">{`${message}`}</p>
        }
      </div>
    </div>
  );
};

Loading.defaultProps = {
  visible: true
};

export default Loading;
