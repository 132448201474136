import React, {Component, PureComponent} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CartItemsTPL from './CartItems.tpl';
import cartActions from 'Redux/cart/actions';

class CartItems extends Component {
  render() {
    return (<CartItemsTPL {...this.props} />)
  }
};


CartItems.propTypes = {
  items: PropTypes.array,
  updateReduxCart: PropTypes.func,
  deleteCartItem: PropTypes.func
};

const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateReduxCart: () => {
      dispatch(cartActions.fetchCart())
    },
    deleteCartItem: (items) => {
      dispatch(cartActions.deleteCartItems(items))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CartItems);
