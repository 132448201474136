import React from 'react';
import './ProductDesc.scss';
import { isNil } from 'lodash';
import { createMarkup } from 'Utils/stringUtils';

const ProductName = ({ name, type, types, bulkPrice, price, desc, selectedType, computedPrice }) => {
  console.log(types);

  return (
    <div className="product-desc">
      <h2 className="product-title">{name}</h2>
      <h2 className="product-type">{type}</h2>
      <h2 className="product-price -bulk">P{computedPrice}</h2>
      {types === 'Cans' && <p className="product-price">P{price} per can</p>}
    </div>
  );
};

export default ProductName;
