import React from 'react';
import { Modal } from 'antd';
import Banner from 'Components/Banner/Banner';
import Product from 'Components/Product/Product';
import Slider from 'react-slick';
import GradientBtn from 'Components/GradientBtn/GradientBtn';
import PropTypes from 'prop-types';
import ProductImg from 'Assets/images/products/sml.png';
import './RecoProducts.scss';

const RecoProductsTPL = props => {
  const { products,
          sectionTitle,
          handleAddToCart,
          shouldLinkToProducts = false,
          handleModalClose,
          addToCartResult,
          addToCartResultMessage,
          disabledProducts
        } = props;
  const carouselSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className="reco-products">
      <div className="centered-container">
        {products.length > 0 && (
          <div className="reco-products-container">
            <Banner title={sectionTitle} />
            {/* <Row className="products-list-cont" type="flex" justify="space-between">
            {products.map((recoProduct, index) => (
              <Col md={8} xs={24}>
                <Product key={index} addCart={handleAddToCart} product={recoProduct} image={ProductImg} />
              </Col>
            ))}
          </Row> */}
            <Slider {...carouselSettings} className="products-list-cont">
              {products.map((recoProduct, index) => (
                <Product key={index} addCart={handleAddToCart} product={recoProduct} image={ProductImg} />
              ))}
            </Slider>
            {shouldLinkToProducts && (
              <GradientBtn href="/buy-beer" className="view-product-btn">
                {' '}
                View all Products{' '}
              </GradientBtn>
            )}
          </div>
        )}
      </div>


      <Modal
          visible={addToCartResult === 'FAIL'}
          onCancel={handleModalClose}
          title={addToCartResult === 'FAIL' ? 'Error' : ''}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          >
            <p>{`${addToCartResultMessage}`}</p>
            {
              (Array.isArray(disabledProducts) && disabledProducts.length > 0) &&
                (
                  <ul>
                    {disabledProducts.map((disabledProduct, index) => {
                      return (
                        <li key={index}>{disabledProduct}</li>
                      )
                    })}
                  </ul>
                )
            }
        </Modal>
    </section>
  );
};

export default RecoProductsTPL;

RecoProductsTPL.propTypes = {
  products: PropTypes.array,
  handleAddToCart: PropTypes.func,
  sectionTitle: PropTypes.string.isRequired,
  shouldLinkToProducts: PropTypes.bool
};
