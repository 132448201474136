import React from 'react';
import PropTypes from 'prop-types';
import './DeleteAccount.scss';

const DeleteAccountTPL = () => {
    return (
      <section className="delete-account-content">
        <div className="centered-container">
          <div className="delete-account-body">
            <h1 className="delete-account-heading">Delete Account</h1>
            <p className="delete-message text">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Praesentium voluptas laboriosam esse voluptatibus atque possimus voluptatum reprehenderit iusto tempore eius, nesciunt ratione sed, repudiandae similique qui cum aperiam neque libero harum autem accusamus tempora minima cumque. Aperiam sequi maiores repellendus dicta, debitis ipsum, magni consectetur minus soluta in esse magnam deleniti necessitatibus, dolor perferendis nisi quia. Impedit, vitae? Explicabo fuga error dolorem, nisi libero expedita dignissimos quia, sunt est placeat ratione dolorum ut accusamus sint corrupti rem corporis rerum. Aliquid natus sit recusandae quasi minima dolorem explicabo asperiores sed dolorum adipisci itaque vel veniam nemo, eius earum. Ea, nemo ipsa.</p>

            <div className="delete-button-container">
              <button className="delete-account-trigger">Delete Account</button>
            </div>
          </div>
        </div>
      </section>
      )
};

export default DeleteAccountTPL;


DeleteAccountTPL.propTypes = {};
