import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import withModal from '../withModal/withModal';

const withHomeModal = WrappedComponent => {
  class HomeModal extends Component {
    constructor(props) {
      super(props);

      this.state = {
        modalProps: {},
        ModalContents: null,
        registrationToken: ''
      };
    }

    handleCancelModal = () => {
      const { toggleModal } = this.props;

      toggleModal(
        {
          visible: false
        },
        null
      );
    };

    handleOkModal = () => {
      const { toggleModal } = this.props;

      toggleModal(
        {
          visible: false
        },
        null
      );

      window.location.href = '/';
    };

    handleAccountDeleteSuccess = Content => {
      const { toggleModal } = this.props;

      toggleModal(
        {
          visible: true,
          onCancel: this.handleCancelModal,
          onOk: this.handleOkModal,
          cancelButtonProps: { style: { display: 'none' } },
          title: null,
          footer: null,
          closable: false,
          className: '--snf-modal'
        },
        props => {
          return <Content {...props} />;
        }
      );
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onAccountSuccessDelete={this.handleAccountDeleteSuccess}
        />
      );
    }
  }

  return withModal(HomeModal);
};

withHomeModal.propTypes = {};

export default withHomeModal;
