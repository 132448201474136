import React, {Component} from 'react';
import { Provider } from 'react-redux';
import  {history, store} from 'Redux/store';
import { ConnectedRouter } from 'connected-react-router'
import App from 'App/App'
import boot from 'App/boot';

/**
 * BOOT
 */
boot();


class AppProvider extends Component {
    render() {
      return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </Provider>
        )
    }
}

export default AppProvider;
